import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import {connect} from "react-redux";
import {addAddress, auditAddress } from "../../redux/actions/modal";
import {loadAddress} from "../../redux/actions/address";
import store from "../../redux/store";
import {ADDRESS_MODAL_LOADING, MODAL_CLOSE_ALERT} from "../../redux/type";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PreviewIcon from '@mui/icons-material/Preview';
import MenuItem from "@mui/material/MenuItem";

const transport_type = {
     "0": "空运", "1": "海运", "2":"海运和空运"
}

const byte_to_chinese = {
     "0": "否", "1": "是"
}


class AddAddress extends React.Component {
    constructor(props) {
        super(props);
        if(this.props.data){
            this.rowdata = this.props.data
        }else{
            this.rowdata = {}
        }
        this.state = {
            open: false,
        }
    }
    columns = [
        {id: 1, field: "company_name", headerName: "公司名"},
        {id: 3, field: "user_sign", headerName: "客户ID -- 如您是我司代理账号，请填写该客户公司在你方的系统客户唯一ID(如账号自用可自行编写),注意:下单需传此参数才能成功"},
        {id: 5, field: "weekly_amount", headerName: "周预估打单金额"},
        {
            id: 6, field: "transport_type", headerName: "转运类型", renderCell: (params) => {
                let tt = transport_type[params.row.transport_type]
                return (
                    <div>
                        {tt}
                    </div>
                )
            }
        },
        {id: 7, field: "address1", headerName: "地址1"},
        {id: 8, field: "address2", headerName: "地址2"},
        {id: 9, field: "state", headerName: "州(简称)"},
        {id: 10, field: "city", headerName: "城市"},
        {id: 11, field: "postal_code", headerName: "邮编"},
        {id: 12, field: "business_subject", headerName: "地址租赁/经营主体"},
        {id: 13, field: "relationship", headerName: "客户与地址主体关系"},
        {id: 14, field: "contacts", headerName: "地址联系人"},
        {id: 15, field: "phone", headerName: "地址联系电话"},
        {
            id: 16, field: "is_one_piece", headerName: "一件代发", renderCell: (params) => {
                let yn = byte_to_chinese[params.row.is_one_piece]
                return (
                    <div>
                        {yn}
                    </div>
                )
            }
        },
        {
            id: 17, field: "has_fedex", headerName: "已有fedex", renderCell: (params) => {
                let yn = byte_to_chinese[params.row.has_fedex]
                return (
                    <div>
                        {yn}
                    </div>
                )
            }
        },
        {id: 18, field: "fedex_subject", headerName: "Fedex账号主体"},

        {id: 19, field: "fedex_history_num", headerName: "历史出货Fedex单号"},
        {id: 20, field: "fedex_online_city", headerName: "历史上网城市"},
        {id: 21, field: "comment", headerName: "请填写通过/拒绝理由"},

    ]
    Close = () => {
        this.setState({open: false})
    }
    Open = () => {
        this.setState({open: true})
    }

    componentDidMount() {
    }

    // handleChange = e => {
    //     this.setState({uid: e.target.value})
    // }
    handleSubmit = e => {
        e.preventDefault()
        const data = new FormData(e.currentTarget);
        this.props.addAddress(data)
        this.Close()
    }

    handleAudit = (e,data,status) =>{
        // e.preventDefault()
        console.log(data,status);

        this.props.auditAddress(data.id, data.comment, status)
        this.Close()

    }

    render() {
        return (
            <div>
                {this.props.show_button ?
                <Button open={false} sx={{textTransform: 'none'}} startIcon={
                    this.props.audit ? <PreviewIcon/> : <ArrowDownwardIcon/>
                    } onClick={() => {
                    this.Open()
                }}>{this.props.button_name}</Button> : null
                }
                <Dialog fullScreen={true}  open={this.state.open} onClose={() => {
                    this.Close()
                }}>
                        <DialogTitle>{this.props.button_name}</DialogTitle>
                        <DialogContent>
                            <Box component="form" onSubmit={this.handleSubmit} sx={{display: 'flex', flexWrap: 'wrap'}}>

                                {

                                    this.columns.map((item,index)=>{
                                        let required = !['address2','fedex_subject','fedex_history_num','fedex_online_city'].includes(item.field)
                                        if (item.field == 'transport_type'){
                                            return (
                                                    <FormControl variant="standard" sx={{m: 5, minWidth: 150}} required>
                                                    <InputLabel id="model">{item.headerName}</InputLabel>
                                                    <Select name={item.field}
                                                        label={item.headerName}
                                                            value={this.rowdata[item.field]} disabled={this.props.audit}
                                                    >
                                                        <MenuItem key="0" value="0">空运 </MenuItem>
                                                        <MenuItem key="1" value="1">海运 </MenuItem>
                                                        <MenuItem key="2" value="2">海运和空运 </MenuItem>
                                                    </Select>
                                                </FormControl>);
                                        }else if (item.field == 'is_one_piece' ||item.field == 'has_fedex' ){
                                            return (
                                                    <FormControl variant="standard" sx={{m: 1, minWidth: 150}} required>
                                                    <InputLabel id="model">{item.headerName}</InputLabel>
                                                    <Select name={item.field}
                                                        label={item.headerName}
                                                            value={this.rowdata[item.field]} disabled={this.props.audit}
                                                    >
                                                        <MenuItem key="0" value="0">否</MenuItem>
                                                        <MenuItem key="1" value="1">是</MenuItem>
                                                    </Select>
                                                </FormControl>);
                                        }else if (item.field == 'comment'){
                                            if(this.props.audit){
                                                return (
                                                <FormControl sx={{width: '100%', mt: 2}} variant="standard" >
                                                    <TextField
                                                    name={item.field} type="text" variant="outlined"
                                                    size="small" label={item.headerName} required onChange={(e) =>{
                                                        this.rowdata.comment=e.target.value
                                                    }
                                                    }>
                                                    </TextField>
                                                </FormControl>)
                                            }

                                        }else {

                                                return(
                                            <FormControl sx={{width: '100%', mt: 2}} variant="standard" >
                                                    <TextField
                                                    name={item.field} type="text" variant="outlined" disabled={this.props.audit}
                                                    size="small" label={item.headerName} value={this.rowdata[item.field]} required={required}>
                                                    </TextField>
                                            </FormControl>)
                                        }


                                    })
                                }
                                {this.props.audit ?
                                <DialogActions>
                                    <Button fullWidth variant="contained" color="secondary" sx={{mt: 3, mb: 2, ml: 45}} onClick={() => {
                                        this.Close()
                                    }}>取消</Button>
                                    <Button color="success" fullWidth onClick={(e) => this.handleAudit(e,this.rowdata, true)} variant="contained" sx={{mt: 3, mb: 2}} >通过</Button>
                                    <Button color="error" fullWidth onClick={(e) => this.handleAudit(e,this.rowdata, false)} variant="contained" sx={{mt: 3, mb: 2}} >拒绝</Button>
                                </DialogActions>
                                    :
                                <DialogActions>
                                    <Button fullWidth variant="contained" color="secondary" sx={{mt: 3, mb: 2, ml: 45}} onClick={() => {
                                        this.Close()
                                    }}>取消</Button>
                                    <Button color="success" fullWidth type='submit' variant="contained" sx={{mt: 3, mb: 2}} >提交</Button>
                                </DialogActions>}
                            </Box>
                        </DialogContent>
                    </Dialog>

            </div>
        );
    }

}

// 更新就渲染
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.modal.isLoading,
    alert: state.modal.alert,
    user: state.auth.user,
    payload: state.modal.content,
});

export default connect(mapStateToProps, {addAddress,auditAddress})(AddAddress);