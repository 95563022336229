import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import {AxiosDefaultRequest} from "../../components/default_request";
import server from "../../server";
import {connect} from "react-redux";
import {addCredit} from "../../redux/actions/modal";
import APICircularProgress from "../../components/APICircularProgress";
import APIAlert from "../../components/APIAlert";
import store from "../../redux/store";
import {MODAL_CLOSE_ALERT} from "../../redux/type";
import FormHelperText from "@mui/material/FormHelperText";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const re = {regex: /^(\+|-)?\d+(\.\d{0,2})?$/};

class AddCredit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            display: false,
            credit: 0,
            account: [],
            uid: 0,

        }
    }

    Close = () => {
        this.setState({open: false})
    }
    Open = () => {
        this.setState({open: true})
    }

    componentDidMount() {
        AxiosDefaultRequest({
            url: server.user_list_url,
            success: res => {
                this.setState({account: res.data.UserList, display: true, uid: Object.keys(res.data.UserList)[0]})
            }
        })
    }

    handleChange = e => {
        this.setState({uid: e.target.value})
    }
    handleSubmit = e => {
        e.preventDefault()
        const data = new FormData(e.currentTarget);
        this.props.addCredit(this.state.uid, data.get('Credit'), data.get('Remark'))
    }
    closeAlert = () => {
        store.dispatch({type: MODAL_CLOSE_ALERT})
    }

    render() {
        let loading;
        let alert;
        let options = [];
        if (this.state.account) {
            for (const k in this.state.account) {
                options.push(<option aria-label="None" key={k} value={k}>{this.state.account[k]}</option>)
            }
        }
        if (this.props.isLoading){
            loading = APICircularProgress('success')
        }
        if (this.props.alert) {
            const {level, title, message} = this.props.payload
            const alert_p = {level: level, title: title, message: message, options: null, closeFunc: this.closeAlert}
            alert = APIAlert(alert_p)
        }
        return (
            <span>
                <Button startIcon={<AttachMoneyIcon/>} onClick={() => {
                    this.Open()
                }}>充值</Button>
                {loading}
                {alert}
                    <Dialog disableEscapeKeyDown open={this.state.open} onClose={() => {
                        this.Close()
                    }}>
                        <DialogTitle>Add Credit</DialogTitle>
                        <DialogContent>
                            <Box component="form" onSubmit={this.handleSubmit} sx={{display: 'flex', flexWrap: 'wrap'}}>
                                {this.state.display ?
                                    <FormControl sx={{m: 1, minWidth: 500}}>
                                        <InputLabel id="Account" name="Account" htmlFor="Account" required>Account</InputLabel>
                                        <Select
                                            native
                                            aria-describedby="account-helper"
                                            labelId="Account"
                                            value={this.state.uid}
                                            onChange={(e) => {
                                                this.handleChange(e)
                                            }}
                                            label="Account"
                                            input={<OutlinedInput label="Account"/>}
                                        >
                                            {options}
                                        </Select>
                                        <FormHelperText id="account-helper">Please select a recharge user</FormHelperText>
                                    </FormControl> : ""}
                                    <FormControl sx={{m: 1, minWidth: 500}}>
                                        <TextField id="Credit" name="Credit" inputMode="decimal" type="number"
                                           value={this.state.credit}
                                           label="Credit" variant="standard" helperText="Please set the recharge amount,Maximum two decimal places"
                                           onChange={(value) => {
                                               if (re.regex.test(value.target.value) || value.target.value === ''){
                                                   this.setState({credit: value.target.value})
                                               }
                                           }}
                                           required
                                        />
                                    </FormControl>
                                    <FormControl sx={{m: 1, minWidth: 500}}>
                                        <TextField id="Remark" name="Remark" label="Remark" variant="standard" helperText="Please set a Remark"/>
                                    </FormControl>
                                <DialogActions>
                                    <Button fullWidth variant="contained" sx={{mt: 3, mb: 2, ml: 45}} onClick={() => {this.Close()}}>Cancel</Button>
                                    <Button fullWidth type='submit' variant="contained" sx={{mt: 3, mb: 2}}>Submit</Button>
                                </DialogActions>
                            </Box>
                        </DialogContent>
                    </Dialog>
            </span>
        );
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.modal.isLoading,
    alert: state.modal.alert,
    user: state.auth.user,
    payload: state.modal.content
});

export default connect(mapStateToProps, {addCredit})(AddCredit);