import React, {Component} from 'react';
import store from "../redux/store";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';

import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import {
    ACCOUNT,
    ACCOUNT_SEARCH, ACCOUNT_SEARCH_UPDATE, ADD_CREDIT_RECORD_SEARCH, ADMIN, ADMIN_SEARCH, ADMIN_SEARCH_UPDATE, BILL,
    BILL_SEARCH, BILL_SEARCH_UPDATE, CLOSE_ALERT, OPEN_ALERT, SHIPMENT, SHIPMENT_SEARCH, SHIPMENT_SEARCH_UPDATE
} from "../redux/type";
import List from "@mui/material/List";
import FormControl from "@mui/material/FormControl";
import {AxiosDefaultRequest} from "./default_request";
import server from "../server";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {Link, Skeleton} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import ExportPage from "../pages/modal/ExportPageModal"
import {
    renderButtonField,
    renderCloseField,
    renderDesktopDatePickerField,
    renderSelectField,
    RenderTextField
} from "./renderField";

const searchFormControl = {width: '80%', marginBottom: '5%'}
const searchFormControl2 = {width: '80%', marginBottom: '1%', marginLeft: '-2%', marginTop: "5%"};
const searchFormControl3 = {width: '80%', marginBottom: '1%', marginLeft: '0%', marginTop: "5%"};
const searchFormControl4 = {width: '80%', marginBottom: '1%', marginLeft: '-25%', marginTop: "5%"};  // end upload time
const searchFormControl5 = {width: '80%', marginBottom: '1%', marginLeft: '-20%', marginTop: "5%"};  // end check time
const searchFormControl6 = {width: '85%', marginBottom: '1%', marginLeft: '-6.5%'};
const searchFormControl7 = {width: '85%', marginBottom: '5%'};
const searchFormControl8 = {width: '80%', marginBottom: '5%', marginTop: "-5%"};

export default class SearchTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchOpen: true,
            searchButton: false,
            user: props['user']
        }
    }

    closeAlert = () => {
        store.dispatch({type: CLOSE_ALERT})
    }
    toggleDrawer = () => this.setState({searchOpen: !this.state.searchOpen, searchButton: !this.state.searchButton})
    list = () => {
        let table;
        const searchTable = store.getState().indexReducer.searchTable
        const current = store.getState().indexReducer.current
        if (searchTable) {
            switch (searchTable) {
                case SHIPMENT:
                    table = <ShipmentSearchTable reqUser={this.state.user} closeFunc={this.toggleDrawer}
                                                 closeAlert={this.closeAlert}/>
                    break
                case BILL:
                    table = <BillSearchTable reqUser={this.state.user} closeFunc={this.toggleDrawer}
                                             closeAlert={this.closeAlert}/>
                    break
                case ACCOUNT:
                    table = <AccountSearchTable reqUser={this.state.user} closeFunc={this.toggleDrawer}
                                                closeAlert={this.closeAlert}/>
                    break
                case ADMIN:
                    table = <AdminSearchTable reqUser={this.state.user} closeFunc={this.toggleDrawer}
                                              closeAlert={this.closeAlert}/>
                    break
                default:
                    table = null;
            }
        }
        return <Box
            // sx={{width: 350, paddingTop: "20%", marginLeft: 10}}
            // sx={{width: "100%"}}
            role="presentation"
        >
            {/*<Typography component="h1" variant="h5">*/}
            {/*    Search*/}
            {/*</Typography>*/}
            <List>
                {table}
            </List>
        </Box>
    }

    render() {
        let list;
        if (this.state.searchOpen) {
            list = this.list()
        }
        return (
            // sx={{position: 'absolute', left: "95.5%", top: "75%"}}
            <span>
                <Paper
                    sx={{paddingLeft: '1%'}}
                >
                    {list}
                </Paper>
                <Button sx={{textTransform: 'none', display: this.state.searchButton ? '' : 'none'}} startIcon={<SearchIcon/>}
                        onClick={() => {
                            this.toggleDrawer()
                        }} variant="text">
                    search
                </Button>
            </span>
        )
    }

}


// TODO 后续更新 改动任意字段失去焦点后都会发送请求
class ShipmentSearchTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            warehouse_options: [],
            warehouse_id: '',
            tracking_number: '',
            usps_tracking_number: '',
            start_create_time: null,
            end_create_time: null,
            user_list: [],
            secondaryTrackNumber: '',
            Reference1: '',
            service_name: '',
            ship_status: '',
            user: '',
            // # -1:None 1: 打单中 2: 生成单 3:已完成 4:打单失败 5:未知错误 6:取消单
            ship_status_options: {
                "-1": "未知",
                "1": "打单中",
                "2": "出单",
                "3": "完成",
                "4": "失败",
                "5": "未知错误",
                "6": "取消",
            },
            service_options: ["None", "FEDEX_GROUND", "FEDEX_HOME_DELIVERY", "FEDEX_MWT", "FEDEX_SMART_POST",
                "FEDEX_2_DAY_Flat_Rate", "FEDEX_2_DAY_LRN", "UPS_GROUND", "UPS_GROUND_2"],
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        // TODO 验证
        if (this.state.tracking_number || this.state.secondaryTrackNumber || this.state.Reference1 || this.state.warehouse_id ||
            this.state.usps_tracking_number || this.state.start_create_time || this.state.end_create_time ||
            this.state.service_name || this.state.ship_status || this.state.user) {
            this.search(true)
        } else {
            // level, title, message, options, closeFunc
            store.dispatch({
                type: OPEN_ALERT, payload: {
                    level: 'error',
                    title: 'FAILED',
                    message: '请输入至少一个条件.',
                    closeFunc: this.props.closeAlert
                }
            })
        }
    }
    search = (isClick, payload) => {
        if (isClick) {
            store.dispatch({
                type: SHIPMENT_SEARCH
            })
        } else {
            this.setState(payload);
            store.dispatch({
                type: SHIPMENT_SEARCH_UPDATE, payload: payload
            })
        }
    }

    componentDidMount() {
        AxiosDefaultRequest({
            url: server.warehouse_list_url,
            success: res => {
                let options = [<MenuItem aria-label="None" key={'Warehouse_None'} value={""}>不选择</MenuItem>];
                for (const i in res.data) {
                    options.push(<MenuItem aria-label="None" key={res.data[i].id}
                                           value={res.data[i].id}>{res.data[i].name}</MenuItem >)
                }
                this.setState({warehouse_options: options})
            }
        })
        AxiosDefaultRequest({
            url: server.user_list_url,
            success: res => {
                let user_option = [<MenuItem aria-label="None" key={'User_None'} value={""}>不选择</MenuItem>];
                for (const k in res.data.UserList) {
                    user_option.push(<MenuItem  aria-label="None" key={k} value={k}>{res.data.UserList[k]}</MenuItem >)
                }
                this.setState({user_list: user_option})
            }
        })
    }

    ChangeWarehouse = (e) => {
        this.search(false, {'warehouse_id': e.target.value});
    }
    clearSearch = () => {
        this.search(false, {
            tracking_number: '',
            secondaryTrackNumber: '',
            Reference1: '',
            warehouse_id: '',
            usps_tracking_number: '',
            start_create_time: null,
            end_create_time: null,
            service_name: '',
            user: '',
            ship_status: ''
        });
    }
    render() {
        const serviceOptions = this.state.service_options.map(option => (
            <MenuItem value={option} key={option}>{option}</MenuItem>
        ));
        const statusOptions = Object.entries(this.state.ship_status_options).map(([key, value]) => (
            <MenuItem value={key} key={key}>{value}</MenuItem>
        ));
        return (
            <div>
                <Box component="form" onSubmit={this.handleSubmit} noValidate>
                    <Grid container style={{marginBottom: "1%"}}>
                        {RenderTextField({
                            idAname: "tracking_number", label: "追踪号",
                            value: this.state.tracking_number,
                            onChangeHandler: (e) => { this.search(false, {'tracking_number': e.target.value}); }
                        })}
                        {RenderTextField({
                            idAname: "secondaryTrackNumber", label: "子单号(模糊查询)",
                            value: this.state.secondaryTrackNumber,
                            onChangeHandler: (e) => { this.search(false, {'secondaryTrackNumber': e.target.value}); }
                        })}
                        {RenderTextField({
                            idAname: "Reference1", label: "客户备注",
                            value: this.state.Reference1,
                            onChangeHandler: (e) => { this.search(false, {'Reference1': e.target.value}); }
                        })}
                    </Grid>
                    <Grid container style={{marginBottom: "1%"}}>
                        {renderSelectField({
                            idAname: "Warehouse",
                            label: "仓库",
                            value: this.state.warehouse_id,
                            onChangeHandler: (e) => {this.ChangeWarehouse(e)},
                            select_options: this.state.warehouse_options
                        })}
                        <Grid item lg={3} md={3} xs={3} sx={{paddingTop: '0.5%'}}>
                            <Grid container spacing={1}>
                                {renderDesktopDatePickerField({
                                    idAname: "start_create_time",
                                    label: "下单起始日期",
                                    value: this.state.start_create_time,
                                    onChangeHandler: (e) => {this.search(false, {'start_create_time': new Date(e).toLocaleDateString("en")})}
                                })}
                                {renderDesktopDatePickerField({
                                    idAname: "end_create_time",
                                    label: "下单结束日期",
                                    value: this.state.end_create_time,
                                    onChangeHandler: (e) => {this.search(false, {'end_create_time': new Date(e).toLocaleDateString("en")})}
                                })}
                            </Grid>
                        </Grid>
                        {renderSelectField({
                            idAname: "user",
                            label: "用户",
                            value: this.state.user,
                            onChangeHandler: (e) => {this.search(false, {'user': e.target.value})},
                            select_options: this.state.user_list
                        })}
                    </Grid>
                    <Grid container style={{marginBottom: "1%"}}>
                        {renderSelectField({
                            idAname: "service_name",
                            label: "服务类型",
                            value: this.state.service_name,
                            onChangeHandler: (e) => {this.search(false, {'service_name': e.target.value})},
                            select_options: serviceOptions
                        })}
                        {renderSelectField({
                            idAname: "ship_status",
                            label: "状态",
                            value: this.state.ship_status,
                            onChangeHandler: (e) => {this.search(false, {'ship_status': e.target.value})},
                            select_options: statusOptions
                        })}
                        {RenderTextField({
                            idAname: "usps_tracking_number", label: "USPS单号",
                            value: this.state.usps_tracking_number,
                            onChangeHandler: (e) => { this.search(false, {'usps_tracking_number': e.target.value}); }
                        })}
                    </Grid>
                    <Grid container>
                        {renderButtonField({
                            idAname: "Search",
                            variant: "contained",
                            text: "查询",
                            buttonType: "submit",
                            tip: "条件查询",
                            onClickHandler: null
                        })}
                        {renderButtonField({
                            idAname: "export",
                            variant: "contained",
                            text: "导出查询信息",
                            buttonType: "submit",
                            tip: "查询条件导出",
                            onClickHandler: (e) => {this.search(false, {'submitType': 'export'})
                            }
                        })}
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <ExportPage type={'SE'}/>
                            </FormControl>
                        </Grid>
                        {renderButtonField({
                            idAname: "Clear",
                            variant: "outlined",
                            text: "清空",
                            buttonType: "button",
                            tip: "清空查询信息",
                            onClickHandler: () => {this.clearSearch()}
                        })}
                        {renderCloseField({onClickHandler: () => {this.props.closeFunc()}})}
                    </Grid>
                </Box>
            </div>
        );
    }
}

export class BillSearchTable extends Component {
    constructor(props) {
        super(props);
        /*
        * 1.4.X.0609202204I [bill页面]搜索增加bill_type[下拉框],master_tracking,tracking_number,user,时间范围(create_time)
        *     # 2 bill,3 admin,4 void,5 shipment,7 adjust
        * */
        this.state = {
            BillDescription: '', bill_type: '', master_tracking: '',
            bill_track: '', user: '', create_time: null,
            bill_type_select: null, user_list: null
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (this.state.BillDescription || this.state.bill_type || this.state.master_tracking || this.state.bill_track ||
            this.state.user || this.state.create_time) {
            this.search(true)
        } else {
            // level, title, message, options, closeFunc
            store.dispatch({
                type: OPEN_ALERT, payload: {
                    level: 'error', title: 'FAILED', message: 'Please enter at least one condition.', closeFunc: this.props.closeAlert
                }
            })
        }
    }
    UNSAFE_componentWillMount() {
        let options = [<option aria-label="None" key={'None'}/>];
        let user_option = [<option aria-label="None" key={'None'}/>];
        const bill_type_select = {2: 'bill', 3: 'admin', 4: 'void', 5: 'shipment', 7: 'adjust'}
        for (const i in bill_type_select) {
            options.push(<option aria-label="None" key={i}
                                 value={i}>{bill_type_select[i]}</option>)
        }
        AxiosDefaultRequest({
            url: server.user_list_url,
            success: res => {
                for (const k in res.data.UserList) {
                    user_option.push(<option aria-label="None" key={k} value={k}>{res.data.UserList[k]}</option>)
                }
            }
        })
        this.setState({bill_type_select: options, user_list: user_option})
    }
    ChangeBillType = (e) => {
        this.search(false, {'bill_type': e.target.value});
    }
    ChangeUser = (e) => {
        this.search(false, {'user': e.target.value});
    }
    search = (isClick, payload) => {
        if (isClick) {
            store.dispatch({type: BILL_SEARCH})
        } else {
            this.setState(payload);
            store.dispatch({
                type: BILL_SEARCH_UPDATE, payload: payload
            })
        }
    }
    render() {
        return (
            <div>
                <Box component="form" onSubmit={this.handleSubmit} noValidate>
                    <Grid container>
                        <Grid item lg={3} md={3} xs={3}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <TextField type='text' id="BillDescription" name="BillDescription"
                                           label="Description" variant="standard"
                                           value={this.state.BillDescription}
                                           onChange={(e) => {
                                               this.search(false, {'BillDescription': e.target.value});
                                           }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} xs={3}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <TextField
                                    id="bill_type" select label="Bill Type" value={this.state.bill_type}
                                    onChange={(e) => {
                                        this.ChangeBillType(e)
                                    }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    helperText="Please select a Bill Type"
                                    variant="standard"
                                >
                                    {this.state.bill_type_select}
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} xs={3}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <TextField
                                    type='text' id="master_tracking" name="master_tracking"
                                    label="master trace" variant="standard"
                                    value={this.state.master_tracking}
                                    onChange={(e) => {
                                        this.search(false, {'master_tracking': e.target.value});
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item lg={3} md={3} xs={3}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <TextField
                                    type='text' id="bill_track" name="bill_track"
                                    label="bill trace" variant="standard"
                                    value={this.state.bill_track}
                                    onChange={(e) => {
                                        this.search(false, {'bill_track': e.target.value});
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} xs={3}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <TextField
                                    id="user" select label="user" value={this.state.user}
                                    onChange={(e) => {
                                        this.ChangeUser(e)
                                    }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    helperText="Please select a User Account"
                                    variant="standard"
                                >
                                    {this.state.user_list}
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} xs={3}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="create time"
                                        inputFormat="MM/dd/yyyy"
                                        endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                                        value={this.state.create_time}
                                        onChange={(e) => {
                                            this.search(false, {'create_time': new Date(e).toLocaleDateString()});
                                        }}
                                        renderInput={(params) => <TextField id="create_time" name="create_time" sx={{mb: 1}} {...params} />}
                                    />
                                </LocalizationProvider>
                                <InputLabel htmlFor="component-helper" sx={{mt: 4.3, ml: 1}}> > greater than the current time</InputLabel>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <Button
                                    type="submit"
                                    // fullWidth
                                    variant="contained"
                                    id="searchBill"
                                    sx={{textTransform: 'none'}}
                                    onClick={(e) => {
                                        this.search(false, {'submitType': 'search'});
                                    }}
                                >
                                    Search Bill
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    id="exportBill"
                                    sx={{textTransform: 'none'}}
                                    onClick={(e) => {
                                        this.search(false, {'submitType': 'export'});
                                        }}
                                >
                                    Export Bill
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <Button
                                    type="button"
                                    // fullWidth
                                    variant="outlined"
                                    sx={{textTransform: 'none'}}
                                    onClick={() => {
                                        this.search(false, {
                                            "BillDescription": '', "bill_type": '', "master_tracking": '',
                                            "bill_track": '', "user": '', "create_time": null,});
                                    }}
                                >
                                    Clear
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} md={2} xs={2}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <Link
                                    // fullWidth
                                    underline='none'
                                    sx={{textTransform: 'none',}}
                                >
                                    <Button onClick={() => {
                                        this.props.closeFunc()
                                    }}>
                                        收起搜索栏
                                        <KeyboardArrowUpIcon/>
                                    </Button>
                                </Link>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        );
    }
}

export class AccountSearchTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AccountUsername: '', AccountUid: ''
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (this.state.AccountUsername || this.state.AccountUid) {
            this.search(true);
        } else {
            // level, title, message, options, closeFunc
            store.dispatch({
                type: OPEN_ALERT, payload: {
                    level: 'error', title: 'FAILED', message: 'Please enter at least one condition.', closeFunc: this.props.closeAlert
                }
            })
        }
    }
    search = (isClick, payload) => {
        if (isClick) {
            store.dispatch({type: ACCOUNT_SEARCH})
        } else {
            this.setState(payload);
            store.dispatch({
                type: ACCOUNT_SEARCH_UPDATE, payload: payload
            })
        }
    }
    render() {
        return (
            <div>
                <Box component="form" onSubmit={this.handleSubmit} noValidate>
                    <Grid container>
                        <Grid item lg={2} md={2} xs={2}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <TextField
                                    type='text' id="AccountUsername" name="AccountUsername"
                                    label="User Name" variant="standard"
                                    value={this.state.AccountUsername}
                                    onChange={(e) => {
                                        this.search(false, {AccountUsername: e.target.value})
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} md={2} xs={2}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <TextField
                                    type='text' id="AccountUid" name="AccountUid"
                                    label="User Id" variant="standard"
                                    value={this.state.AccountUid}
                                    onChange={(e) => {
                                        this.search(false, {AccountUid: e.target.value})
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <Button
                                    type="submit"
                                    // fullWidth
                                    variant="contained"
                                    sx={{textTransform: 'none'}}
                                >
                                    Search
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <Button
                                    type="button"
                                    // fullWidth
                                    variant="outlined"
                                    sx={{textTransform: 'none'}}
                                    onClick={() => {
                                        this.search(false, {AccountUsername: '', AccountUid: ''})
                                    }}
                                >
                                    Clear
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} md={2} xs={2}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <Link
                                    // fullWidth
                                    underline='none'
                                    sx={{textTransform: 'none',}}
                                >
                                    <Button onClick={() => {
                                        this.props.closeFunc()
                                    }}>
                                        收起搜索栏
                                        <KeyboardArrowUpIcon/>
                                    </Button>
                                </Link>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        );
    }
}

export class AdminSearchTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            import_name: '', record_status: '', record_type: '', status_options: null, type_options: null
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (this.state.import_name || this.state.record_status || this.state.record_type) {
            this.search(true)
        } else {
            // level, title, message, options, closeFunc
            store.dispatch({
                type: OPEN_ALERT, payload: {
                    level: 'error', title: 'FAILED', message: 'Please enter at least one condition.', closeFunc: this.props.closeAlert
                }
            })
        }
    }
    UNSAFE_componentWillMount() {
        let options = [<option aria-label="None" key={'None'}/>];
        const status_options = {0: '初始化', 1: '处理中', 2: '完成', 3: '错误'}
        for (const i in status_options) {
            options.push(<option aria-label="None" key={i}
                                 value={i}>{status_options[i]}</option>)
        }

        let type_options = [<option aria-label="None" key={'None'}/>];
        const type_options_dict = {UI: 'UI', TK: 'TK', GS: 'GS'}
        for (const i in type_options_dict) {
            type_options.push(<option aria-label="None" key={i}
                                 value={i}>{type_options_dict[i]}</option>)
        }

        this.setState({status_options: options, type_options: type_options})
    }
    ChangeStatus = (e) => {
        this.search(false, {'record_status': e.target.value});
    }
    ChangeType = (e) => {
        this.search(false, {'record_type': e.target.value});
    }
    search = (isClick, payload) => {
        if (isClick) {
            store.dispatch({type: ADMIN_SEARCH})
        } else {
            this.setState(payload);
            store.dispatch({
                type: ADMIN_SEARCH_UPDATE, payload: payload
            })
        }
    }
    render() {
        return (
            <div>
                <Box component="form" onSubmit={this.handleSubmit} noValidate>
                    <Grid container>
                        <Grid item lg={2} md={2} xs={2}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <TextField
                                    type='text' id="import_name" name="import_name"
                                    label="import name" variant="standard"
                                    value={this.state.import_name}
                                    helperText="不需要填写后缀名[.txt .xlsx .xls ...]"
                                    onChange={(e) => {
                                        this.search(false, {import_name: e.target.value})
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} md={2} xs={2}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <TextField
                                    id="record_status" select label="record status" value={this.state.record_status}
                                    onChange={(e) => {
                                        this.ChangeStatus(e)
                                    }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="standard"
                                >
                                    {this.state.status_options}
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} md={2} xs={2}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <TextField
                                    id="record_type" select label="record type" value={this.state.record_type}
                                    onChange={(e) => {
                                        this.ChangeType(e)
                                    }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="standard"
                                >
                                    {this.state.type_options}
                                </TextField>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <Button
                                    type="submit"
                                    // fullWidth
                                    variant="contained"
                                    sx={{textTransform: 'none'}}
                                >
                                    Search
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <Button
                                    type="button"
                                    // fullWidth
                                    variant="outlined"
                                    sx={{textTransform: 'none'}}
                                    onClick={() => {
                                        this.search(false, {import_name: '', record_status: '', record_type: ''})
                                    }}
                                >
                                    Clear
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} md={2} xs={2}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <Link
                                    // fullWidth
                                    underline='none'
                                    sx={{textTransform: 'none',}}
                                >
                                    <Button onClick={() => {
                                        this.props.closeFunc()
                                    }}>
                                        收起搜索栏
                                        <KeyboardArrowUpIcon/>
                                    </Button>
                                </Link>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        );
    }
}

export class AddCreditSearchTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start_datetime: new Date().toLocaleDateString(),
            end_datetime: new Date().toLocaleDateString(),
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const data = new FormData(e.currentTarget)
        store.dispatch({
            type: ADD_CREDIT_RECORD_SEARCH, payload: {
                'username': data.get('username'),
                'remark': data.get('remark'),
                'start_create_time': data.get('start_create_time'),
                'end_create_time': data.get('end_create_time'),
            }
        })
    }
    handleStartDateChange = (newValue) => {
        this.setState({start_datetime: newValue})
    };
    handleEndDateChange = (newValue) => {
        this.setState({end_datetime: newValue})
    };

    render() {
        return (
            <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box component="form" onSubmit={this.handleSubmit} noValidate>
                        <TextField
                            margin="normal" fullWidth id="username"
                            label="username" name="username"
                        />
                        <TextField
                            margin="normal" fullWidth id="remark"
                            label="remark" name="remark"
                        />
                        <DesktopDatePicker
                            label="start create time"
                            inputFormat="MM/dd/yyyy"
                            value={this.state.start_datetime}
                            onChange={(e) => {
                                this.handleStartDateChange(e)
                            }}
                            renderInput={(params) => <TextField id="start_create_time" name="start_create_time" sx={{mb: 1}} {...params} />}
                        />
                        <DesktopDatePicker
                            label="end create time"
                            inputFormat="MM/dd/yyyy"
                            value={this.state.end_datetime}
                            onChange={(e) => {
                                this.handleEndDateChange(e)
                            }}
                            renderInput={(params) => <TextField id="end_create_time" name="end_create_time" {...params} />}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2, textTransform: 'none'}}
                        >
                            SearchAddCreditRecord
                        </Button>
                    </Box>
                </LocalizationProvider>
            </div>
        );
    }
}


