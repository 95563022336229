export function sort_order(orders) {
    let orders_str = "&sort=";
    let number = 1;
    for (let i in orders){
        if (orders[i]['sort'] === 'desc'){
            orders_str += '-' + orders[i]['field']
        }else {
            orders_str += orders[i]['field']
        }
        if (number < orders.length){
            orders_str +=  ','
        }
        number += 1
    }
    return orders_str
}