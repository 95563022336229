import * as React from 'react';
import {connect} from "react-redux";
import Box from "@mui/material/Box";
import {loadAccount} from "../redux/actions/account";
import UploadInvoice from "./modal/UploadInvoice";
import GetLabel from "./modal/GetLabel";
import VoidShip from "./modal/VoidShip";
import {AdminPreLoad} from "../redux/actions/adminAction";
import APICircularProgress from "../components/APICircularProgress";
import APIAlert from "../components/APIAlert";
import {ADMIN_CLOSE_ALERT, ADMIN_ORDERS, ADMIN_STOP} from "../redux/type";
import store from "../redux/store";
import Grid from "@mui/material/Grid";
import SearchTable from "../components/searchTable";
import StyledBox from "../components/StyledBox";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";
import APIToolBar from "../components/APIToolBar";
import {GridActionsCellItem, GridColDef} from "@mui/x-data-grid";

import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

import SetVisibility from "../components/SetVisibility";
import SetColumns from "../components/SetColumns";
import {DeleteRecord, downloadFile, recallInvoice} from "../redux/actions/modal";
import './styles.scss'
import RecallInvoice from "./modal/RecallInvoice";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Replenish from "./modal/Replenish";
import ReplenishMasterTrack from "./modal/ReplenishMasterTrack";

const status_chinese = {
    "initializing": "初始化", "Processing": "处理中", "Complete": "完成", "Error": "错误", "Reset": "重置"
}

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            Visibility: this.setVisibility,
            record_id: null
        }
    }

    columnVisibilityModel = { // 控制是否展示列
        id: false,
        download_file_name: false
    }

    localStorageKey = this.props.user.username + "_" + 'AdminColumns';
    localStorageVisibilityKey = this.props.user.username + "_" + 'AdminVisibility';
    setVisibility = SetVisibility(this.localStorageVisibilityKey, this.columnVisibilityModel)

    ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        // 获取columns
        // console.log(set_columns)
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
        // oldIndex > targetIndex
        // targetIndex < oldIndex
    }
    columns: GridColDef[] = [
        {id: 0, field: "id", headerName: "Id", flex: 10},
        {id: 1, field: "import_name", headerName: "import_name", flex: 20},
        {
            id: 2, field: "record_status", headerName: "record_status", flex: 20, renderCell: (params) => {
                let chinese_status = status_chinese[params.row.record_status]
                return (
                    <div className={`AdminCellWithStatus ${params.row.record_status}`}>
                        {chinese_status}
                    </div>
                )
            }
        },
        {
            id: 3, field: "record_type", type: 'singleSelect', headerName: "record_type", flex: 20,
            valueOptions: ['UI', 'TK', 'GS']
        },
        {id: 7, field: "time_consuming", headerName: "TimeConsuming", flex: 30},
        {id: 4, field: "error_message", headerName: "error_message", flex: 100},
        {id: 5, field: "download_file_name", headerName: "download_file_name", flex: 50, hideable: false},
        {
            id: 6, field: 'actions', type: 'actions', headerName: "Actions", renderCell: (params) => {
                return [
                    <span key={params.row.id}>
                        <GridActionsCellItem
                            icon={<KeyboardReturnIcon/>}
                            label="ReStore"
                            color="primary"
                            sx={{mr: 1}}
                            onClick={() => {
                                this.setState({open: true, record_id: params.row.id})
                            }}
                            /*
                            * true = 禁止重置 false = 允许重置
                            * params.row.record_type !== 'UI' = 非UI则为ture UI则为false
                            * params.row.record_status === 'Complete' = Complete则为true 非Complete则为false
                            */
                            disabled={!(params.row.record_type === 'UI' && params.row.record_status === 'Complete')}
                        />
                        <GridActionsCellItem
                            icon={<DeleteIcon/>}
                            label="Delete"
                            color="primary"
                            sx={{mr: 1}}
                            onClick={() => {
                                this.props.DeleteRecord(params.row.id, this.props.data)
                            }}
                            disabled={params.row.record_status === 'Complete'}
                        />
                        <GridActionsCellItem
                            icon={<DownloadIcon/>}
                            label="Download"
                            onClick={() => {
                                this.props.downloadFile(params.row.download_file_name)
                            }}
                            color="primary"
                            disabled={params.row.record_status !== 'Complete'}
                        />
                    </span>
                ];
            }
        }
    ]
    set_columns = SetColumns(this.localStorageKey, this.columns)

    UNSAFE_componentWillMount() { // 预加载
        this.props.AdminPreLoad(this.props.page, this.props.pageSize, null,
            null, store.getState().adminReducer.search, null, null);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (store.getState().adminReducer.stop && nextProps.search && !this.props.isLoading && nextProps.searchClick) {  // search
            store.dispatch({type: ADMIN_STOP})
            this.props.AdminPreLoad(this.props.page, this.props.pageSize, null,
                null, store.getState().adminReducer.search, null, null);
        }
        // order
        if (nextProps.orders && nextProps.orderClick) {
            store.dispatch({type: ADMIN_STOP})
            this.props.AdminPreLoad(this.props.page, this.props.pageSize, null,
                null, store.getState().adminReducer.search, null, nextProps.orders);
        }
        // request
        if (nextProps.request) {
            this.props.AdminPreLoad(this.props.page, this.props.pageSize, null,
                null, store.getState().adminReducer.search, null, null);
        }
    }

    ChangeColumnVisibilityModel = (modal) => {
        localStorage.setItem(this.localStorageVisibilityKey, JSON.stringify(modal))
        this.setState({Visibility: modal})
    }
    useQuery = (page) => {
        this.props.AdminPreLoad(
            (page * this.props.pageSize), ((page + 1) * this.props.pageSize), page,
            null, store.getState().adminReducer.search, null, null)
    }
    changePageSize = (pageSize) => {
        setTimeout(() => {
            this.props.AdminPreLoad(
                (this.props.page * pageSize), ((this.props.page + 1) * pageSize),
                null, pageSize, store.getState().adminReducer.search, null, null)
        }, 100);
    }
    closeAlert = () => {
        store.dispatch({type: ADMIN_CLOSE_ALERT})
    }
    ChangeColumnSortModel = (modal) => {
        // 触发 UNSAFE_componentWillReceiveProps
        store.dispatch({type: ADMIN_ORDERS, payload: modal})
    }

    render() {
        let loading;
        let alert;
        if (this.props.isLoading || this.props.ModalLoading) {
            loading = APICircularProgress('success')
        }
        if (this.props.alert && this.props.payload) {
            const {level, title, message} = this.props.payload
            const alert_p = {level: level, title: title, message: message, options: null, closeFunc: this.closeAlert}
            alert = APIAlert(alert_p)
        }
        return (
            <Box sx={{width: '100%'}}>
                {loading}{alert}
                <Grid container>
                    <Grid container item md={12} lg={12}>
                        <Box sx={{minWidth: '100%'}}>
                            <SearchTable/>
                            <UploadInvoice/>
                            <RecallInvoice/>
                            <GetLabel/>
                            <VoidShip/>
                            <Replenish/>
                            {/*<ReplenishMasterTrack/>*/}
                        </Box>
                    </Grid>
                    <Grid item md={12} lg={12}>
                        <StyledBox>
                            {this.props.data ? <DataGridPro
                                rows={this.props.data}
                                columns={this.set_columns}
                                pageSize={this.props.pageSize}
                                rowsPerPageOptions={[20, 30, 50, 100, 200, 500]}
                                loading={this.props.TableLoading}
                                rowCount={this.props.rowCount}
                                paginationMode='server'
                                columnVisibilityModel={this.state.Visibility}
                                onColumnVisibilityModelChange={(newModel) =>
                                    this.ChangeColumnVisibilityModel(newModel)
                                }
                                checkboxSelection
                                headerHeight={70}
                                rowReordering
                                onColumnOrderChange={(e) => {
                                    this.ColumnOrderChange(e)
                                }}
                                initialState={
                                    {pinnedColumns: {right: ['actions']}}
                                }
                                components={{
                                    LoadingOverlay: LinearProgress,
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    Toolbar: APIToolBar
                                }}
                                onPageChange={(page) => this.useQuery(page)}
                                onPageSizeChange={pageSize => this.changePageSize(pageSize)}
                                pagination={true}

                                sortingMode="server"
                                // sortModel={this.set_sort}
                                onSortModelChange={(newSortModel) => this.ChangeColumnSortModel(newSortModel)}

                                // Number of extra columns to be rendered before/after the visible slice.
                                // 在可见切片之前/之后渲染的额外列数。
                                columnBuffer={3}
                                // Number of rows from the columnBuffer that can be visible before a new slice is rendered.
                                // 在呈现新切片之前可以看到的 columnBuffer 中的行数。
                                columnThreshold={3}
                            /> : ''}
                        </StyledBox>
                    </Grid>
                </Grid>
                {/*------------------------------- recallInvoice ---------------------------------------------*/}
                <Dialog disableEscapeKeyDown open={this.state.open} onClose={() => {
                    this.setState({open: false, record_id: null})
                }}>
                    <DialogTitle>reset bill</DialogTitle>
                    <DialogContent sx={{pr: 1}}>
                        <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                            Are you sure you want to reset?

                            <DialogActions sx={{ml: "55%", mt: 2}}>
                                <Button fullWidth variant="outlined" sx={{textTransform: 'none', mr: 1.5}} onClick={() => {
                                    this.setState({open: false, record_id: null})
                                }}>Cancel</Button>
                                <Button fullWidth type='submit' sx={{textTransform: 'none'}} variant="contained" onClick={() => {
                                    this.props.recallInvoice(null, this.state.record_id)
                                    this.setState({open: false, record_id: null})
                                }}>Yes</Button>
                            </DialogActions>
                        </Box>
                    </DialogContent>
                </Dialog>
                {/*------------------------------- recallInvoice ---------------------------------------------*/}
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.adminReducer.isLoading,
    TableLoading: state.adminReducer.TableLoading,
    ModalLoading: state.adminReducer.ModalLoading,
    request: state.adminReducer.request,  // 据此判断是否刷新table
    alert: state.adminReducer.alert,
    payload: state.adminReducer.content,
    pageSize: state.adminReducer.pageSize,
    page: state.adminReducer.page,
    rowCount: state.adminReducer.rowCount,
    data: state.adminReducer.data,
    search: state.adminReducer.search,
    searchClick: state.adminReducer.searchClick,
    orders: state.adminReducer.orders,
    orderClick: state.adminReducer.orderClick,
    user: state.auth.user,
});

export default connect(mapStateToProps, {loadAccount, AdminPreLoad, downloadFile, DeleteRecord, recallInvoice})(Admin)