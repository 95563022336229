import {
    ADD_CREDIT_ERR,
    ADD_CREDIT_SUCCESS,
    MODAL_CLOSE_ALERT,
    MODAL_LOADING,
    LOAD_MODAL,
    DOWNLOAD_FILE_SUCCESS,
    ADD_CREDIT_RECORD_SEARCH_STOP,
    ADD_CREDIT_RECORD_SEARCH,
    LOAD_EXPORT_MODAL,
    EXPORT_PAGE_SEARCH_UPDATE,
    EXPORT_PAGE_SEARCH_STOP,
    RE_STORE_INVOICE_SUCCESS,
    RE_STORE_INVOICE_ERR, GET_LABEL_SUCCESS, GET_LABEL_ERR, DELETE_RECORD_SUCCESS, DELETE_RECORD_ERR

} from "../type";
import {GridRowsProp} from "@mui/x-data-grid/models/gridRows";

const initialState = {
    isLoading: false,
    alert: false,
    content: null,
    pageSize: 30,
    page: 0,
    rowCount: 0,
    display: true,
    request: false,
    search: null,
    stop: true,
    data: [],

    exportPage_pageSize: 30,
    exportPage_page: 0,
    exportPage_rowCount: 0,
    exportPage_stop: true,
    exportPage_data: [],
    exportPage_content: null,
    exportPage_search: null,
};

// 是否不需要modal
export default function (preState = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case MODAL_LOADING:
            return {
                ...preState,
                isLoading: true,
                request: false,
                content: null,
                data: []
            }
        case MODAL_CLOSE_ALERT:
            return {
                ...preState,
                alert: false
            }
        case LOAD_MODAL:
            const rows : GridRowsProp[] = payload.data
            return {
                ...preState,
                isLoading: false,
                data: rows,
                rowCount: payload.rowCount,
                display: true,
                page: payload.page,
                pageSize: payload.pageSize
            }
        case DOWNLOAD_FILE_SUCCESS:
            return {
                ...preState,
                isLoading: false,
                request: false
            }
        case ADD_CREDIT_RECORD_SEARCH_STOP:
            return {
                ...preState,
                stop: false
            }
        case ADD_CREDIT_RECORD_SEARCH:
            return {
                ...preState,
                search: payload,
                data: [],
                page: 0,
                pageSize: 30,
                stop: true
            }
        case ADD_CREDIT_SUCCESS:
        case ADD_CREDIT_ERR:
            return {
                ...preState,
                isLoading: false,
                request: payload.request,
                alert: true,
                content: payload
            }

        case LOAD_EXPORT_MODAL:
            const rows_ : GridRowsProp[] = payload.data
            return {
                ...preState,
                isLoading: false,
                rowCount: payload.rowCount,
                display: true,
                exportPage_data: rows_,
                exportPage_page: payload.page,
                exportPage_pageSize: payload.pageSize
            }
        case EXPORT_PAGE_SEARCH_UPDATE:
            return {
                ...preState,
                exportPage_search: payload,
                exportPage_page: 0,
                exportPage_pageSize: 30,
                exportPage_stop: true
            }
        case EXPORT_PAGE_SEARCH_STOP:
            return {
                ...preState,
                exportPage_stop: false,
                searchClick: false,
                orderClick: false
            }
        default:
            return preState

    }

}