import * as React from 'react';
import {connect} from "react-redux";
import {loadAddress,auditAddress} from "../redux/actions/address";
import ReactDOM from 'react-dom';
import store from "../redux/store";
import {ADDRESS_ORDERS, ADDRESS_STOP, ADDRESS_CLOSE_ALERT} from "../redux/type";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";
import Box from "@mui/material/Box";
import SearchTable from "../components/searchTable";
import APIToolBar from "../components/APIToolBar";
import Grid from "@mui/material/Grid";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import './styles.scss'
import StyledBox from "../components/StyledBox";
import SetColumns from "../components/SetColumns";
import SetVisibility from "../components/SetVisibility";
import Button from "@mui/material/Button";
import AddAddress from "./modal/AddAddress";
import APICircularProgress from "../components/APICircularProgress";
import APIAlert from "../components/APIAlert";
import PackageDisplay from "./modal/PackageDisplay";
/*
field
headerName
hide
minWidth
width
description
flex 弹性分配
valueGetter
valueFormatter
getActions
headerClassName
cellClassName
*/

const transport_type = {
     "0": "空运", "1": "海运", "2":"海运和空运"
}

const byte_to_chinese = {
     "0": "否", "1": "是"
}

const address_status = {
    "-1": "驳回", "0": "已通过审核", "1": "待LV1审核", "2": "L1通过,待LV2审核"
}


class Address extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Visibility: this.setVisibility,
            traceOpen: false,
            ref: 0
        }

        this.trace = React.createRef();
    }

    localStorageKey = this.props.user.username + "_" + 'addressColumns';
    localStorageVisibilityKey = this.props.user.username + "_" + 'addressVisibility';


    columns: GridColDef[] = [
        {id: 1, field: "username", headerName: "提交客户"},
        {id: 2, field: "company_name", headerName: "公司名"},
        {
            id: 3, field: "disabled", headerName: "状态", width:140, renderCell: (params) => {
                let tt = address_status[params.row.disabled]
                return <div>{tt}</div>

            }
        },
        {
            id: 4, field: "operate", headerName: "操作", width:100, renderCell: (params) => {
                if(params.row.can_audit){
                    return <AddAddress show_button={true} data={params.row} audit={true} button_name="审核" />
                    // return <address show_button={true} button_name="审核"/>
                    // return this.auditAddress(params.row)
                    // return <button onClick={(e) => this.auditAddress(params.row,e)}>查看审核</button>
                }
            },
        },
        {id: 13, field: "comment", headerName: "备注",width:200},
        {id: 14, field: "user_sign", headerName: "内部客户id"},
        {id: 15, field: "code", headerName: "地址唯一码"},

        {id: 16, field: "weekly_amount", headerName: "周预估打单金额"},
        // {id: 6, field: "transport_type", headerName: "转运类型"},
        {
            id: 17, field: "transport_type", headerName: "转运类型", renderCell: (params) => {
                let tt = transport_type[params.row.transport_type]
                return (
                    <div>
                        {tt}
                    </div>
                )
            }
        },
        {id: 18, field: "address1", headerName: "地址1"},
        {id: 19, field: "address2", headerName: "地址2"},
        {id: 20, field: "state", headerName: "州(简称)"},
        {id: 21, field: "city", headerName: "城市"},
        {id: 22, field: "postal_code", headerName: "邮编"},
        {id: 23, field: "business_subject", headerName: "地址租赁/经营主体"},
        {id: 24, field: "relationship", headerName: "客户与地址主体关系"},
        {
            id: 25, field: "is_one_piece", headerName: "一件代发", renderCell: (params) => {
                let yn = byte_to_chinese[params.row.is_one_piece]
                return (
                    <div>
                        {yn}
                    </div>
                )
            }
        },
        {
            id: 26, field: "has_fedex", headerName: "已有fedex", renderCell: (params) => {
                let yn = byte_to_chinese[params.row.has_fedex]
                return (
                    <div>
                        {yn}
                    </div>
                )
            }
        },
        {id: 27, field: "fedex_subject", headerName: "Fedex账号主体"},
        {id: 28, field: "contacts", headerName: "地址联系人"},
        {id: 29, field: "phone", headerName: "地址联系电话"},
        {id: 30, field: "fedex_history_num", headerName: "历史出货Fedex单号"},
        {id: 31, field: "fedex_online_city", headerName: "历史上网城市"},
        {id: 32, field: "insert_timestamp", headerName: "创建时间", width:180},
        {id: 33, field: "update_timestamp", headerName: "更新时间", width:180},

    ]
    // 控制是否展示列
    columnVisibilityModel = {
        // id: false,
        // path: false
    }
    set_columns = SetColumns(this.localStorageKey, this.columns);
    setVisibility = SetVisibility(this.localStorageVisibilityKey, this.columnVisibilityModel);

    ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
    }

    useQuery = (page) => {
        this.props.loadAddress((page * this.props.pageSize), ((page + 1) * this.props.pageSize), page,
            null, store.getState().addressReducer.search, null, null)
    }


    changePageSize = (pageSize) => {
        setTimeout(() => {
            this.props.loadAddress((this.props.page * pageSize), ((this.props.page + 1) * pageSize),
                null, pageSize, store.getState().addressReducer.search, null, null)
        }, 100);
    }

    UNSAFE_componentWillMount() { // 预加载
        this.props.loadAddress(this.props.page, this.props.pageSize, null,
            null, store.getState().addressReducer.search, null, null);
    }

    componentDidMount() {

    }
    ChangeColumnVisibilityModel = (modal) => {
        localStorage.setItem(this.localStorageVisibilityKey, JSON.stringify(modal))
        this.setState({Visibility: modal})
    }
    ChangeColumnSortModel = (modal) => {
        // 触发 UNSAFE_componentWillReceiveProps
        store.dispatch({type: ADDRESS_ORDERS, payload: modal})
    }

    closeAlert = () => {
        store.dispatch({type: ADDRESS_CLOSE_ALERT})
        this.props.loadAddress(this.props.page, this.props.pageSize, null,
            null, store.getState().shipment.search, null, null);
    }

    render() {
        let loading;
        let alert;
        if (this.props.isLoading || this.props.ModalLoading) {
            loading = APICircularProgress('success')
        }
        if (this.props.alert && this.props.payload) {
            const {level, title, message} = this.props.payload
            const alert_p = {level: level, title: title, message: message, options: null, closeFunc: this.closeAlert}
            alert = APIAlert(alert_p)
        }
        // this.gridOptions = {
        //     columnDefs: this.getColumnDefs(),
        //     rowDragManaged: true,
        //     onRowDragEnter: (event: RowDragEvent) => {
        //         this.onRowDragEnter(event);
        //     },
        //     onRowDragEnd: (event: RowDragEvent) => {
        //         this.onRowDragEnd(event);
        //     }
        // }

        return (
            <Box sx={{
                width: '100%'
            }}>
                {loading}{alert}
                <Grid container>
                    <Grid container item md={12} lg={12}>
                        <Box sx={{minWidth: '100%'}}>
                            <AddAddress show_button={true} button_name="新增地址"/>
                        </Box>
                    </Grid>
                    <Grid item md={12} lg={12}>
                        <StyledBox>
                            {this.props.data ? <DataGridPro
                                rows={this.props.data}
                                density="compact"
                                columns={this.set_columns}
                                pageSize={this.props.pageSize}
                                rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                                onColumnOrderChange={(e) => {
                                    this.ColumnOrderChange(e)
                                }}
                                checkboxSelection={false}
                                headerHeight={70}
                                loading={this.props.isLoading}
                                rowCount={this.props.rowCount}
                                paginationMode='server'
                                onPageChange={(page) => this.useQuery(page)}
                                onPageSizeChange={pageSize => this.changePageSize(pageSize)}
                                columnVisibilityModel={this.state.Visibility}
                                onColumnVisibilityModelChange={(newModel) =>
                                    this.ChangeColumnVisibilityModel(newModel)
                                }
                                disableMultipleSelection={false}
                                rowReordering

                                pagination={true}

                                sortingMode="server"
                                // sortModel={this.set_sort}
                                onSortModelChange={(newSortModel) => this.ChangeColumnSortModel(newSortModel)}

                                components={{
                                    LoadingOverlay: LinearProgress,
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    Toolbar: APIToolBar,
                                }}
                                columnBuffer={3}
                                columnThreshold={3}/> : ''}
                        </StyledBox>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.addressReducer.isLoading,
    TableLoading: state.addressReducer.TableLoading,
    ModalLoading: state.addressReducer.ModalLoading,
    request: state.addressReducer.request,  // 据此判断是否刷新table
    alert: state.addressReducer.alert,
    payload: state.addressReducer.content,
    pageSize: state.addressReducer.pageSize,
    page: state.addressReducer.page,
    rowCount: state.addressReducer.rowCount,
    data: state.addressReducer.data,
    search: state.addressReducer.search,
    searchClick: state.addressReducer.searchClick,
    orders: state.addressReducer.orders,
    orderClick: state.addressReducer.orderClick,
    user: state.auth.user,
});

export default connect(mapStateToProps, {loadAddress})(Address)