import {
    CHANGE_PAGE, EXPORT_OPEN, SET_CURRENT
} from "../type";

const initialState = {
    pageName: '',  // 前往页面
    current: '',  // 当前页面
    currentUrl: '', // 当前页面Url
    searchTable: '',  // 查询组件
    // Refresh: null  // 刷新方法
    ExportOpen: false // 导出数据组件开关
};

export default function (preState=initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case CHANGE_PAGE:
            return {
                ...preState,
                pageName: payload.pageName,
                searchTable: payload.searchTable
            }
        case SET_CURRENT:
            localStorage.setItem('current', payload.current)
            localStorage.setItem('currentUrl', payload.currentUrl)
            return {
                ...preState,
                current: payload.current,
                currentUrl: payload.currentUrl,
                searchTable: payload.searchTable,
                // Refresh: payload.Refresh
            }
        case EXPORT_OPEN:
            return {
                ...preState,
                ExportOpen: !preState.ExportOpen
            }
        default:
            return preState

    }
}