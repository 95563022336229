import {
    ADMIN_CLOSE_ALERT,
    ADMIN_LOADING,
    ADMIN_SEARCH,
    ADMIN_STOP,
    LOAD_ADMIN,
    ADMIN_SEARCH_UPDATE,
    ADMIN_ORDERS,
    DELETE_RECORD_SUCCESS,
    DELETE_RECORD_ERR,
    GET_LABEL_SUCCESS,
    GET_LABEL_ERR,
    ADMIN_TABLE_LOADING,
    UPLOAD_INVOICE_SUCCESS,
    UPLOAD_INVOICE_ERR,
    RE_STORE_INVOICE_SUCCESS,
    RE_STORE_INVOICE_ERR,
    ADMIN_MODAL_LOADING,
    REPLENISH_SUCCESS, REPLENISH_ERR,
} from "../type";
import {GridRowsProp} from "@mui/x-data-grid/models/gridRows";

const initialState = {
    isLoading: false,
    TableLoading: false,
    ModalLoading: false,
    alert: false,
    pageSize: 30,
    page: 0,
    rowCount: 0,
    request: false,
    search: null,
    searchClick: false,
    stop: true,

    content: null,
    data: [],
    orderClick: false,
    orders: []
};


export default function (preState = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case LOAD_ADMIN:
            const rows : GridRowsProp = payload.data
            return {
                ...preState,
                data: rows,
                rowCount: payload.rowCount,
                TableLoading: false,
                page: payload.page || preState.page,
                pageSize: payload.pageSize || preState.pageSize,
                request: false,
            }
        case ADMIN_LOADING:
            return {
                ...preState,
                isLoading: true,
                request: false,
                content: null,
            }
        case ADMIN_TABLE_LOADING:
            return {
                ...preState,
                TableLoading: true,
                request: false,
            }
        case ADMIN_MODAL_LOADING:
            return {
                ...preState,
                ModalLoading: true,
                request: false,
                content: null,
            }
        case ADMIN_CLOSE_ALERT:
            return {
                ...preState,
                alert: false
            }
        case ADMIN_STOP:
            return {
                ...preState,
                stop: false,
                searchClick: false,
                orderClick: false
            }
        case ADMIN_ORDERS:
            return {
                ...preState,
                orderClick: true,
                orders: payload
            }
        case ADMIN_SEARCH:
            return {
                ...preState,
                data: [],
                page: 0,
                pageSize: 30,
                stop: true,
                searchClick: true
            }
        case ADMIN_SEARCH_UPDATE:
            let search;
            if (!preState.search) {
                search = payload;
            } else {
                search = preState.search;
                for (let i in payload){
                    search[i] = payload[i]
                }
            }
            return {
                ...preState,
                search: search,
                searchClick: false
            }
        case DELETE_RECORD_SUCCESS:
        case DELETE_RECORD_ERR:
        case GET_LABEL_SUCCESS:
        case GET_LABEL_ERR:
        case UPLOAD_INVOICE_SUCCESS:
        case UPLOAD_INVOICE_ERR:
        case RE_STORE_INVOICE_SUCCESS:
        case RE_STORE_INVOICE_ERR:
        case REPLENISH_SUCCESS:
        case REPLENISH_ERR:
            return {
                ...preState,
                ModalLoading: false,
                request: true,
                alert: true,
                content: payload
            }
        default:
            return preState

    }
}