import * as React from 'react';
import Button from '@mui/material/Button';
import {connect} from "react-redux";
import {LoadCreditRecord} from "../../redux/actions/modal";
import Modal from "@mui/material/Modal";
import store from "../../redux/store";
import {ADD_CREDIT_RECORD_SEARCH_STOP} from "../../redux/type";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import {AddCreditSearchTable} from "../../components/searchTable";
import ListAltIcon from '@mui/icons-material/ListAlt';
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import APIToolBar from "../../components/APIToolBar";
import SetColumns from "../../components/SetColumns";
import SetVisibility from "../../components/SetVisibility";

let columns: GridColDef[] = [
    {id: 0, field: "id", headerName: "Id"},
    {id: 1, field: "user", headerName: "User", width: 150},
    {id: 2, field: "bill_msg", headerName: "Remark", width: 200},
    {id: 3, field: "create_time", headerName: "Create time", width: 200},
    {id: 4, field: "change_credit", headerName: "Change Credit", width: 200},
]
const columnVisibilityModel = { // 控制是否展示列
    id: false,
}
const localStorageKey = 'AccountCreditModalColumns';
const localStorageVisibilityKey = 'AccountCreditVisibilityModalColumns';
const set_columns = SetColumns(localStorageKey, columns);
const setVisibility = SetVisibility(localStorageVisibilityKey, columnVisibilityModel);

class AddCreditPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            Visibility: setVisibility
        }
    }

    ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        // 获取columns
        // console.log(set_columns)
        let old = set_columns[e.oldIndex]
        let target = set_columns[e.targetIndex]
        set_columns[e.targetIndex] = old
        set_columns[e.oldIndex] = target
        localStorage.setItem(localStorageKey, JSON.stringify(set_columns))
        // oldIndex > targetIndex
        // targetIndex < oldIndex
    }
    ChangeColumnVisibilityModel = (modal) => {
        localStorage.setItem(localStorageVisibilityKey, JSON.stringify(modal))
        this.setState({Visibility: modal})
    }
    useQuery = (page) => {
        this.props.LoadCreditRecord((page * this.props.pageSize), ((page + 1) * this.props.pageSize),
            page, null, this.props.search)
    }
    changePageSize = (pageSize) => {
        setTimeout(() => {
            this.props.LoadCreditRecord((this.props.page * pageSize), ((this.props.page + 1) * pageSize),
                null, pageSize, this.props.search)
        }, 500);
    }
    Close = () => {
        this.setState({open: false})
    }
    Open = () => {
        this.props.LoadCreditRecord(this.props.page, this.props.pageSize, null, null, this.props.search)
        this.setState({open: true})
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (store.getState().modal.stop && nextProps.search && !this.props.isLoading) {  // search
            store.dispatch({type: ADD_CREDIT_RECORD_SEARCH_STOP})
            this.props.LoadCreditRecord(this.props.page, this.props.pageSize, null,
                null, nextProps.search);
        }
    }

    render() {
        return (
            <span>
                <Button startIcon={<ListAltIcon/>} onClick={() => {
                    this.Open()
                }}>充值记录
                </Button>
                <Modal
                    open={this.state.open}
                    onClose={() => {
                        this.Close()
                    }}
                    aria-labelledby="add-credit-title"
                    aria-describedby="add-credit-description"
                >
                <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={9}>
                          <Paper
                              sx={{
                                  p: 2,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: 800,
                              }}
                          >
                          <DataGridPro
                              rows={this.props.data}
                              sx={{minHeight: 750, overflow: 'auto', display: 'block'}}
                              columns={set_columns}
                              pageSize={this.props.pageSize}
                              rowsPerPageOptions={[20, 30, 50, 100, 200, 500]}
                              autoHeight={true}
                              loading={this.props.isLoading}
                              rowCount={this.props.rowCount}
                              paginationMode='server'
                              columnVisibilityModel={this.state.Visibility}
                              onColumnVisibilityModelChange={(newModel) =>
                                  this.ChangeColumnVisibilityModel(newModel)
                              }
                              rowReordering
                              onColumnOrderChange={(e) => {
                                  this.ColumnOrderChange(e)
                              }}
                              components={{
                                  LoadingOverlay: LinearProgress,
                                  NoRowsOverlay: CustomNoRowsOverlay,
                                  Toolbar: APIToolBar
                              }}
                              onPageChange={(page) => this.useQuery(page)}
                              onPageSizeChange={pageSize => this.changePageSize(pageSize)}
                              pagination={true}
                          />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Paper
                          sx={{
                              p: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              height: 500,
                          }}
                      >
                        <AddCreditSearchTable/>
                      </Paper>
                    </Grid>
                  </Grid>
                </Container>

              </Modal>
            </span>
        );
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    isLoading: state.modal.isLoading,
    pageSize: state.modal.pageSize,
    page: state.modal.page,
    rowCount: state.modal.rowCount,
    data: state.modal.data,
    user: state.auth.user,
    search: state.modal.search
});

export default connect(mapStateToProps, {LoadCreditRecord})(AddCreditPage);