import * as React from 'react';
import {connect} from "react-redux";
import store from "../redux/store";
import {changePassword} from "../redux/actions/auth";
import {LOGIN_FAIL, LOGIN_SUCCESS, LOGIN_LOADING, SET, LOGIN_ERR, LOGIN_CLOSE_ALERT} from "../redux/type";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import Copyright from "../components/Copyright";
import APICircularProgress from "../components/APICircularProgress";
import APIAlert from "../components/APIAlert";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const theme = createTheme();

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayOldPassword: false,
            displayNewPassword: false,
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        this.props.changePassword(this.props.user, data.get("oldPassword"), data.get("newPassword"))
    };

    closeAlert = () => {
        store.dispatch({type: LOGIN_CLOSE_ALERT})
    }
    handleClickShowOldPassword = () => {
        this.setState({displayOldPassword: !this.state.displayOldPassword})
    }
    handleClickShowNewPassword = () => {
        this.setState({displayNewPassword: !this.state.displayNewPassword})
    }
    render() {
        let alert;
        let loading;
        if (this.props.isLoading) {
            loading = APICircularProgress('success')
        }
        if (this.props.alert) {
            const {level, title, message, timeout} = this.props.payload
            const alert_p = {level: level, title: title, message: message, options: null, closeFunc: this.closeAlert, timeout: timeout || 3000}
            alert = APIAlert(alert_p)
        }
        if (!this.props.isAuthenticated){
            this.props.history.push('/login')
        }
        return (
            <div>{alert}
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline/>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                            <LockResetOutlinedIcon sx={{fontSize: '100'}}/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Change Password
                        </Typography>
                        <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{mt: 5}}>
                            <FormControl variant="outlined" sx={{width: '100%', mb: 2}}>
                                <InputLabel htmlFor="oldPassword">current password</InputLabel>
                                <OutlinedInput
                                    id="oldPassword" name="oldPassword" required
                                    type={this.state.displayOldPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={(e) => {this.handleClickShowOldPassword(e)}}
                                                edge="end"
                                            >
                                                {this.state.displayOldPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="current password"
                                />
                            </FormControl>
                            <FormControl variant="outlined" sx={{width: '100%'}}>
                                <InputLabel htmlFor="newPassword">newPassword</InputLabel>
                                <OutlinedInput
                                    id="newPassword" name="newPassword" required
                                    type={this.state.displayNewPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={(e) => {this.handleClickShowNewPassword(e)}}
                                                edge="end"
                                            >
                                                {this.state.displayNewPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="newPassword"
                                />
                            </FormControl>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                    <Copyright sx={{mt: 8, mb: 4}}/>
                </Container>
            </ThemeProvider>{loading}
            </div>)
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.logInReducer.isLoading,
    alert: state.logInReducer.alert,
    payload: state.logInReducer.content,
    user: state.auth.user
});

export default connect(mapStateToProps, {changePassword})(ChangePassword);
