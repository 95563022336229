import * as React from 'react';
import {connect} from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import './styles.scss'
import StyledBox from "../components/StyledBox";
import CheckCredit from "./modal/CheckCreditModal";
import {loadSettings} from "../redux/actions/settingsAction";
import APICircularProgress from "../components/APICircularProgress";
import APIAlert from "../components/APIAlert";
import store from "../redux/store";
import {SETTINGS_CLOSE_ALERT} from "../redux/type";

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    UNSAFE_componentWillMount() { // 预加载
        this.props.loadSettings();
    }
    closeAlert = () => {
        store.dispatch({type: SETTINGS_CLOSE_ALERT})
    }
    render() {
        let loading;
        let alert;
        if (this.props.isLoading || this.props.ModalLoading) {
            loading = APICircularProgress('success')
        }
        if (this.props.alert && this.props.payload) {
            const {level, title, message} = this.props.payload
            const alert_p = {level: level, title: title, message: message, options: null, closeFunc: this.closeAlert}
            alert = APIAlert(alert_p)
        }
        return (
            <Box sx={{
                width: '100%',
                height: '100%',
                marginLeft: "10%",
                marginTop: '3%'
            }}>
                {loading}{alert}
                <Grid container>
                    <Grid item md={12} lg={12}>
                        <StyledBox>
                            <CheckCredit/>
                        </StyledBox>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.settingsReducer.isLoading,
    ModalLoading: state.settingsReducer.ModalLoading,
    alert: state.settingsReducer.alert,
    payload: state.settingsReducer.content,
    user: state.auth.user,
});

export default connect(mapStateToProps, {loadSettings})(Settings)