import React, {Component} from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TableViewIcon from '@mui/icons-material/TableView';
import ListSubheader from '@mui/material/ListSubheader';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import {ACCOUNT, ADMIN, BILL, SETTINGS, SHIPMENT, ADDRESS, USER} from "../redux/type";
import {connect} from "react-redux";
import {changePage} from "../redux/actions/indexAction";
class MainListItems extends Component {

    changePage = (toPage) => {
        this.props.changePage(toPage, this.props.history)
    }
    render() {
        let account;
        let admin;
        if (!this.props.isAuthenticated){
            return
        }
        if (this.props.user.is_superuser) {
            account = (
                <ListItemButton
                    divider={true} onClick={() => {
                    this.changePage(ACCOUNT)
                }}>
                    <ListItemIcon>
                        <AccountBoxIcon/>
                    </ListItemIcon>
                    <ListItemText primary="账户"/>
                </ListItemButton>
            )
            admin = (
                <ListItemButton
                    divider={true} onClick={() => {
                    this.changePage(ADMIN)
                }}>
                    <ListItemIcon>
                        <ManageAccountsIcon/>
                    </ListItemIcon>
                    <ListItemText primary="管理员"/>
                </ListItemButton>
            )
        }
        return (
            <React.Fragment>
                <Box>
                    <List component="nav">
                        <ListItemButton
                            divider={true} onClick={() => {
                            this.changePage(USER)
                        }}>
                            <ListItemIcon>
                                <HomeIcon/>
                            </ListItemIcon>
                            <ListItemText primary="主页"/>
                        </ListItemButton>
                        <ListItemButton
                            divider={true} onClick={() => {
                            this.changePage(SHIPMENT)
                        }}>
                            <ListItemIcon>
                                <TableViewIcon/>
                            </ListItemIcon>
                            <ListItemText primary="运单"/>
                        </ListItemButton>
                        <ListItemButton
                            name='bill' id='bill'
                            divider={true} onClick={() => {
                            this.changePage(BILL)
                        }}>
                            <ListItemIcon>
                                <ShoppingCartIcon/>
                            </ListItemIcon>
                            <ListItemText primary="对账"/>
                        </ListItemButton>
                        <ListItemButton
                            name='address' id='address'
                            divider={true} onClick={() => {
                            this.changePage(ADDRESS)
                        }}>
                            <ListItemIcon>
                                <TableViewIcon/>
                            </ListItemIcon>
                            <ListItemText primary="地址"/>
                        </ListItemButton>
                        <ListItemButton
                            name='settings' id='settings'
                            divider={true} onClick={() => {
                            this.changePage(SETTINGS)
                        }}>
                            <ListItemIcon>
                                <SettingsIcon/>
                            </ListItemIcon>
                            <ListItemText primary="设置"/>
                        </ListItemButton>
                        {account}
                        {admin}
                    </List>
                </Box>
            </React.Fragment>
        );
    }
}

export const secondaryListItems = (
    <React.Fragment>
        <ListSubheader component="div" inset>
            Saved reports
        </ListSubheader>
        <ListItemButton>
            <ListItemIcon>
                <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText primary="Current month"/>
        </ListItemButton>
        <ListItemButton>
            <ListItemIcon>
                <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText primary="Last quarter"/>
        </ListItemButton>
        <ListItemButton>
            <ListItemIcon>
                <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText primary="Year-end sale"/>
        </ListItemButton>
    </React.Fragment>
);

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    current: state.indexReducer.current
});

export default connect(mapStateToProps, {changePage})(MainListItems);
