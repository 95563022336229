import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import {AxiosDefaultRequest} from "../../components/default_request";
import server from "../../server";
import {connect} from "react-redux";
import {addCredit} from "../../redux/actions/modal";
import APICircularProgress from "../../components/APICircularProgress";
import APIAlert from "../../components/APIAlert";
import store from "../../redux/store";
import {MODAL_CLOSE_ALERT} from "../../redux/type";
import FormHelperText from "@mui/material/FormHelperText";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const re = {regex: /^(\+|-)?\d+(\.\d{0,2})?$/};

class AddOfferSheet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            display: false,
            credit: 0,
            account: [],
            uid: 0,
            pfn_number: 0

        }
    }

    Close = () => {
        this.setState({open: false})
    }
    Open = () => {
        this.setState({open: true})
    }

    componentDidMount() {
        AxiosDefaultRequest({
            url: server.get_offer_sheet_list,
            success: res => {
                this.setState({pfn_number: Object.keys(res.data).length})
            }
        })
    }

    handleChange = e => {
        this.setState({uid: e.target.value})
    }
    handleSubmit = e => {
        e.preventDefault()
        // const data = new FormData(e.currentTarget);
        // this.props.addCredit(this.state.uid, data.get('Credit'), data.get('Remark'))
    }
    closeAlert = () => {
        store.dispatch({type: MODAL_CLOSE_ALERT})
    }

    render() {
        let loading;
        let alert;
        let options = [];
        if (this.props.isLoading) {
            loading = APICircularProgress('success')
        }
        if (this.props.alert) {
            const {level, title, message} = this.props.payload
            const alert_p = {level: level, title: title, message: message, options: null, closeFunc: this.closeAlert}
            alert = APIAlert(alert_p)
        }
        return (
            <span>
                <Button variant="outlined" startIcon={<AttachMoneyIcon/>} sx={{mr: 1}} onClick={() => {
                    this.Open()
                }}>导入报价表</Button>
                {loading}
                {alert}
                <Dialog disableEscapeKeyDown open={this.state.open} onClose={() => {
                    this.Close()
                }}>
                        <DialogTitle>导入报价表</DialogTitle>
                        <DialogContent>
                            <Box component="form" onSubmit={this.handleSubmit} sx={{display: 'flex', flexWrap: 'wrap'}}>
                                <FormControl sx={{m: 1, minWidth: 500}}>
                                    <TextField
                                        id="PFNRecommend" name="PFNRecommend" type="text" variant="outlined"
                                        size="small" disabled label="建议使用PFN序号" value={this.state.pfn_number}
                                    />
                                </FormControl>
                                <FormControl sx={{m: 1, minWidth: 500}}>
                                    <TextField
                                        id="PFNRecommend" name="PFNRecommend" type="text" variant="outlined"
                                        size="small" label="PFN序列号"
                                    />
                                </FormControl>
                                <FormControl sx={{m: 1, minWidth: 500}}>
                                    <TextField
                                        id="OfferSheet" name="OfferSheet" type="file" variant="outlined"
                                        helperText="导入 FEDEX_GROUND 报价表文件" size="small"
                                    />
                                </FormControl>
                                <FormControl sx={{m: 1, minWidth: 500}}>
                                    <TextField
                                        id="OfferSheet" name="OfferSheet" type="file" variant="outlined"
                                        helperText="导入 FEDEX_GROUND_HOME_DELIVERY 报价表文件" size="small"
                                    />
                                </FormControl>
                                <FormControl sx={{m: 1, minWidth: 500}}>
                                    <TextField
                                        id="OfferSheet" name="OfferSheet" type="file" variant="outlined"
                                        helperText="导入 FEDEX_SMART_POST 报价表文件" size="small"
                                    />
                                </FormControl>
                                <FormControl sx={{m: 1, minWidth: 500}}>
                                    <TextField
                                        id="OfferSheet" name="OfferSheet" type="file" variant="outlined"
                                        helperText="导入 UPS_Ground 报价表文件" size="small"
                                    />
                                </FormControl>
                                <DialogActions>
                                    <Button fullWidth variant="contained" sx={{mt: 3, mb: 2, ml: 45}} onClick={() => {
                                        this.Close()
                                    }}>Cancel</Button>
                                    <Button fullWidth type='submit' variant="contained"
                                            sx={{mt: 3, mb: 2}}>Submit</Button>
                                </DialogActions>
                            </Box>
                        </DialogContent>
                    </Dialog>
            </span>
        );
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.modal.isLoading,
    alert: state.modal.alert,
    user: state.auth.user,
    payload: state.modal.content
});

export default connect(mapStateToProps, {addCredit})(AddOfferSheet);