import {GridRowsProp} from "@mui/x-data-grid/models/gridRows";
import {
    SETTINGS_CLOSE_ALERT,
    SETTINGS_LOADING,
    SETTINGS_MODAL_LOADING,
    SETTINGS_STOP,
    SETTINGS_TABLE_LOADING,
    LOAD_SETTINGS, VERIFY_CREDIT_SUCCESS, VERIFY_CREDIT_ERR
} from "../type";

const initialState = {
    isLoading: true,
    TableLoading: false,
    ModalLoading: false,
    pageSize: 30,
    page: 0,
    alert: false,
    rowCount: 0,
    display: true,
    data: [],
    search: null,
    searchClick: false,
    stop: true,
    orderClick: false,
    orders: [],
    content: null,
};

export default function (preState=initialState, action) {
    const {type, payload} = action
    switch (type) {
        case LOAD_SETTINGS:
            return {
                ...preState,
                isLoading: false
            }
        case SETTINGS_LOADING:
            return {
                ...preState,
                isLoading: true,
                request: false,
                content: null,
            }
        case SETTINGS_TABLE_LOADING:
            return {
                ...preState,
                TableLoading: true,
                request: false,
            }
        case SETTINGS_MODAL_LOADING:
            return {
                ...preState,
                ModalLoading: true,
                request: false,
                content: null,
            }
        case SETTINGS_CLOSE_ALERT:
            return {
                ...preState,
                alert: false
            }
        case SETTINGS_STOP:
            return {
                ...preState,
                stop: false,
                searchClick: false,
                orderClick: false
            }
        case VERIFY_CREDIT_SUCCESS:
        case VERIFY_CREDIT_ERR:
            return {
                ...preState,
                ModalLoading: false,
                request: true,
                alert: true,
                content: payload
            }
        default:
            return preState
    }
}