import {CHANGE_PAGE, SHIPMENT, BILL, ACCOUNT, LOGOUT, ADMIN, SETTINGS, ADDRESS, USER} from "../type";
import * as React from "react";


export const changePage = (toPage, history) => (dispatch, getState) => {
    const current = getState().indexReducer.current
    switch (toPage) {
        case USER:
            if (current === USER) {
                return
            }
            dispatch({type: CHANGE_PAGE, payload: {pageName: toPage, searchTable: null}});
            history.replace('/index/user')
            break
        case SHIPMENT:
            if (current === SHIPMENT) {
                return
            }
            dispatch({type: CHANGE_PAGE, payload: {pageName: toPage, searchTable: toPage}});
            history.replace('/index/shipment')
            break
        case BILL:
            if (current === BILL) {
                return
            }
            dispatch({type: CHANGE_PAGE, payload: {pageName: toPage, searchTable: toPage}});
            history.replace('/index/bill')
            break
        case SETTINGS:
            if (current === SETTINGS) {
                return
            }
            dispatch({type: CHANGE_PAGE, payload: {pageName: toPage, searchTable: toPage}});
            history.replace('/index/settings')
            break
        case LOGOUT:
            this.props.Logout();
            break
        case ACCOUNT:
            if (current === ACCOUNT) {
                return
            }
            dispatch({type: CHANGE_PAGE, payload: {pageName: toPage, searchTable: toPage}});
            history.replace('/index/account')
            break
        case ADMIN:
            if (current === ADMIN) {
                return
            }
            dispatch({type: CHANGE_PAGE, payload: {pageName: toPage, searchTable: null}});
            history.replace('/index/admin')
            break
        case ADDRESS:
            if (current === ADDRESS) {
                return
            }
            dispatch({type: CHANGE_PAGE, payload: {pageName: toPage, searchTable: null}});
            history.replace('/index/address')
            break
    }
};