import React from "react";
import {AxiosDefaultRequest} from "../../components/default_request";
import {
    ADD_CREDIT_ERR,
    ADD_CREDIT_SUCCESS,
    ADMIN_MODAL_LOADING,
    DELETE_RECORD_ERR,
    DELETE_RECORD_SUCCESS,
    DOWNLOAD_FILE_SUCCESS,
    GET_LABEL_ERR,
    GET_LABEL_SUCCESS,
    LOAD_ADMIN,
    LOAD_MODAL,
    MODAL_LOADING,
    RE_STORE_INVOICE_ERR,
    RE_STORE_INVOICE_SUCCESS,
    REPLENISH_ERR, REPLENISH_SUCCESS, SETTINGS_MODAL_LOADING,
    UPLOAD_INVOICE_ERR, LOAD_EXPORT_MODAL,
    UPLOAD_INVOICE_SUCCESS, VERIFY_CREDIT_ERR, VERIFY_CREDIT_SUCCESS,
    ADDRESS_MODAL_LOADING, ADDRESS_MODAL_SUCCESS, ADDRESS_MODAL_ERR, LOAD_ADDRESS
} from "../type";
import server from "../../server";
import store from "../store";
import {sort_order} from "../../components/utils";


export const addCredit = (uid, credit, remark) => (dispatch, getState) => {
    dispatch({type: MODAL_LOADING})
    AxiosDefaultRequest({
        url: server.add_credit_url,
        method: 'POST',
        data: {
            'uid': uid,
            'amount': credit,
            'note': remark
        }, success: res => {
            let result = res.data;
            if (result.status) {
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                dispatch({type: ADD_CREDIT_SUCCESS, payload: result})
            } else {
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: ADD_CREDIT_ERR, payload: result})
            }
        }, err: res => {
            let result = {'level': 'error', 'title': 'FAILED', 'message': 'Recharge failed, please check the data for errors and resubmit!'}
            dispatch({type: ADD_CREDIT_ERR, payload: result})
        }
    })
}

export const LoadCreditRecord = (offset, limit, setPage = null, setPageSize = null, options=null) => (dispatch, getState) => {
    let url = server.credit_record_url + "?offset=" + offset + "&limit=" + limit
    if (options){
        for (let i in options){
            if (options[i] !== ''){
                url = url + '&' + i + '=' + options[i]
            }
        }
    }
    AxiosDefaultRequest({
        url: url,
        method: 'GET',
        success: (res) => {
            const result = {data: res.data.rows, rowCount: res.data.total, isLoading: false, display: false};
            if (setPage) {
                result['page'] = setPage
            } else {
                result['page'] = store.getState().modal.page;
            }
            if (setPageSize) {
                result['pageSize'] = setPageSize
            } else {
                result['pageSize'] = store.getState().modal.pageSize;
            }
            dispatch({
                type: LOAD_MODAL, payload: result
            })
        }
    })
}

export const getLabel = (file_data, file_name) => (dispatch, getState) => {
    dispatch({type: ADMIN_MODAL_LOADING})
    AxiosDefaultRequest({
        url: server.get_label_url,
        method: 'POST',
        timeout: 100000000,
        data: {
            'get_ship_file': file_data,
            'file_name': file_name
        }, success: res => {
            let result = res.data;
            if (result.status) {
                result['request'] = true
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                dispatch({type: GET_LABEL_SUCCESS, payload: result})
                return true
            } else {
                result['request'] = false
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: GET_LABEL_ERR, payload: result})
                return false
            }

        }, err: res => {
            let result = {
                'request': false,
                'level': 'error',
                'title': 'FAILED',
                'message': 'Failed to upload the bill, please check the data for errors and resubmit!'
            }
            dispatch({type: GET_LABEL_ERR, payload: result})
            return false
        }
    })
}

export const uploadInvoice = (file_data, file_name) => (dispatch, getState) => {
    dispatch({type: ADMIN_MODAL_LOADING})
    AxiosDefaultRequest({
        url: server.async_upload_invoice_url,
        method: 'POST',
        data: {
            'invoice_file': file_data,
            'file_name': file_name,
        }, success: res => {
            let result = res.data;
            if (result.status) {
                result['request'] = true
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                dispatch({type: UPLOAD_INVOICE_SUCCESS, payload: result})

            } else {
                result['request'] = false
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: UPLOAD_INVOICE_ERR, payload: result})
            }

        }, err: res => {
            let result = {
                'request': false,
                'level': 'error',
                'title': 'FAILED',
                'message': 'Failed to upload the bill, please check the data for errors and resubmit!'
            }
            dispatch({type: UPLOAD_INVOICE_ERR, payload: result})
        }
    })
}

export const loadReceipt = (offset, limit, setPage=null, setPageSize=null, options=null) => dispatch => {
    let url = server.record_url + "?offset=" + offset + "&limit=" + limit
    if (options){
        for (let i in options){
            if (options[i] !== ''){
                url = url + '&' + i + '=' + options[i]
            }
        }
    }
    AxiosDefaultRequest({
        url: url,
        method: 'GET',
        success: (res) => {
            const result = {data: res.data.rows, rowCount: res.data.total, isLoading: false, display: false};
            if (setPage){
                result['page'] = setPage
            }else{
                result['page'] = store.getState().modal.page;
            }
            if (setPageSize){
                result['pageSize'] = setPageSize
            }else{
                result['pageSize'] = store.getState().modal.pageSize;
            }
            dispatch({
                type: LOAD_MODAL, payload: result
            })
        }
    })
}

export const voidShip = (file_data, file_name) => (dispatch, getState) => {
    dispatch({type: MODAL_LOADING})
    AxiosDefaultRequest({
        url: server.void_ship_url,
        method: 'POST',
        // headers: {'WEB-USER': store.getState().auth.user.username},
        data: {
            'file': file_data,
            'file_name': file_name,
        }, success: res => {
            let result = res.data;
            if (result.status) {
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                dispatch({type: UPLOAD_INVOICE_SUCCESS, payload: result})
            } else {
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: UPLOAD_INVOICE_ERR, payload: result})
            }

        }, err: res => {
            let result = {
                'level': 'error',
                'title': 'FAILED',
                'message': 'Failed to upload the bill, please check the data for errors and resubmit!'
            }
            dispatch({type: UPLOAD_INVOICE_ERR, payload: result})
        }
    })
}


export const replenish = (file_data, file_name, model) => (dispatch, getState) => {
    dispatch({type: ADMIN_MODAL_LOADING})
    AxiosDefaultRequest({
        url: server.replenish_url,
        method: 'POST',
        data: {
            'file': file_data,
            'file_name': file_name,
            'model': model
        }, success: res => {
            let result = res.data;
            if (result.status) {
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                dispatch({type: REPLENISH_SUCCESS, payload: result})
            } else {
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: REPLENISH_ERR, payload: result})
            }

        }, err: res => {
            let result = {
                'level': 'error',
                'title': 'FAILED',
                'message': 'Failed to upload the bill, please check the data for errors and resubmit!'
            }
            dispatch({type: REPLENISH_ERR, payload: result})
        }
    })
}


export const downloadFile = file_url => (dispatch, getState) => {
    dispatch({type: MODAL_LOADING})
    let url;
    if (server.test) {
        url = server.test_host + ":" + server.post + server.download_get_ship_url + '?file=' + file_url
    }else{
        url = server.host + server.download_get_ship_url + '?file=' + file_url
    }

    const download = document.createElement('a')
    download.href = url
    download.target = '_blank'
    download.click()
    dispatch({type: DOWNLOAD_FILE_SUCCESS})
}

export const downloadShipmentFile = id => (dispatch, getState) => {
    dispatch({type: MODAL_LOADING})
    let url;
    if (server.test) {
        url = server.test_host + ":" + server.post + server.download_get_shipment_url + '?id=' + id
    }else{
        url = server.host + server.download_get_shipment_url + '?id=' + id
    }

    const download = document.createElement('a')
    download.href = url
    download.target = '_blank'
    download.click()
    dispatch({type: DOWNLOAD_FILE_SUCCESS})
}

export const DeleteRecord = (id, data) => (dispatch, getState) => {
    dispatch({type: ADMIN_MODAL_LOADING})
    AxiosDefaultRequest({
        url: server.delete_record_url + '?id=' + id,
        method: 'GET',
        success: res => {
            let result = res.data;
            if (result.status) {
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                dispatch({type: DELETE_RECORD_SUCCESS, payload: result})
                let new_data = [...data];
                for (let i in data){
                    if (data[i].id === id) {
                        new_data.splice(i, 1)
                        break
                    }
                }
                dispatch({type: LOAD_ADMIN, payload: {'data': new_data, 'rowCount': new_data.length}})
            } else {
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: DELETE_RECORD_ERR, payload: result})
            }

        }, err: res => {
            let result = {
                'level': 'error',
                'title': 'FAILED',
                'message': 'Failed to upload the bill, please check the data for errors and resubmit!'
            }
            dispatch({type: DELETE_RECORD_ERR, payload: result})
        }
    })
}

export const recallInvoice = (bill_msg=null, id=null) => (dispatch, getState) => {
    dispatch({type: ADMIN_MODAL_LOADING})
    AxiosDefaultRequest({
        url: server.ReStoreBill_url,
        method: 'POST',
        data: JSON.stringify({
            'bill_msg': id ? '' : bill_msg,
            'bill_id': id ? id : null
        }), success: res => {
            let result = res.data;
            if (result.status) {
                result['request'] = true
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                dispatch({type: RE_STORE_INVOICE_SUCCESS, payload: result})

            } else {
                result['request'] = false
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: RE_STORE_INVOICE_ERR, payload: result})
            }

        }, err: res => {
            let result = {
                'request': false,
                'level': 'error',
                'title': 'FAILED',
                'message': 'Failed to upload the bill, please check the data for errors and resubmit!'
            }
            dispatch({type: RE_STORE_INVOICE_ERR, payload: result})
        }
    })
}
// LoadSecondaryTrackingNumber
export const LoadSecondaryTrackingNumber = (
    offset, limit, setPage=null, setPageSize=null, options=null, shipment_id=null) => (dispatch, getState) => {
    let url = server.dimension_list_url + "?offset=" + offset + "&limit=" + limit + "&shipment_id=" + shipment_id
    // if (options){
    //     for (let i in options){
    //         if (options[i] !== ''){
    //             url = url + '&' + i + '=' + options[i]
    //         }
    //     }
    // }
    AxiosDefaultRequest({
        url: url,
        method: 'GET',
        success: (res) => {
            const result = {data: res.data.rows, rowCount: res.data.total, isLoading: false, display: false};
            if (setPage){
                result['page'] = setPage
            }else{
                result['page'] = store.getState().modal.page;
            }
            if (setPageSize){
                result['pageSize'] = setPageSize
            }else{
                result['pageSize'] = store.getState().modal.pageSize;
            }
            dispatch({
                type: LOAD_MODAL, payload: result
            })
        }
    })
}

export const VerifyCredit = (is_verify, verifyCredit, email) => (dispatch, getState) => {
    dispatch({type: SETTINGS_MODAL_LOADING})
    AxiosDefaultRequest({
        url: server.verify_credit_url,
        method: 'POST',
        data: {
            'is_verify': is_verify,
            'verifyCredit': verifyCredit,
            'email': email
        }, success: res => {
            let result = res.data;
            if (result.status) {
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                dispatch({type: VERIFY_CREDIT_SUCCESS, payload: result})
            } else {
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: VERIFY_CREDIT_ERR, payload: result})
            }
        }, err: res => {
            let result = {'level': 'error', 'title': 'FAILED', 'message': 'Recharge failed, please check the data for errors and resubmit!'}
            dispatch({type: ADD_CREDIT_ERR, payload: result})
        }
    })
}


export const replenishMasterTrack = (file_data, file_name) => (dispatch, getState) => {
    dispatch({type: ADMIN_MODAL_LOADING})
    AxiosDefaultRequest({
        url: server.replenish_master_track_url,
        method: 'POST',
        data: {
            'file': file_data,
            'file_name': file_name,
        }, success: res => {
            let result = res.data;
            if (result.status) {
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                dispatch({type: REPLENISH_SUCCESS, payload: result})
            } else {
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: REPLENISH_ERR, payload: result})
            }

        }, err: res => {
            let result = {
                'level': 'error',
                'title': 'FAILED',
                'message': 'Failed to upload the bill, please check the data for errors and resubmit!'
            }
            dispatch({type: REPLENISH_ERR, payload: result})
        }
    })
}


export const addAddress = (data) => (dispatch, getState) => {
    dispatch({type: ADDRESS_MODAL_LOADING})
    let post_data = {};
    data.forEach((value, key) => post_data[key] = value);
    AxiosDefaultRequest({
        url: server.address_audit_url,
        method: 'POST',
        data: post_data,
        success: res => {
            let result = res.data;
            if (result.status) {
                result['level'] = 'success'
                result['title'] = '成功'
                result['message'] = result['message']
                dispatch({type: ADDRESS_MODAL_SUCCESS, payload: result})
            } else {
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: ADDRESS_MODAL_ERR, payload: result})
            }
        }, err: res => {
            let result = {'level': 'error', 'title': 'FAILED', 'message': 'Submitted failed, please check the data for errors and resubmit!'}
            dispatch({type: ADDRESS_MODAL_ERR, payload: result})
        }
    })
}

export const auditAddress = (id, comment, status) => (dispatch, getState) => {
    dispatch({type: ADDRESS_MODAL_LOADING})
    AxiosDefaultRequest({
        url: server.address_audit_url,
        method: 'PATCH',
        data: {
            "address_id": id,
            "status": status,
            "comment": comment
        },
        success: res => {
            let result = res.data;
            if (result.status) {
                result['level'] = 'success'
                result['title'] = '成功'
                result['message'] = result['message']
                dispatch({type: ADDRESS_MODAL_SUCCESS, payload: result})
            } else {
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: ADDRESS_MODAL_ERR, payload: result})
            }
        }, err: res => {
            let result = {'level': 'error', 'title': 'FAILED', 'message': 'Submitted failed, please check the data for errors and resubmit!'}
            dispatch({type: ADDRESS_MODAL_ERR, payload: result})
        }
    })
}


export const LoadExportPage = (offset, limit, setPage = null, setPageSize = null, options=null, orders=null) => (dispatch, getState) => {
    dispatch({type: MODAL_LOADING})
    let url = server.record_url + "?offset=" + offset + "&limit=" + limit
    if (options) {
        for (let i in options) {
            if (options[i] !== '') {
                url = url + '&' + i + '=' + options[i]
            }
        }
    }
    if (orders){
        url += sort_order(orders);
    }
    AxiosDefaultRequest({
        url: url,
        method: 'GET',
        success: (res) => {
            const result = {data: res.data.rows, rowCount: res.data.total, isLoading: false, display: false};
            if (setPage) {
                result['page'] = setPage
            } else {
                result['page'] = store.getState().modal.exportPage_page;
            }
            if (setPageSize) {
                result['pageSize'] = setPageSize
            } else {
                result['pageSize'] = store.getState().modal.exportPage_pageSize;
            }
            dispatch({
                type: LOAD_EXPORT_MODAL, payload: result
            })
        }
    })
}