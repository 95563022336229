export const SET = 'SET';
export const OUT = 'OUT';

export const USER_ERR = 'USER_ERR';
export const USER_LOAD = 'USER_LOAD';
export const IS_USER = 'IS_USER';

export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_ERR = 'LOGIN_ERR';
export const LOGIN_CLOSE_ALERT = 'LOGIN_CLOSE_ALERT';

export const USER_LOADED = 'USER_LOADED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_LOADING = 'REGISTER_LOADING';
export const REGISTER_CLOSE_ALERT = 'REGISTER_CLOSE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const MODAL_LOADING = 'MODAL_LOADING';
export const MODAL_CLOSE_ALERT = 'MODAL_CLOSE_ALERT';
export const ADD_CREDIT_SUCCESS = 'ADD_CREDIT_SUCCESS';
export const ADD_CREDIT_ERR = 'ADD_CREDIT_ERR';
export const ADD_CREDIT_RECORD_SEARCH_STOP = 'ADD_CREDIT_RECORD_SEARCH_STOP';
export const ADD_CREDIT_RECORD_SEARCH = 'ADD_CREDIT_RECORD_SEARCH';

export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';

export const UPLOAD_INVOICE_SUCCESS = 'UPLOAD_INVOICE_SUCCESS';
export const UPLOAD_INVOICE_ERR = 'UPLOAD_INVOICE_ERR';
export const DELETE_RECORD_SUCCESS = 'DELETE_RECORD_SUCCESS';
export const DELETE_RECORD_ERR = 'DELETE_RECORD_ERR';
export const RE_STORE_INVOICE_SUCCESS = 'RE_STORE_INVOICE_SUCCESS';
export const RE_STORE_INVOICE_ERR = 'RE_STORE_INVOICE_ERR';
export const REPLENISH_SUCCESS = 'REPLENISH_SUCCESS';
export const REPLENISH_ERR = 'REPLENISH_ERR';

export const GET_LABEL_SUCCESS = 'GET_LABEL_SUCCESS';
export const GET_LABEL_ERR = 'GET_LABEL_ERR';

export const LOAD_MODAL = 'LOAD_MODAL'

export const LOAD_SHIPMENT = 'LOAD_SHIPMENT';
export const SHIPMENT_LOADING = 'SHIPMENT_LOADING';
export const SHIPMENT_SEARCH = 'SHIPMENT_SEARCH';
export const SHIPMENT_SEARCH_UPDATE = 'SHIPMENT_SEARCH_UPDATE';
export const SHIPMENT_STOP = 'SHIPMENT_STOP';
export const SHIPMENT_ORDERS = 'SHIPMENT_ORDERS';
export const SHIPMENT_PREVIEW_ERR = 'SHIPMENT_PREVIEW_ERR';
export const SHIPMENT_MODAL_LOADING = 'SHIPMENT_MODAL_LOADING';
export const SHIPMENT_EXPORT = 'SHIPMENT_EXPORT';
export const SHIPMENT_EXPORT_SUCCESS = 'SHIPMENT_EXPORT_SUCCESS';
export const SHIPMENT_SEARCH_CLEAR = 'SHIPMENT_SEARCH_CLEAR';
export const SHIPMENT_CLOSE_ALERT = 'SHIPMENT_CLOSE_ALERT';

export const LOAD_BILL = 'LOAD_BILL';
export const BILL_LOADING = 'BILL_LOADING';
export const BILL_SEARCH = 'BILL_SEARCH';
export const BILL_SEARCH_UPDATE = 'BILL_SEARCH_UPDATE';
export const BILL_STOP = 'BILL_STOP';
export const BILL_ORDERS = 'BILL_ORDERS';
export const BILL_CLOSE_ALERT = 'BILL_CLOSE_ALERT';
export const BILL_EXPORT_SUCCESS = 'BILL_EXPORT_SUCCESS';

export const LOAD_ACCOUNT = 'LOAD_ACCOUNT';
export const ACCOUNT_LOADING = 'ACCOUNT_LOADING';
export const ACCOUNT_SEARCH = 'ACCOUNT_SEARCH';
export const ACCOUNT_SEARCH_UPDATE = 'ACCOUNT_SEARCH_UPDATE';
export const ACCOUNT_STOP = 'ACCOUNT_STOP';
export const ACCOUNT_ORDERS = 'ACCOUNT_ORDERS';

export const LOAD_ADMIN = 'LOAD_ADMIN';
export const ADMIN_LOADING = 'ADMIN_LOADING';
export const ADMIN_TABLE_LOADING = 'ADMIN_TABLE_LOADING';
export const ADMIN_MODAL_LOADING = 'ADMIN_MODAL_LOADING';
export const ADMIN_SEARCH = 'ADMIN_SEARCH';
export const ADMIN_SEARCH_UPDATE = 'ADMIN_SEARCH_UPDATE';
export const ADMIN_STOP = 'ADMIN_STOP';
export const ADMIN_ORDERS = 'ADMIN_ORDERS';

export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const SETTINGS_LOADING = 'SETTINGS_LOADING';
export const SETTINGS_TABLE_LOADING = 'SETTINGS_TABLE_LOADING';
export const SETTINGS_MODAL_LOADING = 'SETTINGS_MODAL_LOADING';
export const SETTINGS_SEARCH = 'SETTINGS_SEARCH';
export const SETTINGS_SEARCH_UPDATE = 'SETTINGS_SEARCH_UPDATE';
export const SETTINGS_STOP = 'SETTINGS_STOP';
export const SETTINGS_ORDERS = 'SETTINGS_ORDERS';
export const SETTINGS_CLOSE_REQ = 'SETTINGS_CLOSE_REQ';
export const SETTINGS_CLOSE_ALERT = 'SETTINGS_CLOSE_ALERT';

export const VERIFY_CREDIT_SUCCESS = 'VERIFY_CREDIT_SUCCESS';
export const VERIFY_CREDIT_ERR = 'VERIFY_CREDIT_ERR';

export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_CLOSE_ALERT = 'CHANGE_PASSWORD_CLOSE_ALERT';

export const LOAD_ADDRESS = 'LOAD_ADDRESS';
export const ADDRESS_LOADING = 'ADDRESS_LOADING';
export const ADDRESS_SEARCH = 'ADDRESS_SEARCH';
export const ADDRESS_SEARCH_UPDATE = 'ADDRESS_SEARCH_UPDATE';
export const ADDRESS_STOP = 'ADDRESS_STOP';
export const ADDRESS_ORDERS = 'ADDRESS_ORDERS';
export const ADDRESS_MODAL_LOADING = 'ADDRESS_MODAL_LOADING';
export const ADDRESS_MODAL_SUCCESS = 'ADDRESS_MODAL_SUCCESS';
export const ADDRESS_MODAL_ERR = 'ADDRESS_MODAL_ERR';
export const ADDRESS_CLOSE_ALERT = 'ADDRESS_CLOSE_ALERT';


// page
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const SET_CURRENT = 'SET_CURRENT';
export const SHIPMENT = 'SHIPMENT';
export const USER = 'USER';
export const BILL = 'BILL';
export const SETTINGS = 'SETTINGS';
export const ACCOUNT = 'ACCOUNT';
export const LOGOUT = 'LOGOUT';
export const ADMIN = 'ADMIN';
export const ADDRESS = 'ADDRESS'

export const OPEN_ALERT = 'OPEN_ALERT';
export const CLOSE_ALERT = 'CLOSE_ALERT';

export const ADMIN_CLOSE_REQ = 'ADMIN_CLOSE_REQ';
export const ADMIN_CLOSE_ALERT = 'ADMIN_CLOSE_ALERT';

export const LOAD_OFFER_SHEET = 'LOAD_OFFER_SHEET';
export const OFFER_SHEET_LOADING = 'OFFER_SHEET_LOADING';
export const OFFER_SHEET_TABLE_LOADING = 'OFFER_SHEET_TABLE_LOADING';
export const OFFER_SHEET_MODAL_LOADING = 'OFFER_SHEET_MODAL_LOADING';
export const OFFER_SHEET_CLOSE_ALERT = 'OFFER_SHEET_CLOSE_ALERT';
export const OFFER_SHEET_STOP = 'OFFER_SHEET_STOP';
export const OFFER_SHEET_ORDERS = 'OFFER_SHEET_ORDERS';

export const UPDATE_SURCHARGE_SUCCESS = 'UPDATE_SURCHARGE_SUCCESS';
export const UPDATE_SURCHARGE_ERR = 'UPDATE_SURCHARGE_ERR';
export const FEDEX_GRD_AD = "FEDEX_GRD_AD";
export const FEDEX_HOME_AD = "FEDEX_HOME_AD";
export const FEDEX_MWT_AD = "FEDEX_MWT_AD";
export const FEDEX_SMART_AD = "FEDEX_SMART_AD";
export const UPS_GRD_AD = "UPS_GRD_AD";

export const EXPORT_OPEN = "EXPORT_OPEN";
export const EXPORT_PAGE_SEARCH_STOP = "EXPORT_PAGE_SEARCH_STOP";
export const EXPORT_PAGE_SEARCH_UPDATE = "EXPORT_PAGE_SEARCH_UPDATE";
export const LOAD_EXPORT_MODAL = "LOAD_EXPORT_MODAL";

export const LOAD_USER = "LOAD_USER";
export const USER_LOADING = "USER_LOADING";
export const USER_MODAL_LOADING = "USER_MODAL_LOADING";

export const USER_TABLE_LOADING = "USER_TABLE_LOADING";
export const USER_STOP = "USER_STOP";
export const USER_ORDERS = "USER_ORDERS";
export const USER_SEARCH = "USER_SEARCH";
export const USER_SEARCH_CLEAR = "USER_SEARCH_CLEAR";
export const USER_SEARCH_UPDATE = "USER_SEARCH_UPDATE";
export const USER_PREVIEW_SUCCESS = "USER_PREVIEW_SUCCESS";
export const USER_PREVIEW_ERR = "USER_PREVIEW_ERR";
export const PREVIEW_USER_OPEN = "PREVIEW_USER_OPEN";
export const USER_EXPORT = "USER_EXPORT";
export const USER_EXPORT_SUCCESS = "USER_EXPORT_SUCCESS";
export const USER_CLOSE_ALERT = "USER_CLOSE_ALERT";
export const SPOD_SUCCESS = "SPOD_SUCCESS";
export const SPOD_ERR = "SPOD_ERR";