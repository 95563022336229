import {AxiosDefaultRequest} from "../../components/default_request";
import server from "../../server";
import {
    SHIPMENT_PREVIEW_ERR,
    LOAD_SHIPMENT,
    SET_CURRENT,
    SHIPMENT,
    SHIPMENT_LOADING,
    SHIPMENT_MODAL_LOADING,
    SHIPMENT_EXPORT,
    SHIPMENT_EXPORT_SUCCESS,
} from "../type";
import store from "../store";
import {sort_order} from "../../components/utils";

export const loadShipment = (offset, limit, setPage = null, setPageSize = null,
                             options = null, reload = null, orders = null) => (dispatch, getState) => {
    /*
    * offset: 开始
    * limit: 结束
    * setPage: 页面条目数量
    * setPageSize: 设置页面条目数
    * options: 当前用于search存储
    * reload: 忘了
    * orders: 排序
    * */
    if (!store.getState().shipment.search) {
        dispatch({type: SHIPMENT_LOADING})
    } else{
        if (store.getState().shipment.search.submitType === "export") {
            dispatch({type: SHIPMENT_EXPORT})
        }else{
            dispatch({type: SHIPMENT_LOADING})
        }
    }
    let url = server.ship_url + "?offset=" + offset + "&limit=" + limit
    if (options) {
        for (let i in options) {
            if (options[i] === null) {
                continue
                // options[i] = ''
            }
            if (options[i] !== '') {
                url = url + '&' + i + '=' + options[i]
            }
        }
    }
    url = url.replace("#", "%23")
    if (orders) {
        url += sort_order(orders);
    }
    AxiosDefaultRequest({
        url: url,
        method: 'GET',
        success: (res) => {
            if (store.getState().shipment.search) {
                if (store.getState().shipment.search.submitType === "export"){
                    dispatch({type: SHIPMENT_EXPORT_SUCCESS, payload: {
                            'request': true, 'level': 'success', 'title': 'SUCCESS',
                            'message': "导出成功!"}})
                    return
                }
            }
            const result = {data: res.data.rows, rowCount: res.data.total, display: false};
            if (String(setPage) && setPage !== null) {
                result['page'] = setPage
            } else {
                result['page'] = store.getState().shipment.page;
            }
            if (String(setPageSize) && setPageSize !== null) {
                result['pageSize'] = setPageSize
            } else {
                result['pageSize'] = store.getState().shipment.pageSize;
            }
            dispatch({type: SET_CURRENT, payload: {current: SHIPMENT, searchTable: SHIPMENT, currentUrl: "/index/shipment"}})
            dispatch({
                type: LOAD_SHIPMENT, payload: result
            })
        }
    })
}