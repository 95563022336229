import {
    USER_LOAD,
    USER_ERR,
    USER_LOADING,
    LOGOUT_SUCCESS,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_LOADING,
    SET,
    LOGIN_ERR,
    CHANGE_PASSWORD_LOADING, CHANGE_PASSWORD_SUCCESS, REGISTER_FAIL, REGISTER_LOADING, REGISTER_SUCCESS
} from "../type";
import {AxiosDefaultRequest} from "../../components/default_request";
import store from "../store";


export const loadUser = () => (dispatch, getState) => {
    dispatch({type: USER_LOADING});
    AxiosDefaultRequest({
        url: '/login/',
        method: 'GET',
        success: res => {
            // dispatch({type: USER_LOAD, content: res.data})  // 似乎没用上
            dispatch({type: LOGIN_SUCCESS, payload: res.data})
        },
        err: err => {
            // 无法判断超时或者未登录
            dispatch({
                type: LOGIN_ERR, payload: {
                    level: "info", title: "Message", message: "Not logged in or status has timed out, please log in again!", timeout: 3000}})
            dispatch({type: USER_ERR})
        }
    })
};

export const login = (username, password, RememberMe) => dispatch => {
    dispatch({type: LOGIN_LOADING})
    AxiosDefaultRequest({
        method: "POST",
        url: "/login/",
        data: JSON.stringify({
            "username": username,
            "password": password
        }),
        success: (msg) => {
            let result = msg.data
            result['title'] = 'SUCCESS'
            result['level'] = 'success'
            result['token'] = result['token']
            result['timeout'] = 3000
            result['message'] = result['message'] + 'You will go to the homepage in 3 seconds!'
            dispatch({type: SET, payload: result})
            setTimeout(() => {
                if (RememberMe){
                    localStorage.setItem('username', username)
                    localStorage.setItem('password', password)
                    localStorage.setItem('RememberMe', RememberMe)
                }
                dispatch({type: LOGIN_SUCCESS, payload: result})
            }, 100)

        },
        err: () => {
            const result = {'title': 'FAILED', 'level': 'error', 'timeout': 5000,
                'message': 'Authentication error, please check the entered username and password and log in again!'}
            setTimeout(() => {
                dispatch({type: LOGIN_ERR, payload: result})
            }, 100)
        }
    });
};


export const Logout = () => (dispatch, getState) => {
    dispatch({type: USER_LOADING});
    AxiosDefaultRequest({
        method: "GET",
        url: "/logout/",
        success: (msg) => {
            setTimeout(() => {
                dispatch({
                    type: LOGOUT_SUCCESS
                });
            }, 500)

        },
        error: (msg) => {
            dispatch({
                type: AUTH_ERROR
            })
        }
    });
}

export const changePassword = (user, oldPassword, newPassword) => (dispatch, getState) => {
    dispatch({type: CHANGE_PASSWORD_LOADING});

    AxiosDefaultRequest({
        method: "POST",
        url: "/change_password/",
        data: JSON.stringify({
            'username': user.username,
            'oldPassword': oldPassword,
            'newPassword': newPassword
        }),
        success: (msg) => {
            let result = msg.data
            if (result.status){
                result['title'] = 'SUCCESS'
                result['level'] = 'success'
                result['message'] = result['message']
                dispatch({type: SET, payload: result})
                setTimeout(() => {
                    dispatch({
                        type: CHANGE_PASSWORD_SUCCESS
                    });
                }, 100)
            }else {
                result['title'] = 'FAILED'
                result['level'] = 'error'
                result['timeout'] = 10000
                result['message'] = result['message']
                dispatch({type: SET, payload: result})
            }
        },
        error: (msg) => {
            dispatch({
                type: AUTH_ERROR
            })
        }
    });
}

export const register = (username, password, uid, key, src_user, pfn, services) => dispatch => {
    dispatch({type: REGISTER_LOADING})
    console.log(store.getState().auth)
    AxiosDefaultRequest({
        method: "POST",
        url: "/register/",
        headers: {'WEB-USER': store.getState().auth.user.username},
        data: {
            "username": username,
            // "password": password,
            "UID": uid,
            "APIkey": key,
            "src_user": src_user,
            "Pfn": pfn,
            "services": services
        },
        success: (msg) => {
            let result = msg.data
            result['title'] = 'SUCCESS'
            result['level'] = 'success'
            result['token'] = result['token']
            result['timeout'] = 3000
            result['message'] = result['message']
            dispatch({type: REGISTER_SUCCESS, payload: result})

        },
        err: (e, f, g) => {
            const result = {'title': 'FAILED', 'level': 'error', 'timeout': 10000,
                'message': 'Registration failed, please check the data and submit again!'}
            setTimeout(() => {
                dispatch({type: REGISTER_FAIL, payload: result})
            }, 3000)
        }
    });
};