import {
    LOAD_BILL,
    BILL_LOADING,
    BILL_SEARCH,
    BILL_STOP,
    BILL_SEARCH_UPDATE,
    BILL_ORDERS,
    BILL_CLOSE_ALERT,
    BILL_EXPORT_SUCCESS
} from "../type";
import {GridRowsProp} from "@mui/x-data-grid/models/gridRows";

const initialState = {
    isLoading: true,
    pageSize: 30,
    page: 0,
    alert: false,
    rowCount: 0,
    display: true,
    data: [],
    search: null,
    searchClick: false,
    stop: true,
    orderClick: false,
    orders: [],
    content: null,
};

export default function (preState=initialState, action) {
    const {type, payload} = action
    switch (type) {
        case LOAD_BILL:
            const rows : GridRowsProp = payload.data
            return {
                ...preState,
                data: rows,
                rowCount: payload.rowCount,
                isLoading: false,
                page: payload.page,
                pageSize: payload.pageSize
            }
        case BILL_LOADING:
            return {
                ...preState,
                isLoading: true,
                data: []
            }
        case BILL_STOP:
            return {
                ...preState,
                stop: false,
                searchClick: false,
                orderClick: false
            }
        case BILL_ORDERS:
            return {
                ...preState,
                orderClick: true,
                orders: payload
            }
        case BILL_SEARCH:
            return {
                ...preState,
                page: 0,
                pageSize: 30,
                stop: true,
                searchClick: true
            }
        case BILL_SEARCH_UPDATE:
            let search;
            if (!preState.search) {
                search = payload;
            } else {
                search = preState.search;
                for (let i in payload){
                    search[i] = payload[i]
                }
            }
            return {
                ...preState,
                search: search,
                searchClick: false
            }
        case BILL_CLOSE_ALERT:
            return {
                ...preState,
                alert: false
            }
        case BILL_EXPORT_SUCCESS:
            return {
                ...preState,
                alert: true,
                content: payload,
                search: null
            }
        default:
            return preState
    }
}