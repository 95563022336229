export default function (localStorageVisibilityKey, columnVisibilityModel) {
    const isVisibility = localStorage.getItem(localStorageVisibilityKey)
    let setVisibility;
    if (!isVisibility) {
        localStorage.setItem(localStorageVisibilityKey, JSON.stringify(columnVisibilityModel));
        setVisibility = columnVisibilityModel;
    } else {
        setVisibility = Object.assign(JSON.parse(isVisibility), columnVisibilityModel);
    }
    return setVisibility
}