import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import {connect} from "react-redux";
import {VerifyCredit} from "../../redux/actions/modal";
import store from "../../redux/store";
import {MODAL_CLOSE_ALERT, VERIFY_CREDIT_ERR} from "../../redux/type";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import FormControlLabel from "@mui/material/FormControlLabel";
import {Switch} from "@mui/material";

class CheckCredit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            is_verify_credit: store.getState().auth.user.is_verify_credit,
            verify_credit: store.getState().auth.user.verify_credit,
            email: store.getState().auth.user.email,
            emailStatus: true,
            errorText: '请输入邮箱地址,以分号[;]分隔多个'
        }
    }

    Close = () => {
        this.setState({open: false})
    }
    Open = () => {
        this.setState({open: true})
    }

    handleChange = e => {
        this.setState({uid: e.target.value})
    }
    handleSubmit = e => {
        e.preventDefault()
        this.props.VerifyCredit(this.state.is_verify_credit, this.state.verify_credit, this.state.email)
        this.setState({open: !this.state.open})
    }
    closeAlert = () => {
        store.dispatch({type: MODAL_CLOSE_ALERT})
    }

    render() {
        return (
            <span>
                <Button startIcon={<CurrencyExchangeIcon/>} onClick={() => {
                    this.Open()
                }}>余额提醒</Button>
                <Dialog disableEscapeKeyDown open={this.state.open} onClose={() => {
                    this.Close()
                }}>
                    <DialogTitle>余额提醒</DialogTitle>
                    <DialogContent>
                        <Box component="form" onSubmit={this.handleSubmit} sx={{display: 'flex', flexWrap: 'wrap'}}>
                            <FormControlLabel sx={{m: 1, minWidth: 500}} control={
                                <Switch checked={this.state.is_verify_credit}
                                        onClick={(e) => {
                                            this.setState({is_verify_credit: !this.state.is_verify_credit})
                                        }}
                                />} label="是否开启余额提示功能"/>

                            <FormControl sx={{m: 2.5, minWidth: 500}}>
                                <TextField type='text' id="check_credit_email" name="check_credit_email"
                                           label="Email" variant="standard" helperText={this.state.errorText}
                                           value={this.state.email}
                                           onChange={(e) => {
                                               this.setState({email: e.target.value})
                                           }}
                                           onBlur={event => {
                                               let verifyEmail = /.*@.*\..*/
                                               let new_emails = event.target.value.replace("；", ";").split(";");
                                               for (let a = 0; a < new_emails.length; a++) {
                                                   if (!verifyEmail.test(new_emails[a]) && new_emails[a] !== '') {
                                                       this.setState({
                                                           errorText: '[' + new_emails[a] + ']不是一个邮箱地址!',
                                                           emailStatus: false
                                                       })
                                                       return
                                                   }
                                               }
                                               this.setState({
                                                   emailStatus: true,
                                                   errorText: '邮箱地址,以分号[;]分隔多个',
                                                   email: event.target.value.replace("；", ";")
                                               })
                                           }}
                                           required
                                           error={!this.state.emailStatus}
                                />
                            </FormControl>

                            <FormControl sx={{m: 2.5, minWidth: 500}}>
                                <TextField type='number' id="verify_credit" name="verify_credit"
                                           label="Credit" variant="standard" helperText="需要提示的金额线"
                                           value={this.state.verify_credit}
                                           onChange={(e) => {
                                               this.setState({verify_credit: e.target.value})
                                           }}
                                           required
                                />
                            </FormControl>
                            <DialogActions>
                                <Button fullWidth variant="contained" sx={{mt: 3, mb: 2, ml: 45}} onClick={() => {
                                    this.Close()
                                }}>Cancel</Button>
                                <Button fullWidth type='submit' variant="contained" disabled={!this.state.emailStatus}
                                        sx={{mt: 3, mb: 2}}>Submit</Button>
                            </DialogActions>
                        </Box>
                    </DialogContent>
                </Dialog>
            </span>
        );
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

export default connect(mapStateToProps, {VerifyCredit})(CheckCredit);