import {
    REGISTER_LOADING,
    REGISTER_CLOSE_ALERT,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
} from "../type";

const initialState = {
    isLoading: false,
    alert: false,
    content: null
};

export default function RegisterReducer(preState=initialState, action) {
    const {type, payload} = action
    switch (type) {
        case REGISTER_LOADING:
            return (preState = {
                ...preState,
                isLoading: true
            })
        case REGISTER_FAIL:
        case REGISTER_SUCCESS:
            return (preState = {
                ...preState,
                isLoading: false,
                alert: true,
                content: payload
            })
        case REGISTER_CLOSE_ALERT:
            return (preState = {
                ...preState,
                alert: false
            })
        default:
            return preState
    }
}

