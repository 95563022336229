import * as React from 'react';
import {connect} from "react-redux";
import {Logout} from "../redux/actions/auth";

class Index extends React.Component {

    render() {
        return (
            <html>
            <header>
                <meta httpEquiv="Content-Type" content="text/html;charset=utf-8"/>
                <title>广州辕蓬物流有限公司</title>
            </header>
            <body>
            <div><img src="./c.png" alt="my_img" width="100%" height="950"/></div>
            <div align={"center"}>Copyright 2023版权所有 广州辕篷物流有限公司 <a href="http://beian.miit.gov.cn/"
                                                               target="_blank">粤ICP备2023074004号</a></div>
            </body>
            </html>
        )
    }

}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    isLoading: state.auth.isLoading,
    current: state.indexReducer.current,
    searchTable: state.indexReducer.searchTable,
    Alert: state.alertReducer
});

export default connect(mapStateToProps, {Logout})(Index);