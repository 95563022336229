import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import {connect} from "react-redux";
import store from "../../redux/store";
import {USER_CLOSE_ALERT} from "../../redux/type";
import InputAdornment from "@mui/material/InputAdornment";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import {batchQueryTraceNumbers} from "../../redux/actions/userAction";


class BatchQueryTraceNumbers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            batch_file: null
        }
    }
    Close = () => {
        this.setState({open: false})
    }
    Open = () => {
        this.setState({open: true})
    }
    handleSubmit = e => {
        e.preventDefault()
        const data = new FormData(e.currentTarget);
        this.props.batchQueryTraceNumbers(this.state.batch_file, data.get('batch_file').name)
        this.setState({open: false})
    }
    closeAlert = () => {
        store.dispatch({type: USER_CLOSE_ALERT})
    }
    ChangeFile = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            this.setState({batch_file: event.target.result})
        };
    }
    render() {
        return (
            <span>
                <Button sx={{textTransform: 'none'}} startIcon={<FormatAlignJustifyIcon/>} onClick={() => {
                    this.Open()
                }}>追踪号批量查询</Button>
                <Dialog disableEscapeKeyDown open={this.state.open} onClose={() => {
                    this.Close()
                }}>
                        <DialogTitle>追踪号批量查询</DialogTitle>
                        <DialogContent>
                            <Box component="form" onSubmit={this.handleSubmit} sx={{display: 'flex', flexWrap: 'wrap'}}>
                                <FormControl sx={{m: 1, minWidth: 500, minHeight: 500}}>
                                    <TextField
                                        id="batch_file"
                                        name="batch_file"
                                        type='file'
                                        label="请上传一个TXT文件,内容为每行一个追踪号 (Note: 格式为.txt)"
                                        onChange={(e) => {
                                            this.ChangeFile(e)
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FileUploadIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                    />
                                </FormControl>

                                <DialogActions>
                                    <Button fullWidth variant="contained" sx={{mt: 3, mb: 2, ml: 45}} onClick={() => {this.Close()}}>Cancel</Button>
                                    <Button fullWidth type='submit' variant="contained" sx={{mt: 3, mb: 2}}>Submit</Button>
                                </DialogActions>
                            </Box>
                        </DialogContent>
                    </Dialog>
            </span>
        );
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

export default connect(mapStateToProps, {batchQueryTraceNumbers})(BatchQueryTraceNumbers);