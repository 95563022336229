import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as React from "react";
import gaba from  "./../images/gaba.png"

export default function Copyright(props) {
    // return (
    //     <Typography variant="body2" color="text.secondary" align="center" {...props}>
    //         <div align={"center"}>Copyright {new Date().getFullYear()}.版权所有 广州辕篷物流有限公司</div>
    //         <div align={"center"}>
    //             <a href="http://beian.miit.gov.cn/" target="_blank" rel="noreferrer">粤ICP备2023074004号</a>
    //         </div>
    //         <div>
    //             <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?spm=a21bo.jianhua.1997523009.39.5af92a89MF6cGU&recordcode=44010602011743" rel="noreferrer">
    //                 <img src={gaba} alt="ba"/>粤公网安备 44010602011743号
    //             </a>
    //         </div>
    //     </Typography>
    // );
    return (
        <footer style={{ textAlign: 'center', padding: '20px 0' }}>
            <Typography variant="body2" color="textSecondary">
                &copy; {new Date().getFullYear()} 版权所有 广州辕篷物流有限公司
            </Typography>
            <Typography variant="body2" color="textSecondary">
                <a href="http://beian.miit.gov.cn/" target="_blank" rel="noreferrer">粤ICP备44010602011743号</a>
            </Typography>
            <Typography variant="body2" color="textSecondary">
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?spm=a21bo.jianhua.1997523009.39.5af92a89MF6cGU&recordcode=44010602011743" rel="noreferrer">
                    <img src={gaba} alt="ba"/>粤公网安备 44010602011743号
                </a>
            </Typography>
        </footer>
    );
}