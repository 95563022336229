import React, {Component} from 'react';
import {Route, Switch, Redirect, withRouter} from 'react-router-dom'
import Login from './pages/Login'
import Home from "./pages/Home";
import { Provider } from "react-redux";
import store from './redux/store'
import {loadUser} from "./redux/actions/auth";
import ChangePassword from "./pages/ChangePassword";
import { LicenseInfo } from '@mui/x-license-pro';
import Register from "./pages/Register";
import OfferSheet from "./pages/OfferSheet";
import DownloadFile from "./components/download_file";
import {Link} from "@mui/material";
import Index from "./pages";


LicenseInfo.setLicenseKey(
  '9bcbec32f76ac4cfc4749c7162169a50T1JERVI6NDI1MzUsRVhQSVJZPTE2ODI1Njk0MTgwMDAsS0VZVkVSU0lPTj0x',
);
class App extends Component {
    constructor(props) {
        super(props);
    }

    // UNSAFE_componentWillMount() {
    //     console.log('test')
    //     store.dispatch(loadUser());
    // }
    UNSAFE_componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
        store.dispatch(loadUser());
    }

    render() {
        return (
            <Provider store={store}>
            <div>
                <Switch>
                    {/*<Route path="/" component={Index} title={"Index"}/>*/}
                    <Route path="/index" component={Home} title={"Home"}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/ChangePassword" component={ChangePassword} />
                    <Route path="/Register" component={Register} />
                    <Route path="/OfferSheet" component={OfferSheet} />
                    <Route path="/download_file/:download_url_datetime/:track.pdf" component={DownloadFile}/>
                    <Redirect to="/index" />
                </Switch>
            </div>
            </Provider>
        );
    }
}



export default withRouter(App)