import React, {Component} from 'react';
import $ from 'jquery';
import server from '../server.js'
import GetCookie from "./GetCookie";
import axios from "axios";
import store from "../redux/store";

export function AjaxDefaultRequest(props) {
    let token = store.getState().auth.token;
    const {url} = props
    let send_url;
    if (server.test) {
        send_url = server.test_host + ":" + server.post + url
    } else {
        send_url = server.host + url
    }
    $.ajax({
        ...props,
        url: send_url,
        headers: {
            "X-CSRFtoken": token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'sessionid': localStorage.getItem('sessionid')
        },
        dataType: 'json',
        xhrFields: {
            withCredentials: true
        },
        timeout: 10000000000000
    })
    // console.log(props)
}

export function AxiosDefaultRequest(props) {
    let token = store.getState().auth.token;
    const {url, success, err} = props
    let send_url;
    if (server.test) {
        send_url = server.test_host + ":" + server.post + url
    } else {
        send_url = server.host + url
    }
    axios.defaults.withCredentials = true;
    let default_headers = {
        "X-CSRFtoken": token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    if (props['headers']) {
        let new_headers = Object.keys(props['headers'])
        for (let i=0;i<new_headers.length;i++){
            default_headers[new_headers[i]] = props['headers'][new_headers[i]]
        }
    }
    axios({
        ...props,
        url: send_url,
        dataType: 'json',
        headers: default_headers,
        // headers: {
        //     "X-CSRFtoken": token,
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json',
        // },
        timeout: 100000000000,
        withCredentials: true
    }).then(success).catch(err)
    // console.log(props)
}


