import {
    LOAD_ADDRESS,
    ADDRESS_LOADING,
    ADDRESS_ORDERS,
    ADDRESS_SEARCH,
    ADDRESS_STOP,
    ADDRESS_SEARCH_UPDATE,
    ADDRESS_MODAL_LOADING,
    ADDRESS_MODAL_SUCCESS,
    ADDRESS_MODAL_ERR, ADDRESS_CLOSE_ALERT
} from "../type";
import {GridRowsProp} from "@mui/x-data-grid/models/gridRows";

const initialState = {
    isLoading: false,
    pageSize: 30,
    page: 0,
    rowCount: 0,
    display: true,
    search: null,
    searchClick: false,
    data: [],
    stop: true,
    orderClick: false,
    orders: [],
    ModalLoading: false
};

export default function (preState=initialState, action) {
    const {type, payload} = action
    switch (type) {
        case LOAD_ADDRESS:
            const rows : GridRowsProp = payload.data
            return {
                ...preState,
                data: rows,
                rowCount: payload.rowCount,
                isLoading: false,
                page: payload.page,
                pageSize: payload.pageSize
            }
        case ADDRESS_LOADING:
            return {
                ...preState,
                isLoading: true,
                data: []
            }
        case ADDRESS_STOP:
            return {
                ...preState,
                stop: false,
                searchClick: false,
                orderClick: false
            }
        case ADDRESS_ORDERS:
            return {
                ...preState,
                orderClick: true,
                orders: payload
            }
        case ADDRESS_SEARCH:
            return {
                ...preState,
                data: [],
                page: 0,
                pageSize: 30,
                stop: true,
                searchClick: true
            }
        case ADDRESS_SEARCH_UPDATE:
            let search;
            if (!preState.search) {
                search = payload;
            } else {
                search = preState.search;
                for (let i in payload){
                    search[i] = payload[i]
                }
            }
            return {
                ...preState,
                search: search,
                searchClick: false
            }
        case ADDRESS_MODAL_SUCCESS:
        case ADDRESS_MODAL_ERR:
            return {
                ...preState,
                ModalLoading: false,
                request: true,
                alert: true,
                content: payload
            }
        case ADDRESS_MODAL_LOADING:
            return {
                ...preState,
                ModalLoading: true,
                request: false,
                content: null,
            }
        case ADDRESS_CLOSE_ALERT:
            return {
                ...preState,
                request: true,
                alert: false
            }
        default:
            return preState
    }
}