import React, {Component} from 'react';
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton
} from "@mui/x-data-grid-pro";
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from "@mui/material/Button";
import {connect} from "react-redux";
import {loadShipment} from "../redux/actions/shipment";
import {loadBill} from "../redux/actions/BillAction";
import {loadAccount} from "../redux/actions/account";
import {ACCOUNT, ADMIN, BILL, SHIPMENT, USER} from "../redux/type";
import {AdminPreLoad} from "../redux/actions/adminAction";
import {LoadExportPage} from "../redux/actions/modal";
import {LoadUserTable} from "../redux/actions/userAction";

class ApiToolBar extends Component {
    constructor(props) {
        super(props);
        let runFunc, name;

        switch (this.props.current.pageName) {
            case USER:
                runFunc = this.props.LoadUserTable;
                name = 'user';
                break;
            case SHIPMENT:
                runFunc = this.props.loadShipment;
                name = 'shipment';
                break;
            case BILL:
                runFunc = this.props.loadBill;
                name = 'bill';
                break;
            case ACCOUNT:
                runFunc = this.props.loadAccount;
                name = 'account';
                break;
            case ADMIN:
                runFunc = this.props.AdminPreLoad;
                name = 'admin';
                break;
            default:
                runFunc = null;
                name = null;
        }

        // 初始化状态
        this.state = { runFunc, name };
    }

    Refresh = () => {
        if (this.props.ExportOpen){
            this.props.LoadExportPage(
                (this.props['export'].page * this.props['export'].pageSize), // 0*30=0 1*30=30 2*30=60
                ((this.props['export'].page + 1) * this.props['export'].pageSize), // (0+1)*30=30 (1+1)*30=60 (2+1)*30=90
                this.props['export'].page, null, this.props['export'].search)
        }else{
            this.state.runFunc(
                (this.props[this.state.name].page * this.props[this.state.name].pageSize), // 0*30=0 1*30=30 2*30=60
                ((this.props[this.state.name].page + 1) * this.props[this.state.name].pageSize), // (0+1)*30=30 (1+1)*30=60 (2+1)*30=90
                this.props[this.state.name].page, null, this.props[this.state.name].search)
        }
    }

    render() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton/>
                <GridToolbarFilterButton/>
                <GridToolbarDensitySelector/>
                <GridToolbarExport/>
                <Button startIcon={<RefreshIcon/>} onClick={() => {
                    this.Refresh()
                }}>
                    刷新
                </Button>
                {this.props.options}
            </GridToolbarContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    shipment: state.shipment,
    bill: state.BillReducer,
    account: state.account,
    admin: state.adminReducer,
    user: state.userReducer,

    export: {
        page: state.modal.exportPage_page,
        pageSize: state.modal.exportPage_pageSize,
        search: state.modal.exportPage_search,
    },
    addCredit: {
        page: state,
        pageSize: state,
        search: state,
    },
    current: state.indexReducer,
    ExportOpen: state.indexReducer.ExportOpen  // 导出组件
});

export default connect(mapStateToProps, {
    loadShipment, loadBill, loadAccount,
    AdminPreLoad, LoadExportPage, LoadUserTable})(ApiToolBar);