import React, {Component} from 'react';
import {connect} from "react-redux";
import {Logout} from "../redux/actions/auth";

import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";

import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PasswordIcon from "@mui/icons-material/Password";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import ListItemIcon from "@mui/material/ListItemIcon";

class AccountMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorElUser: null
        }
    }

    // -------------------- start user avatar ------------------------------------------
    stringToColor = (string) => {
        let hash = 0;
        let i;
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        return color;
    }
    stringAvatar = (name) => {
        return {
            sx: {
                bgcolor: this.stringToColor(name),
            },
            children: `${name[0]}`,
        };
    }
    handleOpenUserMenu = event => this.setState({anchorElUser: event.currentTarget})
    handleCloseUserMenu = () => this.setState({anchorElUser: null})

    // ---------------------end user avatar --------------------------------------------

    render() {
        return (
            <span>
                <Tooltip title="Open settings">
                    <IconButton onClick={(e) => {
                        this.handleOpenUserMenu(e)
                    }} sx={{p: 0}}>
                        <Avatar alt="Remy Sharp" {...this.stringAvatar(this.props.user.username)}/>
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{mt: '45px', width: '400px'}}
                    id="menu-appbar"
                    anchorEl={this.state.anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(this.state.anchorElUser)}
                    onClose={() => (this.handleCloseUserMenu())}
                >
                    <MenuItem key={'user'} disableGutters={false} aria-readonly={"true"}>
                        <ListItemIcon>
                            <AccountCircleRoundedIcon/>
                        </ListItemIcon>
                        <Typography textAlign="center">{this.props.user.username}</Typography>
                    </MenuItem>
                    <MenuItem key={'credit'} disableGutters={false} aria-readonly={"true"}>
                        <ListItemIcon>
                            <AttachMoneyIcon/>
                        </ListItemIcon>
                        <Typography textAlign="center">{this.props.user.credit}</Typography>
                    </MenuItem>
                    <MenuItem key={'changePassword'} disableGutters={false} onClick={() => {
                        this.props.history.push('/changePassword')
                    }}>
                        <ListItemIcon>
                            <PasswordIcon/>
                        </ListItemIcon>
                        <Typography textAlign="center">Change Password</Typography>
                    </MenuItem>
                    <MenuItem key={'logout'} aria-readonly={"true"} disableGutters={false} onClick={() => {
                        this.props.Logout()
                    }}>
                        <ListItemIcon>
                            <LogoutSharpIcon/>
                        </ListItemIcon>
                        <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                </Menu>
            </span>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});

export default connect(mapStateToProps, {Logout})(AccountMenu);