import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import {connect} from "react-redux";
import {recallInvoice} from "../../redux/actions/modal";
import store from "../../redux/store";
import {MODAL_CLOSE_ALERT} from "../../redux/type";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

class RecallInvoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            re_store_bill: null
        }
    }
    Close = () => {this.setState({open: false})}
    Open = () => {this.setState({open: true})}
    handleSubmit = e => {
        e.preventDefault()
        this.props.recallInvoice(this.state.re_store_bill)
    }
    closeAlert = () => {
        store.dispatch({type: MODAL_CLOSE_ALERT})
    }
    render() {
        return (
            <span>
                <Button sx={{textTransform: 'none'}} startIcon={<FileUploadOutlinedIcon/>} onClick={() => {this.Open()}}>撤回账单</Button>
                    <Dialog disableEscapeKeyDown open={this.state.open} onClose={() => {
                        this.Close()
                    }}>
                        <DialogTitle>ReStoreBill</DialogTitle>
                        <DialogContent>
                            <Box component="form" onSubmit={this.handleSubmit} sx={{display: 'flex', flexWrap: 'wrap'}}>
                                <FormControl sx={{m: 1, minWidth: 500, minHeight: 500}}>
                                    <TextField
                                        id="re_store_bill"
                                        name="re_store_bill"
                                        type='text'
                                        required

                                        // label="Please upload billing documents(Note: The uploaded file is a xls file)"
                                        onChange={(e) => {
                                            this.setState({re_store_bill: e})
                                        }}
                                        variant="standard"
                                        label="billMsg"
                                        value={this.state.re_store_bill}
                                    />
                                </FormControl>

                                <DialogActions>
                                    <Button fullWidth variant="contained" sx={{mt: 3, mb: 2, ml: 45}} onClick={() => {this.Close()}}>Cancel</Button>
                                    <Button fullWidth type='submit' variant="contained" sx={{mt: 3, mb: 2}}>Submit</Button>
                                </DialogActions>
                            </Box>
                        </DialogContent>
                    </Dialog>
            </span>
        );
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

export default connect(mapStateToProps, {recallInvoice})(RecallInvoice);