
let server = {
    "test": false,
    "host": "https://ms-api.uparcel.cn",
    "test_host": "http://localhost",
    "post": "8001",
    'ship_url': '/ship_list/',
    'bill_url': '/bill_list/',
    'user_url': '/account_list/',
    'user_list_url': '/getUserList/',
    'dimension_list_url': '/dimension_list/',
    'warehouse_list_url': '/warehouse/list/',
    'add_credit_url': '/add_credit/',
    'credit_record_url': '/credit_record_list/',
    'upload_invoice_url': '/uploadInvoice/',
    'get_label_url': '/uploadGetShip/',
    'async_upload_invoice_url': '/SyncUploadInvoice/',
    'ReStoreBill_url': '/ReStoreBill/',
    'download_get_ship_url': '/DownloadGetShip/',
    'download_get_shipment_url': '/downloadGetShipment/',
    'delete_record_url': '/DeleteRecord/',
    'record_url': '/record_list/',
    'void_ship_url': '/art_void/',
    'replenish_url': '/replenish/',
    'replenish_master_track_url': '/replenish_master_track/',
    'verify_credit_url': '/verify_credit/',
    "surcharge_list": "/surcharge_list/",
    "get_offer_sheet_list": "/get_offer_sheet_list/",
    "update_surcharge_url": "/update_surcharge/",
    'address_audit_url': '/address_audit/',
    'load_services_url':'/load_services_type_list/',
    'get_pod_url': '/exportPod/',
    "batch_query_trace_numbers_list": "/batch_query_trace_numbers/",
}
export default server
