import * as React from 'react';
import {connect} from "react-redux";
import {loadShipment} from "../redux/actions/shipment";

import store from "../redux/store";
import {SHIPMENT_CLOSE_ALERT, SHIPMENT_ORDERS, SHIPMENT_STOP} from "../redux/type";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";
import Box from "@mui/material/Box";
import SearchTable from "../components/searchTable";
import APIToolBar from "../components/APIToolBar";
import Grid from "@mui/material/Grid";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import './styles.scss'
import StyledBox from "../components/StyledBox";
import SetColumns from "../components/SetColumns";
import SetVisibility from "../components/SetVisibility";
import Button from "@mui/material/Button";
import PackageDisplay from "./modal/PackageDisplay";
import APICircularProgress from "../components/APICircularProgress";
import APIAlert from "../components/APIAlert";
import {AxiosDefaultRequest} from "../components/default_request";
import {GridActionsCellItem} from "@mui/x-data-grid";
import DownloadIcon from "@mui/icons-material/Download";
import server from "../server";
import {downloadShipmentFile} from "../redux/actions/modal";
import Tooltip from "@mui/material/Tooltip";
import RateDetails from "./modal/rateDetails";
/*
field
headerName
hide
minWidth
width
description
flex 弹性分配
valueGetter
valueFormatter
getActions
headerClassName
cellClassName
*/

const status_dict = {
    '-1': 'Unknown',
    '1': 'Processing',
    '2': 'Finish',
    '3': 'Ordered',
    '4': 'Error',
    '5': 'UnknownError',
    '6': 'Void'
};
const status_chinese = {
    "-1": "未知", "1": "处理中", "2": "完成", "3": "已出单", "4": "错误", "5": "未知错误", "6": "已取消"
}
const warehouse_options = {};

function service_format(number) {
    let service_dict = {
        "4" : "FEDEX_GROUND",
        "5" : "GROUND_HOME_DELIVERY",
        "1" : "SMART_POST",
        "3" : "DHL_SM_GOUND",
        "7" : "UPS_GFP",
        "6" : "Ground",
        "8" : "Expedited Mail Innovations",
        "11" : "PB_NEWGISTICS",
        "12" : "PB_USPS",
        "29" : "FEDEX_2_DAY OnR_LNR",
        "31" : "FEDEX_2_DAY OnR_NAL",
    }
    return `${service_dict[number.value]}`
}

class Shipment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Visibility: this.setVisibility,
            traceOpen: false,
            warehouseOptions: {},
            address_type_map: {
                "-1": "未验证",
                "0": "商业",
                "1": "住宅",
                "2": "混合",
                "3": "未知",
            },
            rateDetailsOpen: {}
        }
        this.trace = React.createRef();
    }
    toggleRateDetails = (id) => {
        // 切换指定行的展开状态
        this.setState(prevState => ({
            rateDetailsOpen: {
                ...prevState.rateDetailsOpen,
                [id]: !prevState.rateDetailsOpen[id]
            }
        }));
    };
    setRateDetails = (isOpen) => {
        this.setState({rateDetailsOpen: isOpen})
    }
    localStorageKey = this.props.user.username + "_" + 'shipmentColumns';
    localStorageVisibilityKey = this.props.user.username + "_" + 'shipmentVisibility';

    tracking_number_format = (track) => {
        // track.row.status
        if (track.row.status === '2' || track.row.status === '3' || track.row.status === '6') {
            return <PackageDisplay trace={track.value} trace_id={track.id}/>
        }
        return <Button>{track.value}</Button>
    }
    warehouse_format = (params) => {
        return this.state.warehouseOptions[params.value] || '';
    }
    columns: GridColDef[] = [
        {id: 1, field: "id", headerName: "运单ID"},
        {
            id: 2,
            field: "tracking_number",
            headerName: "主追踪号",
            width: 251,
            renderCell: this.tracking_number_format
        },
        // type: 'singleSelect', valueOptions: ['-1', '1', '2', '3', '4', '5', '6']
        {
            id: 3, field: "status", headerName: "运单状态", width: 80, renderCell: (params) => {
                let status = status_dict[params.row.status]
                let chinese_status = status_chinese[params.row.status]
                return (
                    <div className={`cellWithStatus ${status}`}>
                        {chinese_status}
                    </div>
                )
            }
        },
        {id: 21, field: "user", headerName: "用户"},
        {id: 22, field: "UID", headerName: "用户ID", width: 100},
        {id: 4, field: "create_time", headerName: "出单时间", width: 175},
        {id: 5, field: "Logistic_name", headerName: "渠道商", width: 80},
        {id: 6, field: "Warehouse", headerName: "发运仓", valueFormatter: this.warehouse_format},
        {id: 7, field: "Address1", headerName: "地址1", width: 200},
        {id: 33, field: "Address2", headerName: "地址2", width: 200},
        {id: 8, field: "City", headerName: "城市", width: 200},
        {id: 9, field: "PhoneNumber", headerName: "电话", width: 150},
        {id: 10, field: "PostalCode", headerName: "邮编"},
        {id: 11, field: "Country", headerName: "国家"},
        {id: 12, field: "RecipientName", headerName: "收件人"},
        {id: 13, field: "StateOrProvinceCode", headerName: "州/省简称", width: 50},
        {id: 24, field: "ServiceNumber", headerName: "服务名", width: 150, valueFormatter: service_format},
        {id: 14, field: "Reference1", headerName: "客户备注"},
        {id: 15, field: "Rate", headerName: "预扣费"},
        {id: 16, field: "bill_fee", headerName: "对账实收"},
        {
            id: 27,
            field: "total_adj",
            headerName: "总调整",
            valueGetter: (params) => {
                // 确保Rate和bill_fee字段存在并且是数字类型
                const prepaid = params.row.Rate ?? 0;
                const billed = params.row.bill_fee ?? 0;
                return (billed - prepaid).toFixed(2); // 返回计算结果，并保留两位小数
            }
        },
        {id: 17, field: "MultiPackages", headerName: "件数"},
        {id: 35, field: "TotalWeight", headerName: "总重量"},
        {id: 18, field: "bill_status", headerName: "对账状态"},
        {id: 19, field: "all_track", headerName: "所有单号", width: 100},
        {id: 20, field: "path", headerName: "下载路径", width: 100},
        {id: 23, field: "error_msg", headerName: "错误信息", width: 100},
        {id: 25, field: "second_tracking_number", headerName: "USPS单号"},
        {
            id: 26, field: 'actions', type: 'actions', headerName: "操作", getActions: (params) => {
                return [
                    <GridActionsCellItem
                        key={`downloadShip-${params.row.id}`}
                        icon={<Tooltip title={"下载面单"}><DownloadIcon/></Tooltip>}
                        label="Download"
                        onClick={() => {
                            this.props.downloadShipmentFile(params.row.id)
                        }}
                        color="primary"
                        // disabled={false}
                        disabled={params.row.status !== '2' && params.row.status !== '3'}
                    />,
                    <RateDetails
                        key={`rate-details-${params.row.id}`}
                        isOpen={this.state.rateDetailsOpen[params.row.id] || false}
                        setOpen={() => this.toggleRateDetails(params.row.id)}
                        details={params.row.rate_details}
                        disabled={false}
                    />
                ];
            }
        },
        // next 36
    ]
    // 控制是否展示列
    columnVisibilityModel = {
        id: false,
        path: false
    }
    set_columns = SetColumns(this.localStorageKey, this.columns);
    setVisibility = SetVisibility(this.localStorageVisibilityKey, this.columnVisibilityModel);

    ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        // 获取columns
        // console.log(set_columns)
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
        // oldIndex > targetIndex
        // targetIndex < oldIndex
    }

    useQuery = (page) => {
        this.props.loadShipment((page * this.props.pageSize), ((page + 1) * this.props.pageSize), page,
            null, store.getState().shipment.search, null, null)
    }
    changePageSize = (pageSize) => {
        setTimeout(() => {
            this.props.loadShipment((this.props.page * pageSize), ((this.props.page + 1) * pageSize),
                null, pageSize, store.getState().shipment.search, null, null)
        }, 100);
    }

    componentDidMount() {
        // 预加载操作
        this.props.loadShipment(this.props.page, this.props.pageSize, null,
            null, store.getState().shipment.search, null, null);

        AxiosDefaultRequest({
            url: server.warehouse_list_url,
            success: res => {
                for (const i in res.data) {
                    warehouse_options[res.data[i].warehouse_number] = res.data[i].name;
                }
                this.setState({warehouseOptions: warehouse_options});
            }
        });
    }

    componentDidUpdate(prevProps) {
        // search
        if (store.getState().shipment.stop && this.props.search && !this.props.isLoading && this.props.searchClick) {
            store.dispatch({ type: SHIPMENT_STOP });
            this.props.loadShipment(this.props.page, this.props.pageSize, null, null, store.getState().shipment.search, null, null);
        }
        // order
        if (this.props.orders && this.props.orderClick && this.props.orders !== prevProps.orders) {
            store.dispatch({ type: SHIPMENT_STOP });
            this.props.loadShipment(this.props.page, this.props.pageSize, null, null, store.getState().shipment.search, null, this.props.orders);
        }
    }

    ChangeColumnVisibilityModel = (modal) => {
        localStorage.setItem(this.localStorageVisibilityKey, JSON.stringify(modal))
        this.setState({Visibility: modal})
    }
    ChangeColumnSortModel = (modal) => {
        // 触发 UNSAFE_componentWillReceiveProps
        store.dispatch({type: SHIPMENT_ORDERS, payload: modal})
    }
    closeAlert = () => {
        store.dispatch({type: SHIPMENT_CLOSE_ALERT})
    }
    render() {

        let loading;
        let alert;
        if (this.props.isLoading || this.props.ModalLoading) {
            loading = APICircularProgress('success')
        }
        if (this.props.alert && this.props.payload) {
            const {level, title, message} = this.props.payload
            const alert_p = {level: level, title: title, message: message, options: null, closeFunc: this.closeAlert}
            alert = APIAlert(alert_p)
        }
        return (
            <Box sx={{
                width: '100%'
            }}>
                {loading}{alert}
                <Grid container>
                    <Grid container item md={12} lg={12}>
                        <Box sx={{minWidth: '100%'}}>
                            <SearchTable user={this.props.user}/>
                        </Box>
                    </Grid>
                    <Grid item md={12} lg={12}>
                        <StyledBox>
                            {this.props.data ? <DataGridPro
                                rows={this.props.data}
                                density="compact"
                                columns={this.set_columns}
                                pageSize={this.props.pageSize}
                                rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                                onColumnOrderChange={(e) => {
                                    this.ColumnOrderChange(e)
                                }}
                                checkboxSelection={false}
                                headerHeight={70}
                                loading={this.props.TableLoading}
                                rowCount={this.props.rowCount}
                                paginationMode='server'
                                onPageChange={(page) => this.useQuery(page)}
                                onPageSizeChange={pageSize => this.changePageSize(pageSize)}
                                columnVisibilityModel={this.state.Visibility}
                                onColumnVisibilityModelChange={(newModel) =>
                                    this.ChangeColumnVisibilityModel(newModel)
                                }
                                disableMultipleSelection={false}
                                initialState={
                                    {pinnedColumns: {left: ['tracking_number'], right: ['actions']}}
                                }
                                rowReordering={false}

                                pagination={true}

                                sortingMode="server"
                                // sortModel={this.set_sort}
                                onSortModelChange={(newSortModel) => this.ChangeColumnSortModel(newSortModel)}

                                components={{
                                    LoadingOverlay: LinearProgress,
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    Toolbar: APIToolBar,
                                }}
                                columnBuffer={3}
                                columnThreshold={3}/> : ''}
                        </StyledBox>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.shipment.isLoading,
    TableLoading: state.shipment.TableLoading,
    ModalLoading: state.shipment.ModalLoading,
    pageSize: state.shipment.pageSize,
    page: state.shipment.page,
    rowCount: state.shipment.rowCount,
    Refresh: state.indexReducer.Refresh,
    data: state.shipment.data,
    search: state.shipment.search,
    searchClick: state.shipment.searchClick,
    orders: state.shipment.orders,
    orderClick: state.shipment.orderClick,
    alert: state.shipment.alert,
    payload: state.shipment.content,
    user: state.auth.user,
});

export default connect(mapStateToProps, {loadShipment,downloadShipmentFile})(Shipment)