import {ADMIN, LOAD_ADMIN, SET_CURRENT, ADMIN_TABLE_LOADING} from "../type";
import server from "../../server";
import {AxiosDefaultRequest} from "../../components/default_request";
import store from "../store";
import {sort_order} from "../../components/utils";


export const AdminPreLoad = (offset, limit, setPage = null, setPageSize = null, options = null, reload=null, orders=null) => (dispatch, getState) => {
    dispatch({type: ADMIN_TABLE_LOADING})
    let url = server.record_url + "?offset=" + offset + "&limit=" + limit
    if (options) {
        for (let i in options) {
            if (options[i] !== '') {
                url = url + '&' + i + '=' + options[i]
            }
        }
    }
    if (orders){
        url += sort_order(orders);
    }
    AxiosDefaultRequest({
        url: url,
        method: 'GET',
        success: (res) => {
            const result = {data: res.data.rows, rowCount: res.data.total, isLoading: false, display: false};
            if (String(setPage) && setPage !== null) {
                result['page'] = setPage
            } else {
                result['page'] = store.getState().account.page;
            }
            if (String(setPageSize) && setPageSize !== null) {
                result['pageSize'] = setPageSize
            } else {
                result['pageSize'] = store.getState().account.pageSize;
            }
            dispatch({type: SET_CURRENT, payload: {current: ADMIN, searchTable: ADMIN, currentUrl: "/index/admin"}})
            dispatch({type: LOAD_ADMIN, payload: result})
        }
    })
}
