import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";

export default function (localStorageKey, columns) {
    const is_columns = localStorage.getItem(localStorageKey)
    let set_columns: GridColDef[];
    // 1. 判断localStorage 列数据存在
    // 2. 有 则使用 无 则将 columns 写入 localStorage 并使用 columns
    if (!is_columns) {
        localStorage.setItem(localStorageKey, JSON.stringify(columns));
        set_columns = columns;
    } else {
        if (JSON.parse(localStorage.getItem(localStorageKey)).length !== columns.length) {
            localStorage.setItem(localStorageKey, JSON.stringify(columns));
            set_columns = columns;
        } else {
            let json_columns = JSON.parse(is_columns);
            for (let col in json_columns) {
                for (let i in columns) {
                    if (json_columns[col].id === columns[i].id) {
                        json_columns[col] = columns[i]
                        break
                    }
                }
            }
            set_columns = json_columns;
        }
    }
    return set_columns
}