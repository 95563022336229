import * as React from 'react';
import {connect} from "react-redux";
import store from "../redux/store";
import {loadUser, register} from "../redux/actions/auth";
import {IS_USER, OFFER_SHEET_CLOSE_ALERT, REGISTER_CLOSE_ALERT} from "../redux/type";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import {createTheme, ThemeProvider} from '@mui/material/styles';

import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import Copyright from "../components/Copyright";
import APICircularProgress from "../components/APICircularProgress";
import APIAlert from "../components/APIAlert";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

import TextField from "@mui/material/TextField";
import {AxiosDefaultRequest} from "../components/default_request";
import server from "../server";
import {Redirect} from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {FormGroup, FormLabel} from "@mui/material";
import Grid from "@mui/material/Grid";
import '../css/App.css'
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import LinearProgress from "@mui/material/LinearProgress";
import AddOfferSheet from "./modal/AddOfferSheet";
import OfferSheetDisplay from "./modal/OfferSheetDisplayModal";
import {Update_surcharge} from "../redux/actions/OfferSheetAction";

var maps = {
    FEDEX: {
        'AHSDimensions': 'Additional handling surcharge - dimension',
        'AHSWeight': 'Additional handling surcharge - weight',
        'AHSPackaging': 'AHSPackaging',
        'OversizeCharge': 'Peak - Oversize Charge',
        'DeliveryAreaSurcharge': ['Delivery Area Surcharge Commercial', 'FedEx Ground Delivery Area'],
        'ExtendDeliveryAreaSurcharge': 'Delivery Area Surcharge Extended Commercial',
        'DAS': ['', 'Delivery Area Surcharge Hawaii Commercial', 'Delivery Area Surcharge Remote Commercial'],
        'PeakAHSCharge': 'Peak - Additional Handling Surcharge',
        'PeakOversizeCharge': 'Peak - Oversize Charge',
        'AddressCorrection': 'AddressCorrection',
        'DeclaredValue': 'Insured value',
        'DirectSignature': 'Direct signature required',
        'ResidentialDeliveryCharge': ['Residential Surcharge', 'Residential delivery surcharge'],
        'UnauthorizedPackageCharge': 'Unauthorized Package Charge',
        'DangerousGoods': 'DangerousGoods',
        'SMART_POST': {
            'Non-Mach Surcharge': 'SmartPost Non-Machinable',
            'PeakSurcharge': 'Peak Surcharge',
            'DeliveryAndReturns': 'Delivery and Returns',
            'DeliveryAreaSurcharge': ['Delivery Area Surcharge Commercial', 'Delivery Area Surcharge'],
            'ExtendDeliveryAreaSurcharge': 'Delivery Area Surcharge Extended Commercial',
        }
    },
    UPS: {
        'AHSDimensions': 'ADDITIONAL HANDLING',
        'AHSWeight': 'ADDITIONAL HANDLING(Weight)',
        'AHSPackaging': 'AHSPackaging',
        'OversizeCharge': 'LARGE PACKAGE(Residential)',
        'DeliveryAreaSurcharge': ['DELIVERY AREA(Rural Extended)', 'DELIVERY AREA(Rural Extended)'],
        'ExtendDeliveryAreaSurcharge': ['Delivery Area Surcharge Extended Commercial', 'DELIVERY AREA(Rural)'],
        'DAS': ['DELIVERY AREA(Alaska Extended)', 'Delivery Area Surcharge Hawaii Commercial', null],
        'PeakAHSCharge': 'Peak - Additional Handling Surcharge',
        'PeakOversizeCharge': 'Peak - Oversize Charge',
        'AddressCorrection': 'AddressCorrection',
        'DeclaredValue': 'Insured value',
        'DirectSignature': 'Direct signature required',
        'ResidentialDeliveryCharge': ['Residential Surcharge', 'Residential delivery surcharge'],
        'UnauthorizedPackageCharge': 'Unauthorized Package Charge',
        'DangerousGoods': 'DangerousGoods',
        'Non-Mach Surcharge': 'SmartPost Non-Machinable',
        'PeakSurcharge': 'Peak Surcharge',
        'DeliveryAndReturns': 'Delivery and Returns',
    }
}

class OfferSheet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data_list: '',
            password_display: false,
            PFNList: [<MenuItem aria-label="None" key={'None'} name={'None'}>None</MenuItem>],
            logistic_list: [<MenuItem aria-label="None" key={'None'} name={'None'}>None</MenuItem>],
            PFN: '',
            logisticService: '',
            AHSDimensions: [0, 0, 0, 0],
            AHSWeight: [0, 0, 0, 0],
            AHSPackaging: [0, 0, 0, 0],
            OversizeCharge: [0, 0, 0, 0], // z2 z3-4 z5-6 z7-8
            DeliveryAreaSurcharge: [0, 0],
            ExtendDeliveryAreaSurcharge: [0, 0], // 商业， 民居
            DAS: [0, 0, 0], // 阿拉斯加， 夏威夷， 荒远
            PeakAHSCharge: 0,
            PeakOversizeCharge: 0,
            AddressCorrection: 0,
            DeclaredValue: 0,
            DirectSignature: 0,
            ResidentialDeliveryCharge: 0,
            UnauthorizedPackageCharge: 0,
            DangerousGoods: 0,
            NMS: 0,  // SP non-mach-surcharge
            PeakSurcharge: 0,  // SP peak surcharge
            DeliveryAndReturns: 0,  // SP Delivery And Returns
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.Update_surcharge(
            this.state.PFN, this.state.logisticService, maps,
            {
                AHSDimensions: this.state.AHSDimensions,
                AHSWeight: this.state.AHSWeight,
                AHSPackaging: this.state.AHSPackaging,
                OversizeCharge: this.state.OversizeCharge,
                DeliveryAreaSurcharge: this.state.DeliveryAreaSurcharge,
                ExtendDeliveryAreaSurcharge: this.state.ExtendDeliveryAreaSurcharge,
                DAS: this.state.DAS,
                PeakAHSCharge: this.state.PeakAHSCharge,
                PeakOversizeCharge: this.state.PeakOversizeCharge,
                AddressCorrection: this.state.AddressCorrection,
                DeclaredValue: this.state.DeclaredValue,
                DirectSignature: this.state.DirectSignature,
                ResidentialDeliveryCharge: this.state.ResidentialDeliveryCharge,
                UnauthorizedPackageCharge: this.state.UnauthorizedPackageCharge,
                DangerousGoods: this.state.DangerousGoods,
                NMS: this.state.NMS,
                PeakSurcharge: this.state.PeakSurcharge,
                DeliveryAndReturns: this.state.DeliveryAndReturns,
            }
        );
    };
    closeAlert = () => store.dispatch({type: OFFER_SHEET_CLOSE_ALERT})

    GRD_OR_HOME_OR_MWT = (event) => {
        let my_dict = this.state.data_list[this.state.logisticService][event.target.value];
        let AHSDimensions = maps['FEDEX']['AHSDimensions'];
        AHSDimensions = [my_dict[AHSDimensions + "||2"], my_dict[AHSDimensions + "||4"], my_dict[AHSDimensions + "||6"], my_dict[AHSDimensions + "||8"]];
        let AHSWeight = maps['FEDEX']['AHSWeight'];
        AHSWeight = [my_dict[AHSWeight + "||2"], my_dict[AHSWeight + "||4"], my_dict[AHSWeight + "||6"], my_dict[AHSWeight + "||8"]];
        let AHSPackaging = [0, 0, 0, 0];
        let OversizeCharge = maps['FEDEX']['OversizeCharge'];
        OversizeCharge = [my_dict[OversizeCharge + "||2"], my_dict[OversizeCharge + "||4"], my_dict[OversizeCharge + "||6"], my_dict[OversizeCharge + "||8"]];
        let DeliveryAreaSurcharge = maps['FEDEX']['DeliveryAreaSurcharge'][0];
        DeliveryAreaSurcharge = [my_dict[DeliveryAreaSurcharge + "||1"], 0];
        let ExtendDeliveryAreaSurcharge = maps['FEDEX']['ExtendDeliveryAreaSurcharge'];
        ExtendDeliveryAreaSurcharge = [my_dict[ExtendDeliveryAreaSurcharge + "||1"], 0];
        let DAS = [0, my_dict["Delivery Area Surcharge Hawaii Commercial||1"], my_dict["Delivery Area Surcharge Remote Commercial||1"]];
        let PeakAHSCharge = my_dict[maps['FEDEX']['PeakAHSCharge'] + "||1"];
        let PeakOversizeCharge = my_dict[maps['FEDEX']['PeakOversizeCharge'] + "||1"];
        let AddressCorrection = 0;
        let DeclaredValue = my_dict[maps['FEDEX']['DeclaredValue'] + "||1"];
        let DirectSignature = my_dict[maps['FEDEX']['DirectSignature'] + "||1"];
        let ResidentialDeliveryCharge = my_dict[maps['FEDEX']['ResidentialDeliveryCharge'][0] + "||1"];
        let UnauthorizedPackageCharge = 0;
        let DangerousGoods = 0;
        return {
            PFN: event.target.value,
            AHSDimensions: AHSDimensions,
            AHSWeight: AHSWeight,
            AHSPackaging: AHSPackaging,
            OversizeCharge: OversizeCharge,
            DeliveryAreaSurcharge: DeliveryAreaSurcharge,
            ExtendDeliveryAreaSurcharge: ExtendDeliveryAreaSurcharge,
            DAS: DAS,
            PeakAHSCharge: PeakAHSCharge,
            PeakOversizeCharge: PeakOversizeCharge,
            AddressCorrection: AddressCorrection,
            DeclaredValue: DeclaredValue,
            DirectSignature: DirectSignature,
            ResidentialDeliveryCharge: ResidentialDeliveryCharge,
            UnauthorizedPackageCharge: UnauthorizedPackageCharge,
            DangerousGoods: DangerousGoods
        }
    }
    SMART_POST = (event) => {
        let my_dict = this.state.data_list[this.state.logisticService][event.target.value];
        let DeliveryAreaSurcharge = maps['FEDEX']['SMART_POST']['DeliveryAreaSurcharge'][0];
        DeliveryAreaSurcharge = [my_dict[DeliveryAreaSurcharge + "||1"], my_dict[DeliveryAreaSurcharge + "||1"]];
        let ExtendDeliveryAreaSurcharge = maps['FEDEX']['SMART_POST']['ExtendDeliveryAreaSurcharge'];
        ExtendDeliveryAreaSurcharge = [my_dict[ExtendDeliveryAreaSurcharge + "||1"], my_dict[ExtendDeliveryAreaSurcharge + "||1"]];
        return {
            PFN: event.target.value,
            AHSDimensions: [0, 0, 0, 0],
            AHSWeight: [0, 0, 0, 0],
            AHSPackaging: [0, 0, 0, 0],
            OversizeCharge: [0, 0, 0, 0],
            DeliveryAreaSurcharge: DeliveryAreaSurcharge,
            ExtendDeliveryAreaSurcharge: ExtendDeliveryAreaSurcharge,
            DAS: [0, 0, 0],
            PeakAHSCharge: 0,
            PeakOversizeCharge: 0,
            AddressCorrection: 0,
            DeclaredValue: 0,
            DirectSignature: 0,
            ResidentialDeliveryCharge: 0,
            UnauthorizedPackageCharge: 0,
            DangerousGoods: 0,
            NMS: my_dict[maps['FEDEX']['SMART_POST']['Non-Mach Surcharge'] + "||1"],
            PeakSurcharge: my_dict[maps['FEDEX']['SMART_POST']['PeakSurcharge'] + "||1"],
            DeliveryAndReturns: my_dict[maps['FEDEX']['SMART_POST']['DeliveryAndReturns'] + "||1"],
        }
    }

    UPS = (event) => {
        let my_dict = this.state.data_list[this.state.logisticService][event.target.value];
        let AHSDimensions = maps['FEDEX']['AHSDimensions'];
        AHSDimensions = [my_dict[AHSDimensions + "||2"], my_dict[AHSDimensions + "||4"], my_dict[AHSDimensions + "||6"], my_dict[AHSDimensions + "||8"]];
        let AHSWeight = maps['FEDEX']['AHSWeight'];
        AHSWeight = [my_dict[AHSWeight + "||2"], my_dict[AHSWeight + "||4"], my_dict[AHSWeight + "||6"], my_dict[AHSWeight + "||8"]];
        let AHSPackaging = [0, 0, 0, 0];
        let OversizeCharge = maps['FEDEX']['OversizeCharge'];
        OversizeCharge = [my_dict[OversizeCharge + "||2"], my_dict[OversizeCharge + "||4"], my_dict[OversizeCharge + "||6"], my_dict[OversizeCharge + "||8"]];
        let DeliveryAreaSurcharge = maps['FEDEX']['DeliveryAreaSurcharge'][0];
        DeliveryAreaSurcharge = [my_dict[DeliveryAreaSurcharge + "||1"], 0];
        let ExtendDeliveryAreaSurcharge = maps['FEDEX']['ExtendDeliveryAreaSurcharge'];
        ExtendDeliveryAreaSurcharge = [my_dict[ExtendDeliveryAreaSurcharge + "||1"], 0];
        let DAS = [0, my_dict["Delivery Area Surcharge Hawaii Commercial||1"], my_dict["Delivery Area Surcharge Remote Commercial||1"]];
        let PeakAHSCharge = my_dict[maps['FEDEX']['PeakAHSCharge'] + "||1"];
        let PeakOversizeCharge = my_dict[maps['FEDEX']['PeakOversizeCharge'] + "||1"];
        let AddressCorrection = 0;
        let DeclaredValue = my_dict[maps['FEDEX']['DeclaredValue'] + "||1"];
        let DirectSignature = my_dict[maps['FEDEX']['DirectSignature'] + "||1"];
        let ResidentialDeliveryCharge = my_dict[maps['FEDEX']['ResidentialDeliveryCharge'][0] + "||1"];
        let UnauthorizedPackageCharge = 0;
        let DangerousGoods = 0;
        return {
            PFN: event.target.value,
            AHSDimensions: AHSDimensions,
            AHSWeight: AHSWeight,
            AHSPackaging: AHSPackaging,
            OversizeCharge: OversizeCharge,
            DeliveryAreaSurcharge: DeliveryAreaSurcharge,
            ExtendDeliveryAreaSurcharge: ExtendDeliveryAreaSurcharge,
            DAS: DAS,
            PeakAHSCharge: PeakAHSCharge,
            PeakOversizeCharge: PeakOversizeCharge,
            AddressCorrection: AddressCorrection,
            DeclaredValue: DeclaredValue,
            DirectSignature: DirectSignature,
            ResidentialDeliveryCharge: ResidentialDeliveryCharge,
            UnauthorizedPackageCharge: UnauthorizedPackageCharge,
            DangerousGoods: DangerousGoods
        }
    }

    PFNChange = (event) => {
        /*
        AHSDimensions: [0, 0, 0, 0],
        AHSWeight: [0, 0, 0, 0],
        AHSPackaging: [0, 0, 0, 0],
        OversizeCharge: [0, 0, 0, 0], // z2 z3-4 z5-6 z7-8
        DeliveryAreaSurcharge: [0, 0],
        ExtendDeliveryAreaSurcharge: [0, 0], // 商业， 民居
        DAS: [0, 0, 0], // 阿拉斯加， 夏威夷， 荒远
        PeakAHSCharge: 0,
        PeakOversizeCharge: 0,
        AddressCorrection: 0,
        DeclaredValue: 0,
        DirectSignature: 0,
        ResidentialDeliveryCharge: 0,
        UnauthorizedPackageCharge: 0,
        DangerousGoods: 0
        */
        if (event.target.value !== null && event.target.value !== undefined) {
            // FEDEX
            let update_dict;
            if (this.state.logisticService === 'FEDEX_SP_AD') {
                update_dict = this.SMART_POST(event)
            } else if (this.state.logisticService === 'UPS_GROUND_AD') {
                update_dict = this.UPS(event)
            } else {
                update_dict = this.GRD_OR_HOME_OR_MWT(event)
            }
            this.setState(update_dict)
        } else {
            this.setState({
                PFN: '',
                AHSDimensions: [0, 0, 0, 0],
                AHSWeight: [0, 0, 0, 0],
                AHSPackaging: [0, 0, 0, 0],
                OversizeCharge: [0, 0, 0, 0],
                DeliveryAreaSurcharge: [0, 0,],
                ExtendDeliveryAreaSurcharge: [0, 0],
                DAS: [0, 0, 0],
                PeakAHSCharge: 0,
                PeakOversizeCharge: 0,
                AddressCorrection: 0,
                DeclaredValue: 0,
                DirectSignature: 0,
                ResidentialDeliveryCharge: 0,
                UnauthorizedPackageCharge: 0,
                DangerousGoods: 0
            })
        }

    }

    UNSAFE_componentWillMount() {
        AxiosDefaultRequest({
            url: server.surcharge_list,
            success: res => {
                let options = [<MenuItem aria-label="None" key={'None'} name={'None'}>None</MenuItem>];
                for (let i in Object.keys(res.data)) {
                    options.push(<MenuItem aria-label="None" key={i} name={Object.keys(res.data)[i]}
                                           value={Object.keys(res.data)[i]}>{Object.keys(res.data)[i]}</MenuItem>)
                }
                this.setState({logistic_list: options, data_list: res.data})
            }
        })
    }

    render() {
        let alert;
        // if (!this.props.isAuthenticated) {
        //     console.log('isAuthenticated')
        //     // 1. dispatch 判断是否登录
        //     console.log(store.getState().auth.isLoadUser)
        //     if (!store.getState().auth.isLoadUser) {
        //         this.props.loadUser()
        //         store.dispatch({type: IS_USER})
        //     }
        // }
        let loading;
        if (this.props.pageLoading) {
            loading = APICircularProgress('success')
        }
        if (this.props.alert) {
            const {level, title, message, timeout} = this.props.payload
            const alert_p = {
                level: level, title: title, message: message, options: null,
                closeFunc: this.closeAlert, timeout: timeout || 3000
            }
            alert = APIAlert(alert_p)
        }
        return (
            <Box sx={{width: '100%'}}>{alert}
                {/*<Container component="main" maxWidth="xs">*/}
                {/*<CssBaseline/>*/}
                {loading}
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Box component="form" onSubmit={this.handleSubmit} sx={{mt: 5}}>
                        <Grid container>
                            {/* button */}
                            <Grid container item md={12} xs={12} lg={12}>
                                {/* 空白 */}
                                <Grid item md={1.5} xs={1.5} lg={1.5}></Grid>
                                <AddOfferSheet/>
                                <OfferSheetDisplay/>
                            </Grid>
                            {/* menu */}
                            <Grid container item md={12} xs={12} lg={12}>
                                {/* 空白 */}
                                <Grid item md={1.5} xs={1.5} lg={1.5}></Grid>
                                {/* logistic service */}
                                <Grid container item md={1.2} xs={1.2} lg={1.2}>
                                    <FormControl variant="standard" sx={{m: 1, minWidth: 150}}>
                                        <InputLabel id="logisticService">logistic service</InputLabel>
                                        <Select
                                            labelId="logisticService"
                                            id="select_ser"
                                            value={this.state.logisticService}
                                            onChange={(e) => {
                                                let pfn_list = Object.keys(this.state.data_list[e.target.value]);
                                                let options = [<MenuItem aria-label="None" key={'None'} selected
                                                                         name={'None'}>None</MenuItem>];
                                                for (let i in pfn_list) {
                                                    options.push(<MenuItem aria-label="None" key={i}
                                                                           name={pfn_list[i]}
                                                                           value={pfn_list[i]}>{pfn_list[i]}</MenuItem>)
                                                }
                                                this.setState({
                                                    PFNList: options,
                                                    logisticService: e.target.value,
                                                    PFN: ''
                                                })

                                            }}
                                            label="logisticService"
                                        >
                                            {this.state.logistic_list}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {/* PFN */}
                                <Grid container item md={3} xs={3} lg={3}>
                                    <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                        <InputLabel id="PFN">PFN</InputLabel>
                                        <Select
                                            labelId="PFN"
                                            id="select_pfn"
                                            value={this.state.PFN}
                                            onChange={this.PFNChange}
                                            label="PFN"
                                        >
                                            {this.state.PFNList}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {/* content */}
                            <Grid container item md={12} xs={12} lg={12} spacing={1}>
                                {/* 空白 */}
                                <Grid item md={1.5} xs={1.5} lg={1.5}></Grid>
                                {/* 第一列 */}
                                <Grid container item md={3} xs={3} lg={3}>
                                    <Grid container item className="surcharge-formControl"
                                          aria-hidden={true} md={12} xs={12} lg={12}>
                                        <Grid container item md={0.2} xs={0.2} lg={0.2}></Grid>
                                        <Typography htmlFor="AHSDimensions" align="center">AHS - Dimensions</Typography>
                                        <Grid container item md={12} xs={12} lg={12}>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '48%', mb: '1%'}}>
                                                <InputLabel htmlFor="AHSDimensions_z2">zone2</InputLabel>
                                                <OutlinedInput
                                                    id="AHSDimensions_z2" name="AHSDimensions_z2"
                                                    value={this.state.AHSDimensions[0]}
                                                    onChange={(e) => {
                                                        let ad = this.state.AHSDimensions;
                                                        ad[0] = e.target.value
                                                        this.setState({AHSDimensions: ad})
                                                    }}
                                                    type="text"
                                                    label="AHSDimensions_z2"
                                                />
                                            </FormControl>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '48%', ml: '2%', mb: '1%'}}>
                                                <InputLabel htmlFor="AHSDimensions_z34">zone3-4</InputLabel>
                                                <OutlinedInput
                                                    id="AHSDimensions_z34" name="AHSDimensions_z34"
                                                    value={this.state.AHSDimensions[1]}
                                                    onChange={(e) => {
                                                        let ad = this.state.AHSDimensions;
                                                        ad[1] = e.target.value
                                                        this.setState({AHSDimensions: ad})
                                                    }}
                                                    type="text"
                                                    label="AHSDimensions_z34"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid container item md={0.5} xs={0.5} lg={0.5}></Grid>
                                        <Grid container item md={12} xs={12} lg={12}>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '48%'}}>
                                                <InputLabel htmlFor="AHSDimensions_z56">zone5-6</InputLabel>
                                                <OutlinedInput
                                                    id="AHSDimensions_z56" name="AHSDimensions_z56"
                                                    value={this.state.AHSDimensions[2]}
                                                    onChange={(e) => {
                                                        let ad = this.state.AHSDimensions;
                                                        ad[2] = e.target.value
                                                        this.setState({AHSDimensions: ad})
                                                    }}
                                                    type="text"
                                                    label="AHSDimensions_z56"
                                                />
                                            </FormControl>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '48%', ml: '2%'}}>
                                                <InputLabel htmlFor="AHSDimensions_z78">zone7-8</InputLabel>
                                                <OutlinedInput
                                                    id="AHSDimensions_z78" name="AHSDimensions_z78"
                                                    value={this.state.AHSDimensions[3]}
                                                    onChange={(e) => {
                                                        let ad = this.state.AHSDimensions;
                                                        ad[3] = e.target.value
                                                        this.setState({AHSDimensions: ad})
                                                    }}
                                                    type="text"
                                                    label="AHSDimensions_z78"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container item className="surcharge-formControl" md={12} xs={12} lg={12}>
                                        <Grid container item md={0.2} xs={0.2} lg={0.2}></Grid>
                                        <Typography htmlFor="AHSWeight" align="center">AHS - Weight</Typography>
                                        <Grid container item md={12} xs={12} lg={12}>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '48%', mb: '1%'}}>
                                                <InputLabel htmlFor="AHSWeight_z2">zone2</InputLabel>
                                                <OutlinedInput
                                                    id="AHSWeight_z2" name="AHSWeight_z2"
                                                    value={this.state.AHSWeight[0]}
                                                    onChange={(e) => {
                                                        let ad = this.state.AHSWeight;
                                                        ad[0] = e.target.value
                                                        this.setState({AHSWeight: ad})
                                                    }}
                                                    type="text"
                                                    label="AHSWeight_z2"
                                                />
                                            </FormControl>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '48%', ml: '2%', mb: '1%'}}>
                                                <InputLabel htmlFor="AHSWeight_z34">zone3-4</InputLabel>
                                                <OutlinedInput
                                                    id="AHSWeight_z34" name="AHSWeight_z34"
                                                    value={this.state.AHSWeight[1]}
                                                    onChange={(e) => {
                                                        let ad = this.state.AHSWeight;
                                                        ad[1] = e.target.value
                                                        this.setState({AHSWeight: ad})
                                                    }}
                                                    type="text"
                                                    label="AHSWeight_z34"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid container item md={0.5} xs={0.5} lg={0.5}></Grid>
                                        <Grid container item md={12} xs={12} lg={12}>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '48%'}}>
                                                <InputLabel htmlFor="AHSWeight_z56">zone5-6</InputLabel>
                                                <OutlinedInput
                                                    id="AHSWeight_z56" name="AHSWeight_z56"
                                                    value={this.state.AHSWeight[2]}
                                                    onChange={(e) => {
                                                        let ad = this.state.AHSWeight;
                                                        ad[2] = e.target.value
                                                        this.setState({AHSWeight: ad})
                                                    }}
                                                    type="text"
                                                    label="AHSWeight_z56"
                                                />
                                            </FormControl>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '48%', ml: '2%'}}>
                                                <InputLabel htmlFor="AHSWeight_z78">zone7-8</InputLabel>
                                                <OutlinedInput
                                                    id="AHSWeight_z78" name="AHSWeight_z78"
                                                    value={this.state.AHSWeight[3]}
                                                    onChange={(e) => {
                                                        let ad = this.state.AHSWeight;
                                                        ad[3] = e.target.value
                                                        this.setState({AHSWeight: ad})
                                                    }}
                                                    type="text"
                                                    label="AHSWeight_z78"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container item className="surcharge-formControl" md={12} xs={12} lg={12}>
                                        <Grid container item md={0.2} xs={0.2} lg={0.2}></Grid>
                                        <Typography htmlFor="AHSPackaging" align="center">AHS - Packaging</Typography>
                                        <Grid container item md={12} xs={12} lg={12}>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '48%', mb: '1%'}}>
                                                <InputLabel htmlFor="AHSPackaging_z2">zone2</InputLabel>
                                                <OutlinedInput
                                                    id="AHSPackaging_z2" name="AHSPackaging_z2"
                                                    value={this.state.AHSPackaging[0]}
                                                    onChange={(e) => {
                                                        let ad = this.state.AHSPackaging;
                                                        ad[0] = e.target.value
                                                        this.setState({AHSPackaging: ad})
                                                    }}
                                                    type="text"
                                                    label="AHSPackaging_z2"
                                                />
                                            </FormControl>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '48%', ml: '2%', mb: '1%'}}>
                                                <InputLabel htmlFor="AHSPackaging_z34">zone3-4</InputLabel>
                                                <OutlinedInput
                                                    id="AHSPackaging_z34" name="AHSPackaging_z34"
                                                    value={this.state.AHSPackaging[1]}
                                                    onChange={(e) => {
                                                        let ad = this.state.AHSPackaging;
                                                        ad[1] = e.target.value
                                                        this.setState({AHSPackaging: ad})
                                                    }}
                                                    type="text"
                                                    label="AHSPackaging_z34"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid container item md={0.5} xs={0.5} lg={0.5}></Grid>
                                        <Grid container item md={12} xs={12} lg={12}>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '48%'}}>
                                                <InputLabel htmlFor="AHSPackaging_z56">zone5-6</InputLabel>
                                                <OutlinedInput
                                                    id="AHSPackaging_z56" name="AHSPackaging_z56"
                                                    value={this.state.AHSPackaging[2]}
                                                    onChange={(e) => {
                                                        let ad = this.state.AHSPackaging;
                                                        ad[2] = e.target.value
                                                        this.setState({AHSPackaging: ad})
                                                    }}
                                                    type="text"
                                                    label="AHSPackaging_z56"
                                                />
                                            </FormControl>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '48%', ml: '2%'}}>
                                                <InputLabel htmlFor="AHSPackaging_z78">zone7-8</InputLabel>
                                                <OutlinedInput
                                                    id="AHSPackaging_z78" name="AHSPackaging_z78"
                                                    value={this.state.AHSPackaging[3]}
                                                    onChange={(e) => {
                                                        let ad = this.state.AHSPackaging;
                                                        ad[3] = e.target.value
                                                        this.setState({AHSPackaging: ad})
                                                    }}
                                                    type="text"
                                                    label="AHSPackaging_z78"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container item className="surcharge-formControl" md={12} xs={12} lg={12}>
                                        <Grid container item md={0.2} xs={0.2} lg={0.2}></Grid>
                                        <Typography htmlFor="OversizeCharge" align="center">Oversize Charge</Typography>
                                        <Grid container item md={12} xs={12} lg={12}>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '48%', mb: '1%'}}>
                                                <InputLabel htmlFor="OversizeCharge_z2">zone2</InputLabel>
                                                <OutlinedInput
                                                    id="OversizeCharge_z2" name="OversizeCharge_z2"
                                                    value={this.state.OversizeCharge[0]}
                                                    onChange={(e) => {
                                                        let ad = this.state.OversizeCharge;
                                                        ad[0] = e.target.value
                                                        this.setState({OversizeCharge: ad})
                                                    }}
                                                    type="text"
                                                    label="OversizeCharge_z2"
                                                />
                                            </FormControl>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '48%', ml: '2%', mb: '1%'}}>
                                                <InputLabel htmlFor="OversizeCharge_z34">zone3-4</InputLabel>
                                                <OutlinedInput
                                                    id="OversizeCharge_z34" name="OversizeCharge_z34"
                                                    value={this.state.OversizeCharge[1]}
                                                    onChange={(e) => {
                                                        let ad = this.state.OversizeCharge;
                                                        ad[1] = e.target.value
                                                        this.setState({OversizeCharge: ad})
                                                    }}
                                                    type="text"
                                                    label="OversizeCharge_z34"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid container item md={0.5} xs={0.5} lg={0.5}></Grid>
                                        <Grid container item md={12} xs={12} lg={12}>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '48%'}}>
                                                <InputLabel htmlFor="OversizeCharge_z56">zone5-6</InputLabel>
                                                <OutlinedInput
                                                    id="OversizeCharge_z56" name="OversizeCharge_z56"
                                                    value={this.state.OversizeCharge[2]}
                                                    onChange={(e) => {
                                                        let ad = this.state.OversizeCharge;
                                                        ad[2] = e.target.value
                                                        this.setState({OversizeCharge: ad})
                                                    }}
                                                    type="text"
                                                    label="OversizeCharge_z56"
                                                />
                                            </FormControl>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '48%', ml: '2%'}}>
                                                <InputLabel htmlFor="OversizeCharge_z78">zone7-8</InputLabel>
                                                <OutlinedInput
                                                    id="OversizeCharge_z78" name="OversizeCharge_z78"
                                                    value={this.state.OversizeCharge[3]}
                                                    onChange={(e) => {
                                                        let ad = this.state.OversizeCharge;
                                                        ad[3] = e.target.value
                                                        this.setState({OversizeCharge: ad})
                                                    }}
                                                    type="text"
                                                    label="OversizeCharge_z78"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* 第二列 */}
                                <Grid container item md={3} xs={3} lg={3}>
                                    <Typography htmlFor="DeliveryAreaSurcharge">Delivery Area Surcharge偏远地区</Typography>
                                    <Grid container item md={12} xs={12} lg={12} spacing={1}>
                                        <Grid container item md={6} xs={6} lg={6}>
                                            <FormControl className="surcharge-formControl" variant="outlined">
                                                <InputLabel htmlFor="DeliveryAreaSurcharge_C">商业地址</InputLabel>
                                                <OutlinedInput
                                                    id="DeliveryAreaSurcharge_C" name="DeliveryAreaSurcharge_C"
                                                    value={this.state.DeliveryAreaSurcharge[0]}
                                                    onChange={(e) => {
                                                        let ad = this.state.DeliveryAreaSurcharge;
                                                        ad[0] = e.target.value
                                                        this.setState({DeliveryAreaSurcharge: ad})
                                                    }}
                                                    type="text"
                                                    label="DeliveryAreaSurcharge_C"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid container item md={6} xs={6} lg={6}>
                                            <FormControl className="surcharge-formControl" variant="outlined">
                                                <InputLabel htmlFor="DeliveryAreaSurcharge_R">民居地址</InputLabel>
                                                <OutlinedInput
                                                    id="DeliveryAreaSurcharge_R" name="DeliveryAreaSurcharge_R"
                                                    value={this.state.DeliveryAreaSurcharge[1]}
                                                    onChange={(e) => {
                                                        let ad = this.state.DeliveryAreaSurcharge;
                                                        ad[1] = e.target.value
                                                        this.setState({DeliveryAreaSurcharge: ad})
                                                    }}
                                                    type="text"
                                                    label="DeliveryAreaSurcharge_R"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Typography htmlFor="ExtendDeliveryAreaSurcharge">Extend Delivery Area
                                        Surcharge超偏远地区</Typography>
                                    <Grid container item md={12} xs={12} lg={12} spacing={1}>
                                        <Grid container item md={6} xs={6} lg={6}>
                                            <FormControl className="surcharge-formControl" variant="outlined">
                                                <InputLabel htmlFor="ExtendDeliveryAreaSurcharge_C">商业地址</InputLabel>
                                                <OutlinedInput
                                                    id="ExtendDeliveryAreaSurcharge_C"
                                                    name="ExtendDeliveryAreaSurcharge_C"
                                                    value={this.state.ExtendDeliveryAreaSurcharge[0]}
                                                    onChange={(e) => {
                                                        let ad = this.state.ExtendDeliveryAreaSurcharge;
                                                        ad[0] = e.target.value
                                                        this.setState({ExtendDeliveryAreaSurcharge: ad})
                                                    }}
                                                    type="text"
                                                    label="ExtendDeliveryAreaSurcharge_C"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid container item md={6} xs={6} lg={6}>
                                            <FormControl className="surcharge-formControl" variant="outlined">
                                                <InputLabel htmlFor="ExtendDeliveryAreaSurcharge_R">民居地址</InputLabel>
                                                <OutlinedInput
                                                    id="ExtendDeliveryAreaSurcharge_R"
                                                    name="ExtendDeliveryAreaSurcharge_R"
                                                    value={this.state.ExtendDeliveryAreaSurcharge[1]}
                                                    onChange={(e) => {
                                                        let ad = this.state.ExtendDeliveryAreaSurcharge;
                                                        ad[1] = e.target.value
                                                        this.setState({ExtendDeliveryAreaSurcharge: ad})
                                                    }}
                                                    type="text"
                                                    label="ExtendDeliveryAreaSurcharge_R"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container item className="surcharge-formControl" md={12} xs={12} lg={12}>
                                        <Grid container item md={0.2} xs={0.2} lg={0.2}></Grid>
                                        <Typography htmlFor="uid" align="center">DAS
                                            Alaska/Hawaii阿拉斯加以及夏威夷偏远地区</Typography>
                                        <Grid container item md={12} xs={12} lg={12}>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '32%', mb: '1%'}}>
                                                <InputLabel htmlFor="DAS_Alaska">阿拉斯加</InputLabel>
                                                <OutlinedInput
                                                    id="DAS_Alaska" name="DAS_Alaska"
                                                    value={this.state.DAS[0]}
                                                    onChange={(e) => {
                                                        let ad = this.state.DAS;
                                                        ad[0] = e.target.value
                                                        this.setState({DAS: ad})
                                                    }}
                                                    type="text"
                                                    label="DAS_Alaska"
                                                />
                                            </FormControl>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '32%', ml: '2%', mb: '1%'}}>
                                                <InputLabel htmlFor="DAS_Hawaii">夏威夷</InputLabel>
                                                <OutlinedInput
                                                    id="DAS_Hawaii" name="DAS_Hawaii"
                                                    value={this.state.DAS[1]}
                                                    onChange={(e) => {
                                                        let ad = this.state.DAS;
                                                        ad[1] = e.target.value
                                                        this.setState({DAS: ad})
                                                    }}
                                                    type="text"
                                                    label="DAS_Hawaii"
                                                />
                                            </FormControl>
                                            <FormControl
                                                disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                                variant="outlined" sx={{width: '32%', ml: '2%', mb: '1%'}}>
                                                <InputLabel htmlFor="DAS_Remote">荒远</InputLabel>
                                                <OutlinedInput
                                                    id="DAS_Remote" name="DAS_Remote"
                                                    value={this.state.DAS[2]}
                                                    onChange={(e) => {
                                                        let ad = this.state.DAS;
                                                        ad[2] = e.target.value
                                                        this.setState({DAS: ad})
                                                    }}
                                                    type="text"
                                                    label="DAS_Remote"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid container item md={0.5} xs={0.5} lg={0.5}></Grid>
                                    </Grid>
                                    <FormControl
                                        disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                        className="surcharge-formControl" variant="outlined">
                                        <InputLabel htmlFor="PeakAHSCharge">Peak - AHS Charge</InputLabel>
                                        <OutlinedInput
                                            id="PeakAHSCharge" name="PeakAHSCharge"
                                            value={this.state.PeakAHSCharge}
                                            onChange={(e) => {
                                                this.setState({PeakAHSCharge: e.target.value})
                                            }}
                                            type="text"
                                            label="PeakAHSCharge"
                                        />
                                    </FormControl>
                                    <FormControl
                                        disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                        className="surcharge-formControl" variant="outlined">
                                        <InputLabel htmlFor="PeakOversizeCharge">Peak - Oversize Charge</InputLabel>
                                        <OutlinedInput
                                            id="PeakOversizeCharge" name="PeakOversizeCharge"
                                            value={this.state.PeakOversizeCharge}
                                            onChange={(e) => {
                                                this.setState({PeakOversizeCharge: e.target.value})
                                            }}
                                            type="text"
                                            label="PeakOversizeCharge"
                                        />
                                    </FormControl>
                                    <FormControl
                                        disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                        className="surcharge-formControl" variant="outlined">
                                        <InputLabel htmlFor="AddressCorrection">Address Correction</InputLabel>
                                        <OutlinedInput
                                            id="AddressCorrection" name="AddressCorrection"
                                            value={this.state.AddressCorrection}
                                            onChange={(e) => {
                                                this.setState({AddressCorrection: e.target.value})
                                            }}
                                            type="text"
                                            label="AddressCorrection"
                                        />
                                    </FormControl>
                                    <FormControl
                                        disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                        className="surcharge-formControl" variant="outlined">
                                        <InputLabel htmlFor="DeclaredValue">Declared Value</InputLabel>
                                        <OutlinedInput
                                            id="DeclaredValue" name="DeclaredValue"
                                            value={this.state.DeclaredValue}
                                            onChange={(e) => {
                                                this.setState({DeclaredValue: e.target.value})
                                            }}
                                            type="text"
                                            label="DeclaredValue"
                                        />
                                    </FormControl>
                                    <FormControl
                                        disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                        className="surcharge-formControl" variant="outlined">
                                        <InputLabel htmlFor="DirectSignature">Direct Signature</InputLabel>
                                        <OutlinedInput
                                            id="DirectSignature" name="DirectSignature"
                                            value={this.state.DirectSignature}
                                            onChange={(e) => {
                                                this.setState({DirectSignature: e.target.value})
                                            }}
                                            type="text"
                                            label="DirectSignature"
                                        />
                                    </FormControl>
                                </Grid>
                                {/* 第三列 */}
                                <Grid container item md={3} xs={3} lg={3}>
                                    <FormControl
                                        disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                        className="surcharge-formControl" variant="outlined">
                                        <InputLabel htmlFor="ResidentialDeliveryCharge">Residential Delivery
                                            charge(住宅地址附加费)</InputLabel>
                                        <OutlinedInput
                                            id="ResidentialDeliveryCharge" name="ResidentialDeliveryCharge"
                                            value={this.state.ResidentialDeliveryCharge}
                                            onChange={(e) => {
                                                this.setState({ResidentialDeliveryCharge: e.target.value})
                                            }}
                                            type="text"
                                            label="ResidentialDeliveryCharge"
                                        />
                                    </FormControl>
                                    <FormControl
                                        disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                        className="surcharge-formControl" variant="outlined">
                                        <InputLabel htmlFor="UnauthorizedPackageCharge">Unauthorized Package
                                            Charge</InputLabel>
                                        <OutlinedInput
                                            id="UnauthorizedPackageCharge" name="UnauthorizedPackageCharge"
                                            value={this.state.UnauthorizedPackageCharge}
                                            onChange={(e) => {
                                                this.setState({UnauthorizedPackageCharge: e.target.value})
                                            }}
                                            type="text"
                                            label="UnauthorizedPackageCharge"
                                        />
                                    </FormControl>
                                    <FormControl
                                        disabled={this.state.logisticService === "FEDEX_SP_AD"}
                                        className="surcharge-formControl" variant="outlined">
                                        <InputLabel htmlFor="DangerousGoods">危险品</InputLabel>
                                        <OutlinedInput
                                            id="DangerousGoods" name="DangerousGoods"
                                            value={this.state.DangerousGoods}
                                            onChange={(e) => {
                                                this.setState({DangerousGoods: e.target.value})
                                            }}
                                            type="text"
                                            label="DangerousGoods"
                                        />
                                    </FormControl>
                                    <FormControl
                                        disabled={this.state.logisticService !== "FEDEX_SP_AD"}
                                        className="surcharge-formControl" variant="outlined">
                                        <InputLabel htmlFor="NMS">Non-Mach Surcharge</InputLabel>
                                        <OutlinedInput
                                            id="NMS" name="NMS"
                                            value={this.state.NMS}
                                            onChange={(e) => {
                                                this.setState({NMS: e.target.value})
                                            }}
                                            type="text"
                                            label="Non-Mach Surcharge"
                                        />
                                    </FormControl>
                                    <FormControl
                                        disabled={this.state.logisticService !== "FEDEX_SP_AD"}
                                        className="surcharge-formControl" variant="outlined">
                                        <InputLabel htmlFor="PeakSurcharge">Peak Surcharge</InputLabel>
                                        <OutlinedInput
                                            id="PeakSurcharge" name="PeakSurcharge"
                                            value={this.state.PeakSurcharge}
                                            onChange={(e) => {
                                                this.setState({PeakSurcharge: e.target.value})
                                            }}
                                            type="text"
                                            label="PeakSurcharge"
                                        />
                                    </FormControl>
                                    <FormControl
                                        disabled={this.state.logisticService !== "FEDEX_SP_AD"}
                                        className="surcharge-formControl" id="DeliveryAndReturnsF">
                                        <InputLabel htmlFor="DeliveryAndReturns">Delivery And Returns</InputLabel>
                                        <OutlinedInput
                                            id="DeliveryAndReturns" name="DeliveryAndReturns"
                                            value={this.state.DeliveryAndReturns}
                                            onChange={(e) => {
                                                this.setState({DeliveryAndReturns: e.target.value})
                                            }}
                                            type="text"
                                            label="Delivery And Returns"
                                        />
                                    </FormControl>
                                    {/* 以下占位美化用 */}
                                    <FormControl className="surcharge-formControl" disabled variant="outlined">
                                        <InputLabel htmlFor="危险品3">占位-美化</InputLabel>
                                        <OutlinedInput
                                            id="危险品3" name="危险品3"
                                            value={this.state.UserId} readOnly={true}
                                            onChange={(e) => {
                                                this.setState({UserId: e.target.value})
                                            }}
                                            type="text"
                                            label="占位-美化"
                                        />
                                    </FormControl>
                                    <FormControl className="surcharge-formControl" disabled variant="outlined">
                                        <InputLabel htmlFor="危险品4">占位-美化</InputLabel>
                                        <OutlinedInput
                                            id="危险品4" name="危险品4"
                                            value={this.state.UserId} readOnly={true}
                                            onChange={(e) => {
                                                this.setState({UserId: e.target.value})
                                            }}
                                            type="text"
                                            label="占位-美化"
                                        />
                                    </FormControl>
                                    <FormControl className="surcharge-formControl" disabled variant="outlined">
                                        <InputLabel htmlFor="危险品5">占位-美化</InputLabel>
                                        <OutlinedInput
                                            id="危险品5" name="危险品5"
                                            value={this.state.UserId} readOnly={true}
                                            onChange={(e) => {
                                                this.setState({UserId: e.target.value})
                                            }}
                                            type="text"
                                            label="占位-美化"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {/* submit */}
                            <Grid container>
                                <Grid container item md={5} xs={5} lg={5}></Grid>
                                <Grid container item md={2} xs={2} lg={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{mt: 3, mb: 2}}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                                <Grid container item md={5} xs={5} lg={5}></Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{mt: 8, mb: 4}}/>
                {/*</Container>*/}

            </Box>)
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    // isLoading: state.logInReducer.isLoading,
    pageLoading: state.OfferSheetReducer.pageLoading,
    alert: state.OfferSheetReducer.alert,
    payload: state.OfferSheetReducer.content,
    // isAuthenticated: state.auth.isAuthenticated,
    // user: state.auth.user,
});

export default connect(mapStateToProps, {Update_surcharge})(OfferSheet);
