import {AxiosDefaultRequest} from "../../components/default_request";
import server from "../../server";
import {
    OFFER_SHEET_MODAL_LOADING,
    UPLOAD_INVOICE_ERR,
    UPLOAD_INVOICE_SUCCESS,
    UPDATE_SURCHARGE_SUCCESS,
    UPDATE_SURCHARGE_ERR, OFFER_SHEET_LOADING
} from "../type";
import store from "../store";
import {sort_order} from "../../components/utils";


export const Update_surcharge = (pfn, service, service_maps, data) => (dispatch, getState) => {
    dispatch({type: OFFER_SHEET_LOADING})

    if (!pfn || !service) {
        let result = {request: false, level: 'error', title: 'FAILED', 'message': '请选择后提交'}
        dispatch({type: UPDATE_SURCHARGE_ERR, payload: result})
        return
    }

    // 1. 打包json 发送api
    // 2. 存储json字符串 发送微服务
    // 3. 微服务更新数据库
    AxiosDefaultRequest({
        url: server.update_surcharge_url,
        method: 'POST',
        data: {
            'pfn': pfn,
            'service': service,
            'service_maps': service_maps,
            'data': data,
        }, success: res => {
            let result = res.data;
            if (result.status) {
                result['request'] = true
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                dispatch({type: UPDATE_SURCHARGE_SUCCESS, payload: result})

            } else {
                result['request'] = false
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: UPDATE_SURCHARGE_ERR, payload: result})
            }

        }, err: res => {
            let result = {
                'request': false,
                'level': 'error',
                'title': 'FAILED',
                'message': 'Failed to upload the bill, please check the data for errors and resubmit!'
            }
            dispatch({type: UPDATE_SURCHARGE_ERR, payload: result})
        }
    })
}


export const AddPFN = (file_data, file_name) => (dispatch, getState) => {
    dispatch({type: OFFER_SHEET_MODAL_LOADING})
    AxiosDefaultRequest({
        url: server.async_upload_invoice_url,
        method: 'POST',
        data: {
            'invoice_file': file_data,
            'file_name': file_name,
        }, success: res => {
            let result = res.data;
            if (result.status) {
                result['request'] = true
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                dispatch({type: UPLOAD_INVOICE_SUCCESS, payload: result})

            } else {
                result['request'] = false
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: UPLOAD_INVOICE_ERR, payload: result})
            }

        }, err: res => {
            let result = {
                'request': false,
                'level': 'error',
                'title': 'FAILED',
                'message': 'Failed to upload the bill, please check the data for errors and resubmit!'
            }
            dispatch({type: UPLOAD_INVOICE_ERR, payload: result})
        }
    })
}


export const FEDEX_GRD_AD_ACTION = (DATA) => (dispatch, getState) => {

}


export const FEDEX_HOME_ACTION = (DATA) => (dispatch, getState) => {

}


export const FEDEX_MWT_ACTION = (DATA) => (dispatch, getState) => {

}


export const FEDEX_SMART_ACTION = (DATA) => (dispatch, getState) => {

}


export const UPS_ACTION = (DATA) => (dispatch, getState) => {

}