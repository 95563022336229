import {LOAD_ACCOUNT, ACCOUNT_LOADING, ACCOUNT_STOP, ACCOUNT_SEARCH, ACCOUNT_SEARCH_UPDATE, ACCOUNT_ORDERS} from "../type";
import {GridRowsProp} from "@mui/x-data-grid/models/gridRows";

const initialState = {
    isLoading: true,
    pageSize: 30,
    page: 0,
    rowCount: 0,
    display: true,
    data: [],
    search: null,
    searchClick: false,
    stop: true,
    orders: [],
    orderClick: false
};

export default function (preState=initialState, action) {
    const {type, payload} = action
    switch (type) {
        case LOAD_ACCOUNT:
            const rows : GridRowsProp = payload.data
            return {
                ...preState,
                data: rows,
                rowCount: payload.rowCount,
                isLoading: false,
                page: payload.page,
                pageSize: payload.pageSize
            }
        case ACCOUNT_LOADING:
            return {
                ...preState,
                isLoading: true,
                data: []
            }
        case ACCOUNT_STOP:
            return {
                ...preState,
                stop: false,
                searchClick: false,
                orderClick: false
            }
        case ACCOUNT_ORDERS:
            return {
                ...preState,
                orderClick: true,
                orders: payload
            }
        case ACCOUNT_SEARCH:
            return {
                ...preState,
                data: [],
                page: 0,
                pageSize: 30,
                stop: true,
                searchClick: true
            }
        case ACCOUNT_SEARCH_UPDATE:
            let search;
            if (!preState.search) {
                search = payload;
            } else {
                search = preState.search;
                for (let i in payload){
                    search[i] = payload[i]
                }
            }
            return {
                ...preState,
                search: search,
                searchClick: false
            }
        default:
            return preState
    }
}