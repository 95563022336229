import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import React from "react";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import {Link} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export function renderButtonField({idAname, variant, text, buttonType, tip, onClickHandler, ...otherProps}) {
    return (
        <Grid item lg={1.5} md={1.5} xs={1.5}>
            <FormControl variant="standard" style={{width: '80%'}}>
                <Tooltip title={tip}>
                    <Button
                        type={buttonType}
                        // fullWidth
                        id={idAname}
                        variant={variant}
                        onClick={onClickHandler}
                        sx={{textTransform: 'none'}}
                        {...otherProps}
                    >
                        {text}
                    </Button>
                </Tooltip>
            </FormControl>
        </Grid>
    )
}

export function RenderTextField({idAname, label, value, onChangeHandler, ...otherProps}) {
    return (
        <Grid item lg={3} md={3} xs={3}>
            <FormControl variant="standard" style={{width: '80%'}}>
                <TextField
                    type='text'
                    id={idAname}
                    name={idAname}
                    label={label}
                    variant="standard"
                    value={value}
                    onChange={onChangeHandler}
                    {...otherProps}
                />
            </FormControl>
        </Grid>
    )
}

export function renderSelectField({idAname, label, value, onChangeHandler, select_options, ...otherProps}) {
    return (
        <Grid item lg={3} md={3} xs={3}>
            <FormControl variant="standard" style={{width: '80%'}}>
                <TextField
                    select
                    type='text'
                    id={idAname}
                    name={idAname}
                    label={label}
                    variant="standard"
                    value={value}
                    onChange={onChangeHandler}
                    {...otherProps}
                >{select_options}</TextField>
            </FormControl>
        </Grid>
    )
}

export function renderDesktopDatePickerField({idAname, label, value, onChangeHandler, ...otherProps}) {
    return (
        <Grid item lg={5} md={5} xs={5}>
            <FormControl variant="standard">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label={label}
                        inputFormat="MM/dd/yyyy"
                        endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                        value={value}
                        onChange={onChangeHandler}
                        {...otherProps}
                        renderInput={(params) => <TextField
                            id={idAname} name={idAname}
                            size="small" {...params} />}
                    />
                </LocalizationProvider>
            </FormControl>
        </Grid>
    );
}


export function renderCloseField({onClickHandler}) {
    return (
        <Grid item lg={3} md={3} xs={3}>
            <FormControl variant="standard">
                <Link
                    // fullWidth
                    underline='none'
                    sx={{textTransform: 'none',}}
                >
                    <Button onClick={onClickHandler}>
                        收起搜索栏
                        <KeyboardArrowUpIcon/>
                    </Button>
                </Link>
            </FormControl>
        </Grid>
    )
}