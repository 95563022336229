import * as React from 'react';
import {connect} from "react-redux";
import {loadBill} from "../redux/actions/BillAction";
import store from "../redux/store";
import {BILL_CLOSE_ALERT, BILL_ORDERS, BILL_STOP} from "../redux/type";

import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import SearchTable from "../components/searchTable";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";
import APIToolBar from "../components/APIToolBar";
import Box from "@mui/material/Box";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import StyledBox from "../components/StyledBox";
import SetColumns from "../components/SetColumns";
import SetVisibility from "../components/SetVisibility";
import APIAlert from "../components/APIAlert";

function check_time_format(val) {
    return val.value === '2000-01-01 00:00:00' ? '' : val.value
}
function package_amount_format(val) {
    return val.row.Package_Amount + '/' + val.row.MultiPackages
}
function bill_type_format(number) {
    // 2 bill,3 admin,4 void,5 shipment,6 restore, 7 adjust,8 reset bill
    let bill_type_dict = {
        "2": "账单", "3": "充值", "4": "取消", "5": "出单", "6": "重置", "7": "调整", "8": "重置账单"
    }
    return `${bill_type_dict[number.value]}`
}
class Bill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Visibility: this.setVisibility
        }
    }

    localStorageKey = this.props.user.username + "_" + 'BillColumns';
    localStorageVisibilityKey = this.props.user.username + "_" + 'BillVisibility';
    columns: GridColDef[] = [
        {id: 1, field: "id", headerName: "id", flex: 1},
        {id: 2, field: "create_time", headerName: "create_time", flex: 1.2},
        {id: 3, field: "tracking_number", headerName: "tracking_number", flex: 1.1},
        {id: 4, field: "last_modify_time", headerName: "last_modify_time", flex: 1.1},
        {id: 5, field: "user", headerName: "user", flex: 0.5},
        {id: 14, field: "UID", headerName: "UID", flex: 0.4},
        {id: 6, field: "description", headerName: "desc", flex: 0.4},
        {id: 7, field: "bill_datetime", headerName: "check time", flex: 1, valueFormatter: check_time_format},
        {id: 8, field: "bill_type", headerName: "bill type", flex: 0.4, valueFormatter: bill_type_format},
        {id: 9, field: "change_credit", headerName: "change credit", flex: 0.8},
        {id: 10, field: "invoice", headerName: "invoice", flex: 0.8},
        {id: 11, field: "creditRecord", headerName: "credit", flex: 0.6},
        {id: 12, field: "oldcreditRecord", headerName: "old credit", flex: 0.7},
        {id: 13, field: "Reference1", headerName: "Reference", flex: 1.2},
        {id: 15, field: "Package_Amount", headerName: "Package Amount", flex: 1.2, renderCell: package_amount_format},
        // {id: 8, field: "bill_status", headerName: "bill_status"},
    ]
    // 控制是否展示列
    columnVisibilityModel = {
        id: false
    }
    set_columns = SetColumns(this.localStorageKey, this.columns);
    setVisibility = SetVisibility(this.localStorageVisibilityKey, this.columnVisibilityModel);


    ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        // 获取columns
        // console.log(set_columns)
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
        // oldIndex > targetIndex
        // targetIndex < oldIndex
    }
    useQuery = (page) => {
        this.props.loadBill((page * this.props.pageSize), ((page + 1) * this.props.pageSize), page,
            null, this.props.search, null, null)
    }
    changePageSize = (pageSize) => {
        setTimeout(() => {
            this.props.loadBill((this.props.page * pageSize), ((this.props.page + 1) * pageSize),
                null, pageSize, this.props.search, null, null)
        }, 100);
    }

    UNSAFE_componentWillMount() { // 预加载
        this.props.loadBill(this.props.page, this.props.pageSize, null,
            null, this.props.search, null, null);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (store.getState().BillReducer.stop && nextProps.search && !this.props.isLoading && nextProps.searchClick) {  // search
            store.dispatch({type: BILL_STOP})  // 没这个会跑两次
            this.props.loadBill(this.props.page, this.props.pageSize, null,
                null, store.getState().BillReducer.search, null, nextProps.orders);
        }
        // order
        if (nextProps.orders && nextProps.orderClick) {
            store.dispatch({type: BILL_STOP})
            this.props.loadBill(this.props.page, this.props.pageSize, null,
                null, store.getState().BillReducer.search, null, nextProps.orders);
        }
    }
    ChangeColumnVisibilityModel = (modal) => {
        localStorage.setItem(this.localStorageVisibilityKey, JSON.stringify(modal))
        this.setState({Visibility: modal})
    }
    ChangeColumnSortModel = (modal) => {
        // 触发 UNSAFE_componentWillReceiveProps
        store.dispatch({type: BILL_ORDERS, payload: modal})
    }

        closeAlert = () => {
        store.dispatch({type: BILL_CLOSE_ALERT})
    }

    render() {
        let alert;
        if (this.props.alert && this.props.payload) {
            const {level, title, message} = this.props.payload
            const alert_p = {level: level, title: title, message: message, options: null, closeFunc: this.closeAlert}
            alert = APIAlert(alert_p)
        }
        return (
            <Box sx={{width: '100%', minHeight: '100%'}}>
                {alert}
                <Grid container>
                    <Grid container item md={12} lg={12}>
                        <Box sx={{minWidth: '100%'}}>
                            <SearchTable/>
                        </Box>
                    </Grid>
                    <Grid item md={12} lg={12}>
                        <StyledBox>
                            {this.props.data ? <DataGridPro
                                rows={this.props.data}
                                density="compact"
                                columns={this.set_columns}
                                pageSize={this.props.pageSize}
                                rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                                onColumnOrderChange={(e) => {
                                    this.ColumnOrderChange(e)
                                }}
                                // checkboxSelection
                                headerHeight={50}
                                loading={this.props.isLoading}
                                rowCount={this.props.rowCount}
                                paginationMode='server'
                                onPageChange={(page) => this.useQuery(page)}
                                onPageSizeChange={pageSize => this.changePageSize(pageSize)}
                                columnVisibilityModel={this.state.Visibility}
                                onColumnVisibilityModelChange={(newModel) =>
                                  this.ChangeColumnVisibilityModel(newModel)
                                }
                                disableMultipleSelection={false}
                                initialState={
                                    {pinnedColumns: {left: ['tracking_number']}}
                                }
                                rowReordering
                                pagination={true}

                                sortingMode="server"
                                // sortModel={this.set_sort}
                                onSortModelChange={(newSortModel) => this.ChangeColumnSortModel(newSortModel)}

                                components={{
                                    LoadingOverlay: LinearProgress,
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    Toolbar: APIToolBar,
                                }}
                                columnBuffer={3}
                                columnThreshold={3}
                            />: ""}
                        </StyledBox>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.BillReducer.isLoading,
    pageSize: state.BillReducer.pageSize,
    page: state.BillReducer.page,
    alert: state.BillReducer.alert,
    payload: state.BillReducer.content,
    rowCount: state.BillReducer.rowCount,
    data: state.BillReducer.data,
    search: state.BillReducer.search,
    searchClick: state.BillReducer.searchClick,
    orders: state.BillReducer.orders,
    orderClick: state.BillReducer.orderClick,
    user: state.auth.user,
});

export default connect(mapStateToProps, {loadBill})(Bill)