import * as React from 'react';
import {connect} from "react-redux";
import {loadAccount} from "../redux/actions/account";
import store from "../redux/store";
import {ACCOUNT_ORDERS, ACCOUNT_STOP} from "../redux/type";

import AddCredit from "./modal/AddCreditModal";
import AddCreditPageModal from "./modal/AddCreditPageModal";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";
import Grid from "@mui/material/Grid";
import SearchTable from "../components/searchTable";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";
import APIToolBar from "../components/APIToolBar";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import StyledBox from "../components/StyledBox";

// style
import './styles.scss'

import SetColumns from "../components/SetColumns";
import SetVisibility from "../components/SetVisibility";



class Account extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Visibility: this.setVisibility
        }
    }

    localStorageKey = this.props.user.username + "_" + 'AccountColumns';
    localStorageVisibilityKey = this.props.user.username + "_" + 'AccountVisibility';
    columns: GridColDef[] = [
        {id: 1, field: "id", headerName: "Id"},
        {id: 2, field: "username", headerName: "User Name", width: 150},
        {id: 3, field: "UID", headerName: "User Id"},
        // {id: 4, field: "fedex_rate", headerName: "Fedex Rate"},
        // {id: 5, field: "ups_rate", headerName: "UPS Rate", width: 150},
        {
            id: 4, field: "credit", headerName: "Credit", valueFormatter: params => {
                return `$ ${params.value}`
            }
        }
    ]
    // 控制是否展示列
    columnVisibilityModel = {
        id: false
    }
    set_columns = SetColumns(this.localStorageKey, this.columns)
    setVisibility = SetVisibility(this.localStorageVisibilityKey, this.columnVisibilityModel)

    ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        // 获取columns
        // console.log(set_columns)
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
        // oldIndex > targetIndex
        // targetIndex < oldIndex
    }

    useQuery = (page) => {
        this.props.loadAccount((page * this.props.pageSize), ((page + 1) * this.props.pageSize),
            page, null, store.getState().account.search, null, null)
    }
    changePageSize = (pageSize) => {
        setTimeout(() => {
            this.props.loadAccount((this.props.page * pageSize), ((this.props.page + 1) * pageSize),
                null, pageSize, store.getState().account.search, null, null)
        }, 100);
    }
    ChangeColumnVisibilityModel = (modal) => {
        localStorage.setItem(this.localStorageVisibilityKey, JSON.stringify(modal))
        this.setState({Visibility: modal})
    }
    UNSAFE_componentWillMount() { // 预加载
        this.props.loadAccount(this.props.page, this.props.pageSize, null,
            null, store.getState().account.search, null, null);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (store.getState().account.stop && nextProps.search && !this.props.isLoading && nextProps.searchClick) {  // search
            store.dispatch({type: ACCOUNT_STOP})
            this.props.loadAccount(this.props.page, this.props.pageSize, null,
                null, store.getState().account.search, null, null);
        }
        // order
        if (nextProps.orders && nextProps.orderClick) {
            store.dispatch({type: ACCOUNT_STOP})
            this.props.loadAccount(this.props.page, this.props.pageSize, null,
                null, store.getState().account.search, null, nextProps.orders);
        }
    }
    ChangeColumnSortModel = (modal) => {
        // 触发 UNSAFE_componentWillReceiveProps
        store.dispatch({type: ACCOUNT_ORDERS, payload: modal})
    }
    render() {
        return (
            <Box sx={{width: '100%'}}>
                <Grid container>
                    <Grid container item md={12} lg={12}>
                        <Box sx={{minWidth: '100%'}}>
                            <SearchTable/>
                            <AddCredit/>
                            <AddCreditPageModal/>
                        </Box>
                    </Grid>
                    <Grid item md={12} lg={12}>
                        <StyledBox>
                            {this.props.data ? <DataGridPro
                                rows={this.props.data}
                                density="compact"
                                columns={this.set_columns}
                                pageSize={this.props.pageSize}
                                rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                                rowReordering
                                onColumnOrderChange={(e) => {
                                    this.ColumnOrderChange(e)
                                    // APIColumnOrderChange(e, set_columns, localStorageKey)
                                }}
                                // checkboxSelection
                                headerHeight={70}
                                // autoHeight={true}
                                loading={this.props.isLoading}
                                rowCount={this.props.rowCount}
                                paginationMode='server'
                                onPageChange={(page) => this.useQuery(page)}
                                onPageSizeChange={pageSize => this.changePageSize(pageSize)}
                                columnVisibilityModel={this.state.Visibility}
                                onColumnVisibilityModelChange={(newModel) =>
                                  this.ChangeColumnVisibilityModel(newModel)
                                }
                                disableMultipleSelection={false}
                                pagination={true}

                                sortingMode="server"
                                // sortModel={this.set_sort}
                                onSortModelChange={(newSortModel) => this.ChangeColumnSortModel(newSortModel)}

                                components={{
                                    LoadingOverlay: LinearProgress,
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    Toolbar: APIToolBar
                                }}
                                columnBuffer={3}
                                columnThreshold={3}
                            />: ''}
                        </StyledBox>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.account.isLoading,
    pageSize: state.account.pageSize,
    page: state.account.page,
    rowCount: state.account.rowCount,
    data: state.account.data,
    search: state.account.search,
    searchClick: state.account.searchClick,
    orders: state.account.orders,
    orderClick: state.account.orderClick,
    user: state.auth.user
});

export default connect(mapStateToProps, {loadAccount})(Account)