import {AxiosDefaultRequest} from "../../components/default_request";
import server from "../../server";
import {LOAD_ADDRESS, SET_CURRENT, ADDRESS, ADDRESS_LOADING} from "../type";
import store from "../store";
import {sort_order} from "../../components/utils";

export const loadAddress = (offset, limit, setPage = null, setPageSize = null,
                             options = null, reload= null, orders= null) => (dispatch, getState) => {
    /*
    * offset: 开始
    * limit: 结束
    * setPage: 页面条目数量
    * setPageSize: 设置页面条目数
    * options: 当前用于search存储
    * reload: 忘了
    * orders: 排序
    * */
    dispatch({type: ADDRESS_LOADING})
    let url = server.address_audit_url + "?offset=" + offset + "&limit=" + limit
    if (options) {
        for (let i in options) {
            if (options[i] !== '') {
                url = url + '&' + i + '=' + options[i]
            }
        }
    }
    if (orders){
        url += sort_order(orders);
    }
    AxiosDefaultRequest({
        url: url,
        method: 'GET',
        success: (res) => {
            const result = {data: res.data.rows, rowCount: res.data.total, display: false};
            console.log(result)
            if (String(setPage) && setPage !== null) {
                result['page'] = setPage
            } else {
                result['page'] = store.getState().addressReducer.page;
            }
            if (String(setPageSize) && setPageSize !== null) {
                result['pageSize'] = setPageSize
            } else {
                result['pageSize'] = store.getState().addressReducer.pageSize;
            }
            dispatch({type: SET_CURRENT, payload: {current: ADDRESS, searchTable: ADDRESS, currentUrl: "/index/address"}})
            dispatch({
                type: LOAD_ADDRESS, payload: result
            })
        }
    })
}


// export const auditAddress = (address_id, status) => (dispatch, getState) => {
//     /*
//     * offset: 开始
//     * limit: 结束
//     * setPage: 页面条目数量
//     * setPageSize: 设置页面条目数
//     * options: 当前用于search存储
//     * reload: 忘了
//     * orders: 排序
//     * */
//     dispatch({type: ADDRESS_LOADING})
//     let url = server.address_audit_url
//     AxiosDefaultRequest({
//         url: url,
//         method: 'PATCH',
//         data: {
//             "address_id": address_id,
//             "status": status
//         },
//         success: (res) => {
//             const result = {data: res.data.rows, rowCount: res.data.total, display: false};
//             console.log(result)
//             dispatch({type: SET_CURRENT, payload: {current: ADDRESS, searchTable: ADDRESS, currentUrl: "/index/address"}})
//             dispatch({
//                 type: LOAD_ADDRESS, payload: result
//             })
//         }
//     })
// }